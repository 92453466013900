import cn from "classnames";
import styles from "./ActiveStep.module.scss";
import React from "react";
import colors from "../../../assets/styles/variables/colors.module.scss";
import {ReactComponent as IconArrow} from "../../../assets/icons/arrow-down.svg";

const ActiveStep = ({handleClick, active, title, index, length}: IActiveStep) => {

    const percent = ((index + 1) / length);


    return (
        <div className={cn(styles.element, "d-flex align-items-center w-100")} onClick={handleClick}>
            <div className={cn(styles.circle, "d-inline-flex")}>
                <Circle percent={percent}/>
                <span className={cn(styles.step, "d-inline-flex align-items-center justify-content-center")}>
                    <b>{index + 1}</b>/{length}
                </span>
            </div>
            <div className={styles.title}>{title}</div>
            <IconArrow className={cn(styles.arrow, active && styles['isActive'])}/>
        </div>
    )
}

export default ActiveStep;

const Circle = ({percent}: { percent: number }) => {
    const circumference = 17 * 2 * Math.PI;
    const offset = circumference - percent * circumference;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <circle cx="18" cy="18" r="17" stroke={colors['grey-lighter']} strokeOpacity="0.2" strokeWidth="2"/>
            <circle className={styles.fill} cx="18" cy="18" r="17" stroke={colors['blue']} strokeOpacity="1"
                    strokeWidth="2" strokeDasharray={`${circumference} ${circumference}`} strokeDashoffset={offset}/>
        </svg>
    )
}

interface IActiveStep {
    title: string;
    index: number;
    handleClick: () => void;
    active: boolean;
    length: number;
}
