import React from "react";
import styles from "./FormRadioGroup.module.scss";
import cn from "classnames";

const FormRadioGroup = (props: IFormRadioGroup) => {
  const { children, className, label } = props;

  return (
    <div
      className={cn(
        "d-flex align-items-center flex-wrap",
        styles.element,
        label && styles["withLabel"],
        className
      )}
    >
      {label && (
        <label className={cn(styles.label, "d-inline-flex")}>{label}</label>
      )}
      <div>{children}</div>
    </div>
  );
};

export default FormRadioGroup;

interface IFormRadioGroup {
  children: any;
  className?: string;
  label?: string;
}
