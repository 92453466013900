import { Form, Formik, Field } from "formik";
import Heading from "../../../../components/Heading/Heading";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import Button from "../../../../components/Button/Button";
import FormInlineInput from "../../../../components/Form/FormInlineInput/FormInlineInput";
import * as Yup from "yup";
import FormAgreement from "../../../../components/Form/FormAgreement/FormAgreement";
import { getName } from "../../../../functions/getName";
import { useDispatch, useSelector } from "react-redux";
import { SetData } from "../../../../store/actions/SetData";
import { AppState } from "../../../../store/reducers";

const CommissionAcknowledgement = ({
  formData,
  goToPrevious,
  setActiveStep,
}: ICommissionAcknowledgement) => {
  const IBrokerState = useSelector((state: AppState) => state.IBrokerState);
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          fxTrading:
            IBrokerState?.atcCommission || IBrokerState?.ibCommision
              ? null
              : formData?.commission_acknowledgement?.fxTrading || 30,
          cfdTrading:
            IBrokerState?.atcCommission || IBrokerState?.ibCommision
              ? null
              : formData?.commission_acknowledgement?.cfdTrading || 30,
          other:
            IBrokerState?.atcCommission || IBrokerState?.ibCommision
              ? null
              : formData?.commission_acknowledgement?.other || null,
          atcFxTrading: IBrokerState?.atcCommission || null,
          ibFxTrading: IBrokerState?.ibCommision || null,
          pitpMarkUp: IBrokerState?.atcPipMarkUp || null,
          atcCfdTrading: IBrokerState?.atcCommisionCFD || null,
          ibCfdTrading: IBrokerState?.ibCommissionCFD || null,
          ibTrading: IBrokerState?.ibTradingFees || null,
          agreement: formData?.commission_acknowledgement?.agreement || false,
        }}
        onSubmit={(values) => {
          setActiveStep();
          dispatch(
            SetData({ ...formData, commission_acknowledgement: values })
          );
        }}
        validationSchema={Yup.object().shape({
          agreement: Yup.boolean().oneOf([true], "This field is required"),
        })}
      >
        {({ values, submitForm }) => (
          <Form>
            <Heading
              title="Commission Acknowledgement"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <Paragraph className="mb-medium">
              The undersigned client (“Client”) is applying for an account
              (“Account”) with ATC Brokers Limited (“ATC”) so that the Client
              may enter into transactions (“Transactions”) with ATC.
              {IBrokerState?.typeOfTradingFee && (
                <>
                  {" "}
                  The Client has been introduced to ATC by the referring agent
                  named below (“RA”) and/or by RA’s affiliates.
                </>
              )}
            </Paragraph>
            <Paragraph className="mb-medium">
              The Client instructs ATC to deduct the trading fees (“Trading
              Fees”) listed below from the Account and to pay the Trading Fees
              to ATC
              {IBrokerState?.typeOfTradingFee && " and RA’s affiliates"}.
            </Paragraph>
            <Paragraph className="mb-medium">
              The Client acknowledges that the Trading Fees will be deducted in
              the primary currency in which the monies in the Account are held.
              If the Client opens additional accounts (“Additional Accounts”)
              with ATC, the Client agrees that the Trading Fees will be payable
              in relation to such Additional Accounts (subject to amendment by
              ATC).
            </Paragraph>
            {IBrokerState?.typeOfTradingFee ? (
              <>
                <div className="row d-flex flex-wrap">
                  <div className="col-12 col-lg-6">
                    <Field
                      name="atcFxTrading"
                      component={FormInlineInput}
                      label="ATC FX Trading Fees"
                      type="number"
                    />
                    <Field
                      name="ibFxTrading"
                      component={FormInlineInput}
                      label="RA FX Trading Fees"
                      type="number"
                    />
                    <Field
                      name="pitpMarkUp"
                      component={FormInlineInput}
                      label="Pip Mark-up"
                      type="number"
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <Field
                      name="atcCfdTrading"
                      component={FormInlineInput}
                      label="ATC CFD Trading Fees"
                      type="number"
                    />
                    <Field
                      name="ibCfdTrading"
                      component={FormInlineInput}
                      label="RA CFD Trading Fees"
                      type="number"
                    />
                    <Field
                      name="ibTrading"
                      component={FormInlineInput}
                      label={
                        IBrokerState?.typeOfTradingFee === "Percentage"
                          ? "RA Trading Fees % Participation of ATC Fees"
                          : "RA Trading Fees"
                      }
                      type="number"
                    />
                  </div>
                </div>
                <Paragraph className="mb-medium semi-bold" upperCase>
                  The Client acknowledges that ATC and the RA are entirely
                  separate entities and are independent of one another. The
                  client further acknowledges that atc does not supervise the RA
                  or for any representations made to the client by the RA.
                </Paragraph>
              </>
            ) : (
              <>
                <Field
                  name="fxTrading"
                  component={FormInlineInput}
                  label="FX Trading Fees - USD per million per side."
                  type="number"
                />
                <Field
                  name="cfdTrading"
                  component={FormInlineInput}
                  label="CFD Trading Fees – USD per million per side"
                  type="number"
                />
                <Field
                  name="other"
                  component={FormInlineInput}
                  label="Other (If applicable)"
                  type="number"
                />
              </>
            )}
            <Paragraph>
              The Client may enter into Transactions with ATC on a margined
              basis. Transactions, particularly margined Transactions, have a
              high risk factor. As such only genuine “risk” funds, which the
              Client can afford to lose, should be used in such trading. Subject
              to applicable law, the Client’s losses on Transactions may be
              greater that the initial values of the Transactions and the Client
              should only enter into Transactions if it can bear the costs
              associated with such losses.
            </Paragraph>
            <hr />
            <Field
              name="agreement"
              component={FormAgreement}
              label={`I, ${getName(
                formData
              )}, acknowledge having received, read, and understood the foregoing commission acknowledgement.`}
            />
            <div className="d-flex flex-row justify-content-between">
              <Button
                title="Back"
                variant="border"
                handleClick={goToPrevious}
              />
              <Button title="Next" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default CommissionAcknowledgement;

interface ICommissionAcknowledgement {
  setActiveStep: () => void;
  goToPrevious: () => void;
  formData?: any;
}
