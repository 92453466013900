import React from "react";
import styles from "./StepperMobileSteps.module.scss";
import cn from "classnames";
import {IStep, Step} from "../../Stepper/Stepper";

const StepperMobileSteps = ({open, steps}: IStepperMobileSteps) => {

    return(
        <div className={cn(styles.element, open && styles['isOpen'])}>
            <div className={cn(styles.steps, "d-flex flex-column")}>
                {steps.map((step, key) => {
                    const nextFilled = steps[key+1] && steps[key+1].filled;
                    const nextActive = steps[key+1] && steps[key+1].active;
                    return <Step {...step} key={key} first={key === 0} last={key === steps.length-1} nextFilled={nextFilled} nextActive={nextActive}/>
                })}
            </div>
        </div>
    )
}

export default StepperMobileSteps;

interface IStepperMobileSteps {
    open: boolean;
    steps: Array<IStep>;
}
