import { MIN_SELECT_OPTIONS } from "../../../../constans";
import { QuestionT } from "../../../../store/types/Questions.type";

const handleAnswers = (values: any, questionsData: QuestionT[]) => {
  const result = Object.keys(values).map((key) => {
    const handleFileAnswer = (val: File, index: string) => {
      const form = new FormData();
      form.append("file", val);
      form.append("questionId", index);
      return form;
    };
    const question = questionsData.find(
      (item) => item.questionId === parseInt(key)
    );
    if (question) {
      switch (question.type) {
        case "Value":
          // checkbox
          if (question.multipleChoice) {
            return {
              questionId: parseInt(key),
              responseKeys: values[key],
            };
          } else {
            if (question.possibleResponses.length > MIN_SELECT_OPTIONS) {
              // select
              return {
                questionId: parseInt(key),
                responseKeys: [values[key].value],
              };
            } else {
              // radio button
              return {
                questionId: parseInt(key),
                responseKeys: [values[key]],
              };
            }
          }
        case "Text":
          // text input
          return {
            questionId: parseInt(key),
            text: values[key],
          };
        case "Optional":
          // text input
          return {
            questionId: parseInt(key),
            text: values[key],
          };
        case "Date":
          // text input
          return {
            questionId: parseInt(key),
            text: values[key],
          };
        case "OptionalDate":
          // text input
          return {
            questionId: parseInt(key),
            text: values[key],
          };
        case "Phone":
          // text input
          return {
            questionId: parseInt(key),
            text: values[key],
          };
        case "Email":
          // text input
          return {
            questionId: parseInt(key),
            text: values[key],
          };
        case "Document":
          return handleFileAnswer(values[key], key);
        case "AdditionalDocument":
          return handleFileAnswer(values[key], key);
        default:
          return {
            questionId: parseInt(key),
            responseKeys: values[key],
          };
      }
    } else return null;
  });
  return result;
};

export default handleAnswers;
