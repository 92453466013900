import React, { useEffect, useRef } from "react";
import styles from "./ContainerMain.module.scss";
import cn from "classnames";

interface IContainerMain {
  children: any;
  className?: string;
  scrollable?: boolean;
  dark?: boolean;
  dependency?: any;
}

const ContainerMain = (props: IContainerMain) => {
  const { children, className, scrollable, dependency, dark } = props;

  const scrollToRef = useRef(null);

  useEffect(() => {
    scrollable &&
      // @ts-ignore
      scrollToRef.current &&
      // @ts-ignore
      scrollToRef.current.scrollIntoView(true);
  }, [scrollToRef, scrollable, dependency]);

  return (
    <div
      className={cn(
        styles.element,
        scrollable && styles["isScrollable"],
        dark && styles["isDark"],
        className
      )}
    >
      <div ref={scrollToRef}>{children}</div>
    </div>
  );
};

export default ContainerMain;
