import React, { useEffect, useState } from "react";
import styles from "./CreateAccount.module.scss";
import StepsNavigation, {
  IStepsNavigation,
} from "../../components/StepsNavigation/StepsNavigation";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import PersonalInformation from "./IndividualAccount/PersonalInformation/PersonalInformation";
import SecurityQuestion from "./IndividualAccount/SecurityQuestion/SecurityQuestion";
import CommissionAcknowledgement from "./IndividualAccount/CommissionAcknowledgement/CommissionAcknowledgement";
import DeclarationIndividual from "./IndividualAccount/Declaration/Declaration";
import DeclarationCorporate from "./CorporateAccount/Declaration/Declaration";
import IndividualSelfCertification from "./IndividualAccount/IndividualSelfCertification/IndividualSelfCertification";
import AuthorisedRepresentative from "./CorporateAccount/AuthorisedRepresentative/AuthorisedRepresentative";
import ApprovedTraders from "./CorporateAccount/ApprovedTraders/ApprovedTraders";
import LayoutMain from "../../components/LayoutMain/LayoutMain";
import ContainerMain from "../../components/ContainerMain/ContainerMain";
import Container from "../../components/Bootstrap/Container";

const CreateAccountView = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<[] | IStepsNavigation["steps"]>([]);
  const [scrollDependency, setScrollDependency] = useState(activeStep);

  const DivisionState = useSelector((state: AppState) => state.DivisionState);
  const AccountState = useSelector((state: AppState) => state.AccountState);
  const Data = useSelector((state: AppState) => state.Data);

  window.onbeforeunload = () => true;

  useEffect(() => {
    setScrollDependency(activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (AccountState === "individual") {
      if (DivisionState === "uk") {
        setSteps([
          {
            title: "Personal Information",
            active: activeStep === 0,
            filled: !!Data.personal_information,
          },
          {
            title: "Security Question",
            active: activeStep === 1,
            filled: !!Data.security_question,
          },
          {
            title: "Commission Acknowledgement",
            active: activeStep === 2,
            filled: !!Data.commission_acknowledgement,
          },
          {
            title: "Declaration",
            active: activeStep === 3,
            filled: !!Data.declaration,
          },
        ]);
      }
      if (DivisionState === "ky") {
        setSteps([
          {
            title: "Personal Information",
            active: activeStep === 0,
            filled: !!Data.personal_information,
          },
          {
            title: "Security Question",
            active: activeStep === 1,
            filled: !!Data.security_question,
          },
          {
            title: "Commission Acknowledgement",
            active: activeStep === 2,
            filled: !!Data.commission_acknowledgement,
          },
          {
            title: "Declaration",
            active: activeStep === 3,
            filled: !!Data.declaration,
          },
          {
            title: "Individual Self Certification",
            active: activeStep === 4,
            filled: !!Data.isc_section1,
          },
        ]);
      }
    }
    if (AccountState === "corporate") {
      setSteps([
        {
          title: "Authorised Representative",
          active: activeStep === 0,
          filled: !!Data.authorised_representative,
        },
        {
          title: "Security Question",
          active: activeStep === 1,
          filled: !!Data.security_question,
        },
        {
          title: "Approved Traders",
          active: activeStep === 2,
          filled: !!Data.approved_traders,
        },
        {
          title: "Commission Acknowledgement",
          active: activeStep === 3,
          filled: !!Data.commission_acknowledgement,
        },
        {
          title: "Declaration",
          active: activeStep === 4,
          filled: !!Data.declaration,
        },
      ]);
    }
  }, [activeStep, Data, DivisionState, AccountState]);

  const showStep = (step: number) => {
    if (AccountState === "individual") {
      if (DivisionState === "uk") {
        switch (step) {
          case 0:
            return (
              <PersonalInformation
                setActiveStep={() => setActiveStep(1)}
                formData={Data}
              />
            );
          case 1:
            return (
              <SecurityQuestion
                setActiveStep={() => setActiveStep(2)}
                goToPrevious={() => setActiveStep(0)}
                formData={Data}
              />
            );
          case 2:
            return (
              <CommissionAcknowledgement
                setActiveStep={() => setActiveStep(3)}
                goToPrevious={() => setActiveStep(1)}
                formData={Data}
              />
            );
          case 3:
            return (
              <DeclarationIndividual
                goToPrevious={() => setActiveStep(2)}
                formData={Data}
              />
            );
        }
      }
      if (DivisionState === "ky") {
        switch (step) {
          case 0:
            return (
              <PersonalInformation
                setActiveStep={() => setActiveStep(1)}
                formData={Data}
              />
            );
          case 1:
            return (
              <SecurityQuestion
                setActiveStep={() => setActiveStep(2)}
                goToPrevious={() => setActiveStep(0)}
                formData={Data}
              />
            );
          case 2:
            return (
              <CommissionAcknowledgement
                setActiveStep={() => setActiveStep(3)}
                goToPrevious={() => setActiveStep(1)}
                formData={Data}
              />
            );
          case 3:
            return (
              <DeclarationIndividual
                goToPrevious={() => setActiveStep(2)}
                formData={Data}
                setActiveStep={() => setActiveStep(4)}
              />
            );
          case 4:
            return (
              <IndividualSelfCertification
                goToPrevious={() => setActiveStep(3)}
                formData={Data}
                setScrollDependency={setScrollDependency}
              />
            );
        }
      }
    }
    if (AccountState === "corporate") {
      switch (step) {
        case 0:
          return (
            <AuthorisedRepresentative
              setActiveStep={() => setActiveStep(1)}
              formData={Data}
            />
          );
        case 1:
          return (
            <SecurityQuestion
              setActiveStep={() => setActiveStep(2)}
              goToPrevious={() => setActiveStep(0)}
              formData={Data}
            />
          );
        case 2:
          return (
            <ApprovedTraders
              setActiveStep={() => setActiveStep(3)}
              goToPrevious={() => setActiveStep(1)}
              formData={Data}
            />
          );
        case 3:
          return (
            <CommissionAcknowledgement
              setActiveStep={() => setActiveStep(4)}
              goToPrevious={() => setActiveStep(2)}
              formData={Data}
            />
          );
        case 4:
          return (
            <DeclarationCorporate
              goToPrevious={() => setActiveStep(3)}
              formData={Data}
            />
          );
      }
    }
  };

  return (
    <LayoutMain>
      {steps.length > 0 && (
        <StepsNavigation steps={steps} type="createAccount" />
      )}
      <ContainerMain scrollable dependency={scrollDependency}>
        <Container limited className={styles.container}>
          {showStep(activeStep)}
        </Container>
      </ContainerMain>
    </LayoutMain>
  );
};

export default CreateAccountView;
