import { useState } from "react";
import { Form, Formik, Field } from "formik";
import Heading from "../../../../components/Heading/Heading";
import styles from "../../CreateAccount.module.scss";
import Grid, { GridElem } from "../../../../components/Grid/Grid";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import FormInput from "../../../../components/Form/FormInput/FormInput";
import FormRadioGroup from "../../../../components/Form/FormRadioGroup/FormRadioGroup";
import FormRadio from "../../../../components/Form/FormRadio/FormRadio";
import Button from "../../../../components/Button/Button";
import ModalNotice from "../../../../components/Modal/ModalNotice/ModalNotice";
import * as Yup from "yup";
import FormInputError from "../../../../components/Form/FormInputError/FormInputError";
import FormSelect from "../../../../components/Form/FormSelect/FormSelect";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { SetData } from "../../../../store/actions/SetData";
import {
  Countries,
  getAdditionalQuestionsForCountry,
} from "../../../InitialForm/Countries";
import getPhoneCode from "../../../../functions/getPhoneCode";
import FormInputPhone from "../../../../components/Form/FormInput/FormInputPhone";
import { AppState } from "../../../../store/reducers";
import { getQuestionBasedOnAccountType } from "../../getQuestionBasedOnAccountType";
import FormSelectWithScrollOnSearch from "../../../../components/Form/FormSelect/FormSelectWithScrollOnSearch";
import { checkEmailAvailability } from "../../../../store/helpers/checkEmailAvailability";
import { specialCharactersError } from "../../../../constans";

const PersonalInformation = ({
  setActiveStep,
  formData,
}: IPersonalInformation) => {
  const IBrokerState = useSelector((state: AppState) => state.IBrokerState);
  const { initial } = useSelector((state: AppState) => state.Data);

  const [introducingBroker, setIntroducingBroker] = useState(
    IBrokerState?.managerName || formData?.personal_information?.referredBroker
  );

  const [birthday, setBirthday] = useState<{
    year?: string | number;
    month?: string | number;
    day?: string | number;
  }>({});

  const [modalOpen, setModalOpen] = useState(false);

  const availableYears = () => {
    const thisYear = parseInt(moment().format("YYYY"));
    const years = [];

    for (let i = thisYear - 100; i <= thisYear - 18; i++) {
      years.push({
        label: i,
        value: i,
      });
    }
    return years.sort((a, b) => b.value - a.value);
  };

  const availableMonths = () => {
    const months = [];

    for (let i = 1; i <= 12; i++) {
      months.push({
        label: i,
        value: i,
      });
    }
    return months;
  };

  const availableDays = (currentDate: any) => {
    const daysLength =
      moment(
        `${currentDate.year}-${currentDate.month}`,
        "YYYY-MM"
      ).daysInMonth() || 31;
    const days = [];

    for (let i = 1; i <= daysLength; i++) {
      days.push({
        label: i,
        value: i,
      });
    }

    return days;
  };

  const dispatch = useDispatch();
  const phoneCode = getPhoneCode(formData.initial.country_of_residence.value);

  return (
    <>
      <Formik
        initialValues={{
          firstName: formData?.personal_information?.firstName || "",
          secondName: formData?.personal_information?.secondName || "",
          birthYear: formData?.personal_information?.birthYear || "",
          birthMonth: formData?.personal_information?.birthMonth || "",
          birthDay: formData?.personal_information?.birthDay || "",
          surname: formData?.personal_information?.surname || "",
          emailAddress: formData?.personal_information?.emailAddress || "",
          confirmEmailAddress:
            formData?.personal_information?.confirmEmailAddress || "",
          phoneCode: formData?.personal_information?.phoneCode || phoneCode,
          phoneNumber: formData?.personal_information?.phoneNumber || "",
          gender: formData?.personal_information?.gender || "",
          introducingBroker: IBrokerState?.managerName
            ? "yes"
            : formData?.personal_information?.introducingBroker || "",
          referredBroker: IBrokerState?.managerName
            ? IBrokerState.managerName
            : formData?.personal_information?.referredBroker || "",
          otherTrader: formData?.personal_information?.otherTrader || "",
          signalSystem: formData?.personal_information?.signalSystem || "",
          buildingNumber: formData?.personal_information?.buildingNumber || "",
          street: formData?.personal_information?.street || "",
          city: formData?.personal_information?.city || "",
          country:
            (initial?.country_of_residence as (typeof Countries)[number]) || "",
          cpfTaxRn: formData?.personal_information?.cpfTaxRn,
          idCardNo: formData?.personal_information?.idCardNo,
          dni: formData?.personal_information?.dni,
          taxId: formData?.personal_information?.taxId,
          sin: formData?.personal_information?.sin,
          postCode: formData?.personal_information?.postCode || "",
        }}
        onSubmit={(values, actions) => {
          dispatch(
            SetData({
              ...formData,
              personal_information: {
                ...values,
                cpfTaxRn: getAdditionalQuestionsForCountry(values.country)
                  ?.cpfTaxRn
                  ? values?.cpfTaxRn
                  : undefined,
                idCardNo: getAdditionalQuestionsForCountry(values.country)
                  ?.idCardNo
                  ? values?.idCardNo
                  : undefined,
                dni: getAdditionalQuestionsForCountry(values.country)?.dni
                  ? values?.dni
                  : undefined,
                taxId: getAdditionalQuestionsForCountry(values.country)?.taxId
                  ? values?.taxId
                  : undefined,
                sin: getAdditionalQuestionsForCountry(values.country)?.sin
                  ? values?.sin
                  : undefined,
              },
            })
          );
          setActiveStep();
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .required("First Name is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          surname: Yup.string()
            .required("Surname is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          birthYear: Yup.object().required("Year of birth is required"),
          birthMonth: Yup.object().required("Month of birth is required"),
          birthDay: Yup.object().required("Day of birth is required"),
          emailAddress: Yup.string()
            .email("Email address must be valid")
            .required("Email address is required")
              .test(
                  "checkEmailAvailability",
                  () => <a href="mailto:accounts@atcbrokers.com">This email is already registered, please
                      contact accounts@atcbrokers.com for assistance.</a>,
                  checkEmailAvailability
              ),
          confirmEmailAddress: Yup.string()
            .email("Email address must be valid")
            .required("This field is required")
            .oneOf(
              [Yup.ref("emailAddress"), null],
              "Email Addresses must match"
            ),
          phoneNumber: Yup.string().required("Phone number is required"),
          gender: Yup.string().required("Gender is required"),
          introducingBroker: Yup.string().required("This field is required"),
          referredBroker: Yup.string().when("introducingBroker", {
            is: "yes",
            then: Yup.string().required("Referring Agent is required"),
          }),
          otherTrader:
            IBrokerState?.accountType !== "Subscription" &&
            IBrokerState?.accountType !== "Self"
              ? Yup.string().required("This field is required")
              : Yup.string().notRequired(),
          signalSystem:
            IBrokerState?.accountType === "Subscription"
              ? Yup.string().required("This field is required")
              : Yup.string().notRequired(),
          buildingNumber: Yup.string()
            .required("Building Number is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          street: Yup.string()
            .required("Street is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          city: Yup.string()
            .required("City/Town is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          postCode: Yup.string().required("Post Code is required"),
          country: Yup.object().required("Country is required"),

          cpfTaxRn: Yup.string().when("country", {
            is: (country: any) =>
              getAdditionalQuestionsForCountry(country)?.cpfTaxRn,
            then: Yup.string().required(
              "CPF Tax Reference Number is required."
            ),
          }),
          idCardNo: Yup.string().when("country", {
            is: (country: any) =>
              getAdditionalQuestionsForCountry(country)?.idCardNo,
            then: Yup.string().required("ID Card Number is required."),
          }),
          dni: Yup.string().when("country", {
            is: (country: any) =>
              getAdditionalQuestionsForCountry(country)?.dni,
            then: Yup.string().required("DNI (National Identity) is required."),
          }),
          taxId: Yup.string().when("country", {
            is: (country: any) =>
              getAdditionalQuestionsForCountry(country)?.taxId,
            then: Yup.string().required("TAX ID is required."),
          }),
          sin: Yup.string().when("country", {
            is: (country: any) =>
              getAdditionalQuestionsForCountry(country)?.sin,
            then: Yup.string().required(
              "Social Insurance Number (SIN) is required."
            ),
          }),
        })}
      >
        {({ setFieldValue, touched, errors, values }) => (
          <Form className={styles.element}>
            <Heading
              title="Personal Information"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <Grid columns={6} gap={2.4}>
              <GridElem size={2}>
                <FormGroup label="First Name">
                  <Field name="firstName" component={FormInput} noDigits />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Middle Name">
                  <Field name="secondName" component={FormInput} noDigits />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Surname">
                  <Field name="surname" component={FormInput} noDigits />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Birth Day">
                  <Field
                    name="birthDay"
                    component={FormSelect}
                    placeholder="Choose day"
                    options={availableDays(birthday)}
                    handleChange={(e: any) => {
                      setBirthday({
                        ...birthday,
                        day: e.value,
                      });
                    }}
                  />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Birth Month">
                  <Field
                    name="birthMonth"
                    placeholder="Choose month"
                    component={FormSelect}
                    options={availableMonths()}
                    handleChange={(e: any) => {
                      setBirthday({
                        ...birthday,
                        month: e.value,
                      });
                    }}
                  />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Birth Year">
                  <Field
                    name="birthYear"
                    component={FormSelect}
                    placeholder="Choose year"
                    options={availableYears()}
                    handleChange={(e: any) => {
                      setBirthday({
                        ...birthday,
                        year: e.value,
                      });
                    }}
                  />
                </FormGroup>
              </GridElem>
            </Grid>
            <hr />
            <Grid gap={2.4}>
              <GridElem size={2}>
                <FormGroup label="Building Number">
                  <Field name="buildingNumber" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Street">
                  <Field name="street" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="City/Town">
                  <Field name="city" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Post Code">
                  <Field name="postCode" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Country">
                  <Field
                    disabled={!!initial?.country_of_residence}
                    name="country"
                    component={FormSelectWithScrollOnSearch}
                    placeholder="Choose country"
                    options={Countries}
                  />
                </FormGroup>
              </GridElem>
            </Grid>
            <Grid columns={6}>
              <GridElem size={6}>
                {getAdditionalQuestionsForCountry(values.country)?.cpfTaxRn ? (
                  <FormGroup
                    label={
                      getAdditionalQuestionsForCountry(values.country)?.cpfTaxRn
                    }
                  >
                    <Field component={FormInput} name={"cpfTaxRn"} />
                  </FormGroup>
                ) : null}
                {getAdditionalQuestionsForCountry(values.country)?.idCardNo ? (
                  <FormGroup
                    label={
                      getAdditionalQuestionsForCountry(values.country)?.idCardNo
                    }
                  >
                    <Field component={FormInput} name={"idCardNo"} />
                  </FormGroup>
                ) : null}
                {getAdditionalQuestionsForCountry(values.country)?.dni ? (
                  <FormGroup
                    label={
                      getAdditionalQuestionsForCountry(values.country)?.dni
                    }
                  >
                    <Field component={FormInput} name={"dni"} />
                  </FormGroup>
                ) : null}
                {getAdditionalQuestionsForCountry(values.country)?.taxId ? (
                  <FormGroup
                    label={
                      getAdditionalQuestionsForCountry(values.country)?.taxId
                    }
                  >
                    <Field component={FormInput} name={"taxId"} />
                  </FormGroup>
                ) : null}
                {getAdditionalQuestionsForCountry(values.country)?.sin ? (
                  <FormGroup
                    label={
                      getAdditionalQuestionsForCountry(values.country)?.sin
                    }
                  >
                    <Field component={FormInput} name={"sin"} />
                  </FormGroup>
                ) : null}
              </GridElem>
            </Grid>
            <hr />
            <Grid gap={2.4}>
              <GridElem size={2}>
                <FormGroup label="Email Address">
                  <Field
                    name="emailAddress"
                    component={FormInput}
                    type="email"
                  />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Confirm Email Address">
                  <Field
                    name="confirmEmailAddress"
                    component={FormInput}
                    type="email"
                  />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Phone number">
                  <Field
                    name="phoneNumber"
                    component={FormInputPhone}
                    onlyDigits
                    phoneCode={phoneCode}
                  />
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormRadioGroup
                  label="Please select your gender"
                  className="justify-content-lg-end"
                >
                  <Field
                    name="gender"
                    component={FormRadio}
                    value="male"
                    label="Male"
                  />
                  <Field
                    name="gender"
                    component={FormRadio}
                    value="female"
                    label="Female"
                  />
                </FormRadioGroup>
                {touched["gender"] && errors["gender"] && (
                  <FormInputError
                    className="ml-lg-auto"
                    errors={errors}
                    inputName="gender"
                  />
                )}
              </GridElem>
            </Grid>
            <Grid gap={2.4} rowGrap={1.6}>
              <GridElem className="d-flex align-items-center" size={2}>
                <b>Were you referred by a Referring Agent?</b>
              </GridElem>
              <GridElem className="d-flex flex-column" size={2}>
                <FormRadioGroup className="justify-content-lg-end">
                  <Field
                    name="introducingBroker"
                    component={FormRadio}
                    value="yes"
                    label="Yes"
                    handleChange={() => {
                      setIntroducingBroker(true);
                    }}
                    disabled={!!IBrokerState?.managerName}
                  />
                  <Field
                    name="introducingBroker"
                    component={FormRadio}
                    value="no"
                    label="No"
                    handleChange={(e: any) => {
                      setIntroducingBroker(false);
                      setFieldValue("referredBroker", "");
                    }}
                    disabled={!!IBrokerState?.managerName}
                  />
                </FormRadioGroup>
                {touched["introducingBroker"] &&
                  errors["introducingBroker"] && (
                    <FormInputError
                      className="ml-lg-auto"
                      errors={errors}
                      inputName="introducingBroker"
                    />
                  )}
              </GridElem>
              {introducingBroker && (
                <>
                  <GridElem className="d-flex align-items-center" size={2}>
                    <b>Referring Agent Name</b>
                  </GridElem>
                  <GridElem size={2}>
                    <Field
                      name="referredBroker"
                      component={FormInput}
                      isRight
                      disabled={!!IBrokerState?.managerName}
                    />
                  </GridElem>
                </>
              )}
              {getQuestionBasedOnAccountType(
                IBrokerState?.accountType,
                setFieldValue,
                touched,
                errors,
                setModalOpen
              )}
            </Grid>
            <div className="d-flex flex-row justify-content-center">
              <Button title="Next" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
      {modalOpen && (
        <ModalNotice
          setModalOpen={setModalOpen}
          accountType={IBrokerState?.accountType}
        />
      )}
    </>
  );
};

export default PersonalInformation;

interface IPersonalInformation {
  setActiveStep: () => void;
  formData?: any;
}
