import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./FormRadioList.module.scss";
import cn from "classnames";

const FormRadioList = (props: IFormRadioList) => {
  const {
    label,
    children,
    setFieldValue,
    name,
    value,
    values,
    bigger,
    className,
  } = props;

  const [active, setActive] = useState(values[name] === value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.value);
  };

  useEffect(() => {
    setActive(values[name] === value);
  }, [values, name, value]);

  return (
    <label
      className={cn(styles.element, bigger && styles["isBigger"], className)}
    >
      <span className={cn(styles.icon, active && styles["isActive"])} />
      <span
        className={cn(
          styles.content,
          "d-inline-flex align-items-center",
          children && "flex-column"
        )}
      >
        <span
          className={cn("d-inline-flex", styles.label, children && "mb-medium")}
        >
          <input
            type="radio"
            checked={active}
            value={value}
            name={name}
            onChange={(e) => handleChange(e)}
          />
          <span>{label}</span>
        </span>
        {children}
      </span>
    </label>
  );
};
export default FormRadioList;

interface IFormRadioList {
  label: string;
  children?: any;
  setFieldValue: (name: string, e: string) => void;
  name: string;
  value: string;
  values: any;
  bigger?: boolean;
  className?: string;
}
