import React, { useEffect, useState } from "react";
import StepsNavigation, {
  IStepsNavigation,
} from "../../components/StepsNavigation/StepsNavigation";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import styles from "./Application.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { logoutUser } from "../../store/actions/Authentication";
import { QuestionT } from "../../store/types/Questions.type";
import Loader from "../../components/Loader/Loader";
import Assessment from "./Assessment/Assessment";
import { SetApply } from "../../store/actions/SetApply";
import { useHistory } from "react-router";
import { checkAppropriateness } from "../../functions/checkAppropriateness";
import { SetWarning } from "../../store/actions/SetWarning";
import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import { sendAssessmentCompleted } from "../../store/actions/Assessment";
import LayoutMain from "../../components/LayoutMain/LayoutMain";

export const checkAssessmentStep = (questions: QuestionT[]) => {
  switch (questions[0].assessmentType) {
    case "Assessment":
      return 0;
    case "SuitabilityAndAppropriateness":
      return 0;
    case "KYCCheck":
      return 1;
    case "POIDocuments":
      return 2;
    case "PORDocuments":
      return 3;
    case "Documents":
      return 4;
    default:
      return -1;
  }
};

const ApplicationView = () => {
  window.onbeforeunload = () => true;
  window.onunload = () => dispatch(logoutUser());

  const dispatch = useDispatch();
  const history = useHistory();

  const [activeStep, setActiveStep] = useState<number>(-1);
  const [steps, setSteps] = useState<[] | IStepsNavigation["steps"]>([]);

  const Data = useSelector((state: AppState) => state.Data);
  const AccountState = useSelector((state: AppState) => state.AccountState);
  const AssessmentState = useSelector(
    (state: AppState) => state.AssessmentState
  );
  const QuestionsState = useSelector((state: AppState) => state.QuestionsState);
  const [isLoading, setIsLoading] = useState<boolean>(
    QuestionsState.loading || !QuestionsState.loaded || AssessmentState.loading
  );

  useEffect(() => {
    setIsLoading(
      QuestionsState.loading ||
        !QuestionsState.loaded ||
        AssessmentState.loading
    );
  }, [QuestionsState, AssessmentState]);

  useEffect(() => {
    if (QuestionsState.questions.length && !isLoading) {
      const step = checkAssessmentStep(QuestionsState.questions);
      if (step >= 0) setActiveStep(step);
    }
  }, [QuestionsState.questions, isLoading]);

  useEffect(() => {
    if (AssessmentState.details) {
      const assessmentResult = checkAppropriateness(
        AssessmentState.details.broker
      );
      if (assessmentResult) {
        dispatch(sendAssessmentCompleted("completedWithWarning"));
      } else {
        dispatch(sendAssessmentCompleted("completed"));
      }
      dispatch(SetWarning(assessmentResult));
      dispatch(SetApply(4));
      history.push("/online-application/end");
    }
  }, [AssessmentState, dispatch, history]);

  useEffect(() => {
    if (AccountState === "individual") {
      setSteps([
        {
          title: "Regulatory Assessment",
          active: activeStep === 0,
          filled: activeStep > 0,
        },
        {
          title: "KYC/AML Assessment",
          active: activeStep === 1,
          filled: activeStep > 1,
        },
        {
          title: "Proof of Identity",
          active: activeStep === 2,
          filled: activeStep > 2,
        },
        {
          title: "Proof of Residency",
          active: activeStep === 3,
          filled: activeStep > 3,
        },
      ]);
    } else {
      setSteps([
        {
          title: "Regulatory Assessment",
          active: activeStep === 0,
          filled: activeStep > 0,
        },
        {
          title: "KYC/AML Assessment",
          active: activeStep === 1,
          filled: activeStep > 1,
        },
        {
          title: "Proof of Identity",
          active: activeStep === 2,
          filled: activeStep > 2,
        },
        {
          title: "Proof of Residency",
          active: activeStep === 3,
          filled: activeStep > 3,
        },
        {
          title: "Additional Documents",
          active: activeStep === 4,
          filled: activeStep > 4,
        },
      ]);
    }
  }, [activeStep, Data, AccountState]);

  return (
    <LayoutMain>
      <StepsNavigation steps={steps} />
      {activeStep >= 0 ? (
        <div className={styles.content}>
          <Assessment />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
          <Loader />
        </div>
      )}
      {isLoading && !QuestionsState.error && (
        <LoaderOverlay>
          <Loader white />
        </LoaderOverlay>
      )}
    </LayoutMain>
  );
};

export default ApplicationView;
