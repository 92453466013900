import { Dispatch, SetStateAction } from "react";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import styles from "../../../CreateAccount.module.scss";
import Button from "../../../../../components/Button/Button";
import Paragraph from "../../../../../components/Paragraph/Paragraph";
import FormRadioList from "../../../../../components/Form/FormRadioList/FormRadioList";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInput from "../../../../../components/Form/FormInput/FormInput";
import Grid, { GridElem } from "../../../../../components/Grid/Grid";
import { IISCSection } from "../IndividualSelfCertification";
import * as Yup from "yup";
import FormInputError from "../../../../../components/Form/FormInputError/FormInputError";
import { useDispatch } from "react-redux";
import { SetData } from "../../../../../store/actions/SetData";

const ISCSection2 = ({ goToPrevious, setSection, formData }: IISCSection2) => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        usCitizenship: formData?.isc_section2?.usCitizenship || "",
        taxPayerId: formData?.isc_section2?.taxPayerId || "",
      }}
      onSubmit={(values) => {
        dispatch(SetData({ ...formData, isc_section2: values }));
        if (values.usCitizenship === "notUSCitizen") {
          setSection(3);
        } else {
          setSection(4);
        }
      }}
      validationSchema={Yup.object().shape({
        usCitizenship: Yup.string().required(
          "Please choose one of these options"
        ),
        taxPayerId: Yup.string().when("usCitizenship", {
          is: "USCitizen",
          then: Yup.string().required(
            "Taxpayer Identifying Number is required"
          ),
        }),
      })}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <b className={cn(styles.subtitle, "d-inline-flex mb-medium")}>
            Section 2: Declaration of U.S. Citizenship or U.S. Residence for Tax
            Purposes.
          </b>
          <Paragraph className="mb-xlarge">
            Please tick (a), (b) or (c) and complete as appropriate.
          </Paragraph>
          <FormRadioList
            values={values}
            name="usCitizenship"
            value="USCitizen"
            setFieldValue={setFieldValue}
            label="I confirm that I am a U.S. citizen and/or resident in the U.S. for tax purposes (green card
                        holder or resident under the substantial presence test) and my U.S. federal taxpayer identifying
                        number (U.S. TIN) is as follows:"
          >
            <Grid>
              <GridElem size={2}>
                <FormGroup className="mb-0" label="Taxpayer Identifying Number">
                  <Field name="taxPayerId" component={FormInput} />
                </FormGroup>
              </GridElem>
            </Grid>
          </FormRadioList>
          <FormRadioList
            values={values}
            name="usCitizenship"
            value="USBorn"
            setFieldValue={setFieldValue}
            label="I confirm that I was born in the U.S. (or a U.S. territory) but am no longer a U.S. citizen as I have voluntarily surrendered my citizenship as evidenced by the attached documents."
          />
          <FormRadioList
            values={values}
            name="usCitizenship"
            value="notUSCitizen"
            setFieldValue={setFieldValue}
            label="I confirm that I am not a U.S. citizen or resident in the U.S. for tax purposes."
          />
          {touched["usCitizenship"] && errors["usCitizenship"] && (
            <FormInputError
              errors={errors}
              inputName="usCitizenship"
              className="mt-medium"
            />
          )}
          <div className="d-flex flex-row justify-content-between">
            <Button title="Back" variant="border" handleClick={goToPrevious} />
            <Button title="Next" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ISCSection2;

interface IISCSection2 extends IISCSection {
  setSection: Dispatch<SetStateAction<number>>;
  formData: any;
}
