import React, { useState } from "react";
import styles from "./FormAgreement.module.scss";
import FormInputError from "../FormInputError/FormInputError";
import cn from "classnames";
import { ReactComponent as IconCheck } from "../../../assets/icons/select.svg";

const FormAgreement = (props: IFormAgreement) => {
  const { field, form, label, handleChange } = props;

  const [active, setActive] = useState(field.value);

  return (
    <div className="mb-medium">
      <label className={cn("d-flex", styles.element)}>
        <input
          {...field}
          type="checkbox"
          checked={active}
          onChange={(e) => {
            setActive(!active);
            form.setFieldValue(field.name, !active);
            if (handleChange) {
              handleChange();
            }
          }}
        />
        <span className={cn(styles.box, active && styles["isActive"])}>
          {active && <IconCheck />}
        </span>
        <span className={styles.label}>{label}</span>
      </label>
      {form.touched[field.name] && form.errors[field.name] && (
        <FormInputError
          errors={form.errors}
          inputName={field.name}
          className="mt-medium"
        />
      )}
    </div>
  );
};

export default FormAgreement;

interface IFormAgreement {
  field: any;
  form: any;
  label: string;
  handleChange?: () => void;
}
