import { useState, useRef, useEffect } from "react";

export function useImperativeScrollOnSearch(setSelected: (data: any) => void) {
  const optionRefs = useRef<{ element: HTMLSpanElement | null; data: any }[]>(
    []
  );
  const [searchString, setSearchString] = useState("");

  function handleInputValueChange(inputValue: string) {
    setSearchString(searchString.concat(inputValue));
  }

  function scrollToInput(inputValue: string) {
    if (!inputValue) {
      return;
    }

    const searchedItem = optionRefs?.current?.find((el) =>
      el?.data?.label?.toLowerCase()?.startsWith(inputValue?.toLowerCase())
    );

    if (!searchedItem) {
      return;
    }
    /**
     * TODO: Instead of getting parentId and scrolling into view from there. Directly
     * scroll into view via searchedItem?.element?.parentElement?.scrollIntoView()
     */
    const parentElementId = searchedItem?.element?.parentElement?.id;

    if (!parentElementId) {
      return;
    }

    const parentElement = document.getElementById(parentElementId);

    if (!parentElement) {
      return;
    }

    parentElement?.scrollIntoView?.();

    setSelected(searchedItem?.data);
  }

  useEffect(() => {
    if (!searchString) {
      return;
    }
    scrollToInput(searchString);
    setTimeout(() => setSearchString(""), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return {
    optionRefs,
    handleInputValueChange,
  };
}
