import React from "react";
import styles from "./Notice.module.scss";
import {ReactComponent as IconInfo} from "../../assets/icons/info.svg";
import cn from "classnames";

const Notice = ({children, className}: {children: string; className?: string}) => {

    return(
        <div className={cn(styles.element, className, "d-flex")}>
            <IconInfo className={styles.icon}/>
            <p className={styles.paragraph}>
                {children}
            </p>
        </div>
    )
}
export default Notice;
