import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Heading from "../../../../components/Heading/Heading";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import FormAgreement from "../../../../components/Form/FormAgreement/FormAgreement";
import Button from "../../../../components/Button/Button";
import ModalProcessing from "../../../../components/Modal/ModalProcessing/ModalProcessing";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetApply } from "../../../../store/actions/SetApply";
import { getName } from "../../../../functions/getName";
import { SetData } from "../../../../store/actions/SetData";
import { registerUser } from "../../../../store/actions/Authentication";
import { AppState } from "../../../../store/reducers";
import { createAssessment } from "../../../../store/actions/Assessment";

const Declaration = ({
  formData,
  goToPrevious,
  setActiveStep,
}: IDeclaration) => {
  const { userId, error } = useSelector(
    (state: AppState) => state.AuthenticationState
  );
  const { assessmentId } = useSelector(
    (state: AppState) => state.AssessmentState
  );

  const DivisionState = useSelector((state: AppState) => state.DivisionState);
  const AccountState = useSelector((state: AppState) => state.AccountState);
  const [modalOpen, setModalOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    !!userId && dispatch(createAssessment());
  }, [userId, dispatch]);

  useEffect(() => {
    error && setModalOpen(false);
  }, [error]);

  useEffect(() => {
    if (!!assessmentId) {
      dispatch(SetApply(2));
      history.push("/online-application/start");
    }
  }, [assessmentId, dispatch, history]);

  return (
    <>
      <Formik
        initialValues={{
          agreement: formData?.declaration?.agreement || false,
        }}
        onSubmit={(values) => {
          dispatch(SetData({ ...formData, declaration: values }));
          if (AccountState === "corporate" || DivisionState === "uk") {
            dispatch(registerUser());
            setModalOpen(true);
          }
          setActiveStep && setActiveStep();
        }}
        validationSchema={Yup.object().shape({
          agreement: Yup.boolean().oneOf([true], "This field is required"),
        })}
      >
        {({ values, submitForm }) => (
          <Form>
            <Heading
              title="Declaration"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <Paragraph className="mb-medium">
              By continuing the application, the Applicant acknowledges that it
              is electronically signing the Customer Agreement between the
              Applicant and ATC and that the Customer Agreement is a legally
              binding contractual agreement. The Applicant also confirms that it
              understands the full nature and risks of trading in the foreign
              exchange, contracts for differences and other derivative markets.
            </Paragraph>
            <Paragraph className="mb-medium">
              The Applicant agrees to be bound by the terms of ATC’s current{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                    DivisionState === "uk"
                      ? "https://atcbrokers.com/en/uk-terms-of-business.pdf"
                      : "https://atcbrokers.com/en/ky-terms-of-business.pdf"
                  }
              >
                Terms of Business
              </a>
              ,{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                    DivisionState === "uk"
                      ? "https://atcbrokers.com/en/uk-order-execution-policy.pdf"
                      : "https://atcbrokers.com/en/ky-order-execution-policy.pdf"
                  }
              >
                Order Execution Policy
              </a>
              ,{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                    DivisionState === "uk"
                      ? "https://atcbrokers.com/en/uk-conflict-of-interest.pdf"
                      : "https://atcbrokers.com/en/ky-conflict-of-interest-policy.pdf"
                  }
              >
                Conflicts of Interests Policy
              </a>{" "}
              and{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                    DivisionState === "uk"
                      ? "https://atcbrokers.com/en/uk-risk-disclosure-notice.pdf"
                      : "https://atcbrokers.com/en/ky-risk-disclosure-notice.pdf"
                  }
              >
                Risk Disclosure Notice
              </a>
              . The Applicant also agrees to be bound by the terms of the{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                    DivisionState === "uk"
                    ? "https://atcbrokers.com/en/uk-referring-agent-disclosure.pdf"
                    : "https://atcbrokers.com/en/ky-referring-agent-disclosure.pdf"
                  }
              >
                Referring Agent Disclosure
              </a>{" "}
              (if the Applicant has been introduced to ATC by a referring
              agent).
            </Paragraph>
            <Paragraph className="mb-medium">
              The Applicant confirms that the funds to be traded are those of
              the Applicant and not of any underlying clients.
            </Paragraph>
            <Paragraph className="mb-medium">
              The Applicant confirms that it has access to the Internet and
              consents to the provision of information about changes to the
              Customer Agreement, Terms of Business, Order Execution Policy,
              Conflict of Interest Policy, and Risk Disclosure Notice by
              maintaining the most up to date versions available on ATC’s
              website{" "}
              {DivisionState === "uk" ? (
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://atcbrokers.co.uk/"
                >
                  atcbrokers.co.uk
                </a>
              ) : (
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://atcbrokers.com/"
                >
                  atcbrokers.com
                </a>
              )}
              .
            </Paragraph>
            <Paragraph className="mb-medium">
              The Applicant warrants that the information provided by it during
              the account opening process is true and correct and that it will
              notify ATC in writing of any material changes to such information.
            </Paragraph>
            <Paragraph className="mb-medium">
              The Applicant warrants that it can lawfully open an account and
              trade with ATC as envisaged in the Customer Agreement and Terms of
              Business.
            </Paragraph>
            <Paragraph>
              <span className="semi-bold">I HEREBY CERTIFY</span> that I am
              authorised to execute this Agreement on behalf of the Applicant
              listed above and that I have direct knowledge of the facts stated
              above.
            </Paragraph>
            <hr />
            <Field
              name="agreement"
              component={FormAgreement}
              label={`I, ${getName(
                formData
              )}, accept the above declaration for and on behalf of the Applicant.`}
            />
            <div className="d-flex flex-row justify-content-between">
              <Button
                title="Back"
                variant="border"
                handleClick={goToPrevious}
              />
              <Button title="Next" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
      {modalOpen && <ModalProcessing />}
    </>
  );
};
export default Declaration;

interface IDeclaration {
  goToPrevious: () => void;
  formData?: any;
  setActiveStep?: () => void;
}
