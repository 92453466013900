import { Field } from "formik";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormSelect from "../../../../../components/Form/FormSelect/FormSelect";
import {
  PossibleResponseT,
  QuestionT,
} from "../../../../../store/types/Questions.type";

const SelectField = ({ question }: { question: QuestionT }) => {
  const formatSelectOptions = (data: PossibleResponseT[]) => {
    const result = data.map((elem: PossibleResponseT) => {
      return {
        value: elem.responseKey,
        label: elem.responseText,
      };
    });
    return result;
  };

  return (
    <div className="row">
      <div className="col-12 col-md-7">
        <FormGroup className="mb-md-large" label={question.questionText}>
          <Field
            component={FormSelect}
            bigger
            label={question.questionText}
            name={question.questionId.toString()}
            options={formatSelectOptions(question.possibleResponses)}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default SelectField;
