import { useEffect } from "react";
import styles from "./ApplicationStart.module.scss";
import cn from "classnames";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Container from "../../components/Bootstrap/Container";
import Heading from "../../components/Heading/Heading";
import Paragraph from "../../components/Paragraph/Paragraph";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SetApply } from "../../store/actions/SetApply";
import { AppState } from "../../store/reducers";
import { logoutUser } from "../../store/actions/Authentication";
import { getQuestions } from "../../store/actions/Questions";
import LayoutMain from "../../components/LayoutMain/LayoutMain";
import ContainerMain from "../../components/ContainerMain/ContainerMain";

const ApplicationStartView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  window.onbeforeunload = () => true;
  window.onunload = () => dispatch(logoutUser());

  const { questions, loading } = useSelector(
    (state: AppState) => state.QuestionsState
  );

  const { userId } = useSelector(
    (state: AppState) => state.AuthenticationState
  );

  useEffect(() => {
    if (questions.length) {
      dispatch(SetApply(3));
      history.push("/online-application");
    }
  }, [dispatch, history, questions]);

  return (
    <LayoutMain>
      <ContainerMain scrollable dark>
        <div className={cn(styles.element, "d-flex align-items-center")}>
          <div className={cn(styles.header, "d-flex justify-content-center")}>
            <div className={styles.logo}>
              <Logo />
            </div>
          </div>
          <Container fluid>
            <div className={styles.content}>
              <Heading
                className="text-center mb-large"
                size="h1"
                title="Application"
              />
              <Paragraph dark bigger className="mb-medium">
                In the following assessment you will be asked a number of
                questions allowing us to assess whether we are able to proceed
                with onboarding you as a client.
              </Paragraph>
              <Paragraph dark bigger className="mb-medium">
                You acknowledge and understand that we are at our sole
                discretion request additional information and documentation in
                support of your responses in this assessment.
              </Paragraph>
              <Paragraph dark bigger className="mb-medium">
                You further declare that all the information provided by you in
                this assessment is correct and you are obliged to inform us
                promptly in case of any changes to this information.
              </Paragraph>
              <div className="d-flex flex-row justify-content-center">
                <Button
                  handleClick={() => {
                    dispatch(getQuestions(userId));
                  }}
                  title="I acknowledge and agree"
                  loading={loading}
                />
              </div>
            </div>
          </Container>
        </div>
      </ContainerMain>
    </LayoutMain>
  );
};

export default ApplicationStartView;
