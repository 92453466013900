import { all, put, select, take, takeLatest } from "redux-saga/effects";
import {
  REGISTER_USER,
  LOGIN_USER,
  CHECK_USER,
  LoginUserT,
  LOGOUT_USER,
  CHECK_USER_DONE,
} from "../types/Authentication.type";
import {
  registerUserDone,
  registerUserFailed,
  loginUser,
  loginUserDone,
  loginUserFailed,
  checkUser,
  logoutUserDone,
  checkUserDone,
  checkUserFailed,
} from "../actions/Authentication";
import { removeUser, saveTokens } from "../helpers/tokenManagement";
import axios from "../axios";
import { SetAccount } from "../actions/SetAccount";
import { SetDivision } from "../actions/SetDivision";
import { AppState } from "../reducers";
import { setDataT } from "../reducers/DataState";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { SetStep } from "../actions/SetStep";
import { getQuestions } from "../actions/Questions";
import { GET_QUESTIONS_DONE } from "../types/Questions.type";

function* registerUserHandler() {
  try {
    const state: AppState = yield select((state) => state);

    const userData = {
      individualAccount: state.AccountState === "individual",
      atcDivision: state.Data.initial.atc_brokers_division.label,
      residenceCountryCode: state.Data.initial.country_of_residence.value,
      residenceCountry: state.Data.initial.country_of_residence.label,
      currencyDeposit: state.Data.initial.currency_deposit.label,
      platform: state.Data.initial.platform.label,
      initialDepositAmount: state.Data.initial.amount,
      individualAccountData: state.Data.personal_information
        ? {
            firstName: state.Data.personal_information.firstName,
            secondName: state.Data.personal_information.secondName,
            surname: state.Data.personal_information.surname,
            birthDay: state.Data.personal_information.birthDay.label,
            birthMonth: state.Data.personal_information.birthMonth.label,
            birthYear: state.Data.personal_information.birthYear.label,
            address: {
              buildingNumber: state.Data.personal_information.buildingNumber,
              street: state.Data.personal_information.street,
              city: state.Data.personal_information.city,
              countryCode: state.Data.personal_information.country.value,
              country: state.Data.personal_information.country.label,
              postCode: state.Data.personal_information.postCode,
            },
            phoneNumber: state.Data.personal_information.phoneNumber,
            gender: state.Data.personal_information.gender,
          }
        : null,
      ...(state.Data.personal_information
        ? {
            cpfTaxRn: state.Data.personal_information?.cpfTaxRn,
            idCardNo: state.Data.personal_information?.idCardNo,
            dni: state.Data.personal_information?.dni,
            taxId: state.Data.personal_information?.taxId,
            sin: state.Data.personal_information?.sin,
          }
        : {}),
      email: state.Data.personal_information
        ? state.Data.personal_information.emailAddress
        : state.Data.authorised_representative.emailAddress,
      phoneNumber: state.Data.personal_information
        ? state.Data.personal_information.phoneNumber
        : state.Data.authorised_representative.phoneNumber,
      companyData: state.Data.authorised_representative
        ? {
            entityName: state.Data.authorised_representative.entityName,
            registeredCountryCode:
              state.Data.authorised_representative.registeredCountry.value,
            registeredNumber:
              state.Data.authorised_representative.registeredNumber,
            leiNumber: state.Data.authorised_representative.leiNumber || null,
            buildingNumber: state.Data.authorised_representative.buildingNumber,
            street: state.Data.authorised_representative.street,
            city: state.Data.authorised_representative.city,
            countryCode: state.Data.authorised_representative.country.value,
            postCode: state.Data.authorised_representative.postCode,
            registeredCountry:
              state.Data.authorised_representative.registeredCountry.label,
            representativeName: state.Data.authorised_representative.firstName,
            representativeSurname: state.Data.authorised_representative.surname,
            representativePosition:
              state.Data.authorised_representative.position,
            financialInstitution:
              state.Data.authorised_representative.financialInstitution ===
              "yes",
            approvedTraders: state.Data.approved_traders,
          }
        : null,
      linkId: state.IBrokerState?.id || null,
      accountType: state.IBrokerState?.accountType || null,
      introducingBroker: state.Data.personal_information
        ? state.Data.personal_information.introducingBroker === "yes"
        : state.Data.authorised_representative?.introducingBroker === "yes",
      introducingBrokerMail: state.IBrokerState?.managerMail || null,
      introducingBrokerMail2: state.IBrokerState?.managerMail2 || null,
      sendAppToBroker: state.IBrokerState?.copyOfApp || null,
      introducingBrokerName: state.Data.personal_information
        ? state.Data.personal_information.referredBroker || null
        : state.Data.authorised_representative?.referredBroker || null,
      tradedBySomeoneOther: state.Data.personal_information?.otherTrader
        ? state.Data.personal_information?.otherTrader === "yes"
        : state.Data.personal_information?.otherTrader === "yes" ||
          state.Data.authorised_representative?.otherTrader
        ? state.Data.authorised_representative?.otherTrader === "yes"
        : state.Data.authorised_representative?.otherTrader === "yes" || null,
      signalSystem: state.Data.personal_information?.signalSystem
        ? state.Data.personal_information?.signalSystem === "yes"
        : state.Data.personal_information?.signalSystem === "yes" ||
          state.Data.authorised_representative?.signalSystem
        ? state.Data.authorised_representative?.signalSystem === "yes"
        : state.Data.authorised_representative?.signalSystem === "yes" || null,
      pin: state.Data.security_question.pin,
      password: state.Data.security_question.password,
      fxTradingFees: state.Data.commission_acknowledgement.fxTrading,
      cfdTradingFees: state.Data.commission_acknowledgement.cfdTrading,
      otherTradingFees: state.Data.commission_acknowledgement.other,
      atcCommission: state.IBrokerState?.atcCommission || null,
      atcCommisionCFD: state.IBrokerState?.atcCommisionCFD || null,
      ibCommision: state.IBrokerState?.ibCommision || null,
      ibCommissionCFD: state.IBrokerState?.ibCommissionCFD || null,
      atcPipMarkUp: state.IBrokerState?.atcPipMarkUp || null,
      ibTradingFees: state.IBrokerState?.ibTradingFees || null,
      typeOfTradingFee: state.IBrokerState?.typeOfTradingFee || null,
      commissionAcknowledgement:
        state.Data.commission_acknowledgement.agreement,
      declarationAcknowledgement: state.Data.declaration.agreement,
      countryOfBirth: state.Data.isc_section1?.countryOfBirth || null,
      mailingAddress: state.Data.isc_section1?.mailingAddressDiff
        ? {
            residentialAddress: state.Data.isc_section1.residentialAddress,
            city: state.Data.isc_section1.city,
            countryCode: state.Data.isc_section1.country.value,
            country: state.Data.isc_section1.country.label,
            postCode: state.Data.isc_section1.postCode,
          }
        : null,
      taxpayerIdNumber:
        state.Data.isc_section2?.usCitizenship &&
        state.Data.isc_section2?.usCitizenship === "USCitizen"
          ? state.Data.isc_section2.taxPayerId
          : null,
      usCitizenship: state.Data.isc_section2?.usCitizenship || null,
      taxResidency:
        state.Data.isc_section2?.usCitizenship &&
        state.Data.isc_section2?.usCitizenship === "notUSCitizen"
          ? {
              discloseTaxId: !state.Data.isc_section3?.notDiscloseTaxId,
              taxData: state.Data.isc_section3?.taxData,
              notDiscloseTaxNumberReason:
                state.Data.isc_section3?.reasonForTaxRefNumber,
            }
          : null,
      ipAddress: state.IPAddressState || "0.0.0.0",
    };

    yield axios.post("/user", userData);

    yield put(registerUserDone());

    const userAuthData = {
      login:
        state.Data.personal_information?.emailAddress ||
        state.Data.authorised_representative?.emailAddress,
      password: state.Data.security_question.password,
    };

    yield put(loginUser(userAuthData));
  } catch (error: any) {
    yield put(registerUserFailed());
    if (error.status === 409) {
      toast.error("User registered with this e-mail already exists");
    } else {
      toast.error("Something went wrong, please try again.");
    }
  }
}

function* loginUserHandler({ userAuthData }: LoginUserT) {
  try {
    const { data } = yield axios.post("/user/login", userAuthData);

    saveTokens({ token: data.token, refreshToken: data.refreshToken });

    const Data: setDataT = yield select(({ Data }: AppState) => Data);

    if (Object.keys(Data).length === 0) {
      yield put(checkUser());
      yield take(CHECK_USER_DONE);
    }
    yield put(loginUserDone({ id: data.id }));
  } catch (error: any) {
    yield put(loginUserFailed());
    if (error.status === 404) {
      toast.error("Incorrect login or password");
    } else {
      toast.error("Something went wrong, please try again.");
    }
  }
}

function* checkUserHandler() {
  try {
    const { data } = yield axios.get("/user");

    const account = data.individualAccountData ? "individual" : "corporate";
    yield put(SetAccount(account));

    // all countries as KY
    const division = data.atcDivision === "United Kingdom" ? "ky" : "ky";
    yield put(SetDivision(division));

    if (data.testState === "inProgress") {
      yield put(getQuestions(data.id));
      yield take(GET_QUESTIONS_DONE);
    }
    yield put(SetStep(data.testState));

    yield put(checkUserDone());
  } catch (error) {
    yield put(checkUserFailed());
    toast.error("Something went wrong, please try again.");
  }
}

function* logoutUserHandler() {
  try {
    removeUser();
    yield put(logoutUserDone());
    yield put(push("/"));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchAuthentication() {
  yield all([
    takeLatest(REGISTER_USER, registerUserHandler),
    takeLatest(LOGIN_USER, loginUserHandler),
    takeLatest(LOGOUT_USER, logoutUserHandler),
    takeLatest(CHECK_USER, checkUserHandler),
  ]);
}
