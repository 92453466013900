import React from "react";
import styles from "./FormInlineInput.module.scss";

const FormInlineInput = (props: IFormInlineInput) => {
  const { label, field } = props;

  return (
    <div className="mb-medium">
      <div className={styles.element}>
        <span className={styles.input}>
          {field.value ? field.value : "N/A"}
        </span>
        <label className={styles.label}>{label}</label>
      </div>
    </div>
  );
};
export default FormInlineInput;

interface IFormInlineInput {
  type: "text" | "number";
  label: string;
  field: any;
  form: any;
}
