import { Dispatch, SetStateAction } from "react";
import Heading from "../../Heading/Heading";
import Modal from "../Modal";
import Paragraph from "../../Paragraph/Paragraph";
import Button from "../../Button/Button";

const ModalNotice = (props: IModalNotice) => {
  const { setModalOpen, accountType } = props;

  return (
    <Modal>
      <Heading title="Notice" size="h1" className="text-center mb-medium" />
      {accountType === "Subscription" ? (
        <Paragraph className="text-center">
          If this account will follow a signal system, you are required to fill
          out the LOD form.
        </Paragraph>
      ) : (
        <Paragraph className="text-center">
          If this account will be managed by someone other than yourself, you
          are required to fill out the LPOA form.
        </Paragraph>
      )}
      <div className="d-flex flex-row justify-content-center">
        <Button title="OK" handleClick={() => setModalOpen(false)} />
      </div>
    </Modal>
  );
};

export default ModalNotice;

interface IModalNotice {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  accountType?: string;
}
