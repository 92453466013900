export const SET_IBROKER_ID = "SET_IBROKER_ID";
export const SET_IBROKER = "SET_IBROKER";
export const SET_IBROKER_DONE = "SET_IBROKER_DONE";

export type setIBrokerIDT = {
  type: "SET_IBROKER_ID";
  id: string;
};

export type setIBrokerT = {
  type: "SET_IBROKER";
  id: string;
};

export type setIBrokerDoneT = {
  type: "SET_IBROKER_DONE";
  payload: any;
};

const initialState = {};

export default function IBrokerState(
  state = initialState,
  action: setIBrokerIDT | setIBrokerDoneT
) {
  switch (action.type) {
    case SET_IBROKER_ID:
      return { ...state, id: action.id };
    case SET_IBROKER_DONE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
