import { Field, Form, Formik } from "formik";
import cn from "classnames";
import styles from "../../../CreateAccount.module.scss";
import Paragraph from "../../../../../components/Paragraph/Paragraph";
import Button from "../../../../../components/Button/Button";
import { IISCSection } from "../IndividualSelfCertification";
import Grid, { GridElem } from "../../../../../components/Grid/Grid";
import FormSelect from "../../../../../components/Form/FormSelect/FormSelect";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInput from "../../../../../components/Form/FormInput/FormInput";
import { Countries } from "../../../../InitialForm/Countries";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { SetData } from "../../../../../store/actions/SetData";
import getCountryLabel from "../../../../../functions/getCountryLabel";

const ISCSection3 = ({ goToPrevious, setSection, formData }: IISCSection3) => {
  // const [contentVisible, setContentVisible] = useState(true);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        notDiscloseTaxId: formData?.isc_section3?.notDiscloseTaxId || false,
        countryOfTaxResidence1: formData?.isc_section3?.taxData
          ? {
              value:
                formData?.isc_section3?.taxData[0]?.countryOfTaxResidence || "",
              label: formData?.isc_section3?.taxData[0]?.countryOfTaxResidence
                ? getCountryLabel(
                    formData?.isc_section3?.taxData[0]?.countryOfTaxResidence
                  )
                : "",
            }
          : "",
        taxReferenceNumberType1: formData?.isc_section3?.taxData
          ? formData.isc_section3.taxData[0]?.taxNumberType
          : "",
        taxReferenceNumber1: formData?.isc_section3?.taxData
          ? formData?.isc_section3?.taxData[0]?.taxNumber
          : "",
        countryOfTaxResidence2: formData?.isc_section3?.taxData
          ? {
              value:
                formData?.isc_section3?.taxData[1]?.countryOfTaxResidence || "",
              label: formData?.isc_section3?.taxData[1]?.countryOfTaxResidence
                ? getCountryLabel(
                    formData?.isc_section3?.taxData[1]?.countryOfTaxResidence
                  )
                : "",
            }
          : "",
        taxReferenceNumberType2: formData?.isc_section3?.taxData
          ? formData?.isc_section3?.taxData[1]?.taxNumberType
          : "",
        taxReferenceNumber2: formData?.isc_section3?.taxData
          ? formData?.isc_section3?.taxData[1]?.taxNumber
          : "",
        countryOfTaxResidence3: formData?.isc_section3?.taxData
          ? {
              value:
                formData?.isc_section3?.taxData[2]?.countryOfTaxResidence || "",
              label: formData?.isc_section3?.taxData[2]?.countryOfTaxResidence
                ? getCountryLabel(
                    formData?.isc_section3?.taxData[2]?.countryOfTaxResidence
                  )
                : "",
            }
          : "",
        taxReferenceNumberType3: formData?.isc_section3?.taxData
          ? formData?.isc_section3?.taxData[2]?.taxNumberType
          : "",
        taxReferenceNumber3: formData?.isc_section3?.taxData
          ? formData?.isc_section3?.taxData[2]?.taxNumber
          : "",
        reasonForTaxRefNumber:
          formData?.isc_section3?.reasonForTaxRefNumber || "",
      }}
      onSubmit={(values) => {
        if (!values.notDiscloseTaxId) {
          const data = [
            {
              // @ts-ignore
              countryOfTaxResidenceCode: values.countryOfTaxResidence1.value,
              // @ts-ignore
              countryOfTaxResidence: values.countryOfTaxResidence1.label,
              taxNumberType: values.taxReferenceNumberType1,
              taxNumber: values.taxReferenceNumber1,
            },
          ];
          values.countryOfTaxResidence2 &&
            values.taxReferenceNumberType2 &&
            data.push({
              // @ts-ignore
              countryOfTaxResidenceCode: values.countryOfTaxResidence2.value,
              // @ts-ignore
              countryOfTaxResidence: values.countryOfTaxResidence2.label,
              taxNumberType: values.taxReferenceNumberType2,
              taxNumber: values.taxReferenceNumber2,
            }) &&
            values.countryOfTaxResidence3 &&
            values.taxReferenceNumberType3 &&
            data.push({
              // @ts-ignore
              countryOfTaxResidenceCode: values.countryOfTaxResidence3.value,
              // @ts-ignore
              countryOfTaxResidence: values.countryOfTaxResidence3.label,
              taxNumberType: values.taxReferenceNumberType3,
              taxNumber: values.taxReferenceNumber3,
            });

          console.log(data);
          dispatch(
            SetData({
              ...formData,
              isc_section3: {
                notDiscloseTaxId: values.notDiscloseTaxId,
                taxData: data,
                reasonForTaxRefNumber: null,
              },
            })
          );
        } else {
          dispatch(
            SetData({
              ...formData,
              isc_section3: {
                notDiscloseTaxId: values.notDiscloseTaxId,
                taxData: null,
                reasonForTaxRefNumber: values.reasonForTaxRefNumber,
              },
            })
          );
        }
        setSection();
      }}
      validationSchema={Yup.object().shape({
        countryOfTaxResidence1: Yup.object().required("Tax Reference Number Type is required"),
        taxReferenceNumberType1: Yup.string().required("Tax Reference Number Type is required"),
        taxReferenceNumber1: Yup.string().required("Tax Reference Number Type is required"),
        reasonForTaxRefNumber: Yup.string().when("notDiscloseTaxId", {
          is: true,
          then: Yup.string().required(
            "Reason for Tax Reference Number Non-Availability is required"
          ),
        }),
      })}
    >
      {({ values, touched, setFieldValue }) => (
        <Form>
          <b className={cn(styles.subtitle, "d-inline-flex mb-medium")}>
            Section 3: Declaration of Tax Residency (other than U.S.)
          </b>
          <Paragraph className="mb-large">
            I hereby confirm that I am, for tax purposes, resident in the
            following countries (indicate the tax reference number type and
            number applicable in each country).
          </Paragraph>
          {/* {contentVisible && (
            <> */}
          <Grid columns={6}>
            <GridElem size={2}>
              <FormGroup label="Country of tax residency">
                <Field
                  name="countryOfTaxResidence1"
                  component={FormSelect}
                  options={Countries}
                  placeholder="Choose country"
                />
              </FormGroup>
            </GridElem>
            <GridElem size={2}>
              <FormGroup label="Tax Reference Number Type">
                <Field name="taxReferenceNumberType1" component={FormInput} />
              </FormGroup>
            </GridElem>
            <GridElem size={2}>
              <FormGroup label="Tax Reference Number">
                <Field name="taxReferenceNumber1" component={FormInput} />
              </FormGroup>
            </GridElem>
          </Grid>
          <hr className="mt-0 mb-medium" />
          <Grid columns={6}>
            <GridElem size={2}>
              <FormGroup label="Country of tax residency">
                <Field
                  name="countryOfTaxResidence2"
                  component={FormSelect}
                  options={Countries}
                  placeholder="Choose country"
                />
              </FormGroup>
            </GridElem>
            <GridElem size={2}>
              <FormGroup label="Tax Reference Number Type">
                <Field name="taxReferenceNumberType2" component={FormInput} />
              </FormGroup>
            </GridElem>
            <GridElem size={2}>
              <FormGroup label="Tax Reference Number">
                <Field name="taxReferenceNumber2" component={FormInput} />
              </FormGroup>
            </GridElem>
          </Grid>
          <hr className="mt-0 mb-medium" />
          <Grid columns={6}>
            <GridElem size={2}>
              <FormGroup label="Country of tax residency">
                <Field
                  name="countryOfTaxResidence3"
                  component={FormSelect}
                  options={Countries}
                  placeholder="Choose country"
                />
              </FormGroup>
            </GridElem>
            <GridElem size={2}>
              <FormGroup label="Tax Reference Number Type">
                <Field name="taxReferenceNumberType3" component={FormInput} />
              </FormGroup>
            </GridElem>
            <GridElem size={2}>
              <FormGroup label="Tax Reference Number">
                <Field name="taxReferenceNumber3" component={FormInput} />
              </FormGroup>
            </GridElem>
          </Grid>
          <Paragraph className="mb-large">
            Please indicate not applicable if jurisdiction does not issue or you
            are unable to procure a tax reference number of functional
            equivalent. If applicable, please specify the reason for
            non-availability of a tax reference number:
          </Paragraph>
          <FormGroup label="Reason for Tax Reference Number Non-Availability">
            <Field name="reasonForTaxRefNumber" component={FormInput} />
          </FormGroup>
          {/* </>
          )} */}
          <div className="d-flex flex-row justify-content-between">
            <Button title="Back" variant="border" handleClick={goToPrevious} />
            <Button title="Next" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default ISCSection3;

interface IISCSection3 extends IISCSection {
  setSection: () => void;
}
