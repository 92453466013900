import Modal from "../Modal";
import Heading from "../../Heading/Heading";
import Paragraph from "../../Paragraph/Paragraph";
import Loader from "../../Loader/Loader";

const ModalProcessing = () => {
  return (
    <Modal>
      <Heading
        className="text-center mb-medium"
        size="h1"
        title="Just a few more steps."
      />
      <Paragraph className="text-center mb-large">
        Please give us a moment to take you to the next page.
      </Paragraph>
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    </Modal>
  );
};

export default ModalProcessing;
