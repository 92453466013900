import React, { useEffect, useState } from "react";
import Heading from "../../../../components/Heading/Heading";
import styles from "../../CreateAccount.module.scss";
import ISCSection0 from "./ISCSection0/ISCSection0";
import ISCSection1 from "./ISCSection1/ISCSection1";
import ISCSection2 from "./ISCSection2/ISCSection2";
import ISCSection3 from "./ISCSection3/ISCSection3";
import ISCSection4 from "./ISCSection4/ISCSection4";

const IndividualSelfCertification = ({
  goToPrevious,
  formData,
  setScrollDependency,
}: IIndividualSelfCertification) => {
  const [section, setSection] = useState(0);

  useEffect(() => {
    setScrollDependency(section);
  }, [section, setScrollDependency]);

  const switchSection = (section: number) => {
    switch (section) {
      case 0:
        return (
          <ISCSection0
            goToPrevious={goToPrevious}
            setSection={() => setSection(1)}
          />
        );
      case 1:
        return (
          <ISCSection1
            formData={formData}
            goToPrevious={() => setSection(0)}
            setSection={() => setSection(2)}
          />
        );
      case 2:
        return (
          <ISCSection2
            formData={formData}
            goToPrevious={() => setSection(1)}
            setSection={setSection}
          />
        );
      case 3:
        return (
          <ISCSection3
            formData={formData}
            goToPrevious={() => setSection(2)}
            setSection={() => setSection(4)}
          />
        );
      case 4:
        return (
          <ISCSection4
            formData={formData}
            goToPrevious={() =>
              formData?.isc_section2?.usCitizenship === "notUSCitizen"
                ? setSection(3)
                : setSection(2)
            }
            step={
              formData?.isc_section2?.usCitizenship === "notUSCitizen" ? 4 : 3
            }
          />
        );
    }
  };

  return (
    <div className={styles.element}>
      <Heading
        title="Individual Self-Certification"
        className="text-center mb-xlarge d-none d-lg-block"
        size="h1"
      />
      {switchSection(section)}
    </div>
  );
};
export default IndividualSelfCertification;

export interface IIndividualSelfCertification {
  goToPrevious: () => void;
  setScrollDependency: (e: any) => void;
  formData?: any;
}
export interface IISCSection {
  goToPrevious: () => void;
  formData?: any;
}
