export const SET_STEP = "SET_STEP";

export type setStepT = {
  type: "SET_STEP";
  data: 'inProgress' | 'completed' | 'completedWithWarning' | 'declined';
};

const initialState = "";

export default function StepState (
  state = initialState,
  action: setStepT
) {
  if ( action.type === SET_STEP ) {
    return action.data;
  }
  return state;
}
