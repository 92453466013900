import { toast } from "react-toastify";
import { all, put, select, takeLatest } from "redux-saga/effects";
import {
  acceptAssessmentWarningDone,
  acceptAssessmentWarningFailed,
  createAssessmentDone,
  createAssessmentFailed,
  getAssessmentDetailsDone,
  getAssessmentDetailsFailed,
  sendAssessmentCompletedDone,
  sendAssessmentCompletedFailed,
} from "../actions/Assessment";
import axios from "../axios";
import { AppState } from "../reducers";
import { setAccountT } from "../reducers/AccountState";
import {
  AcceptAssessmentWarningT,
  ACCEPT_ASSESSMENT_WARNING,
  CREATE_ASSESSMENT,
  GetAssessmentDetailsT,
  GET_ASSESSMENT_DETAILS,
  SendAssessmentCompletedT,
  SEND_ASSESSMENT_COMPLETED,
} from "../types/Assessment.type";
import { AuthenticationStateT } from "../types/Authentication.type";

function* createAssessmentHandler() {
  try {
    const { userId }: AuthenticationStateT = yield select(
      ({ AuthenticationState }: AppState) => AuthenticationState
    );
    const account: setAccountT["data"] = yield select(
      ({ AccountState }: AppState) => AccountState
    );

    const { data } = yield axios.post("/muinmos/createAssessment", {
      userId: userId,
      includeAdditionalDocs: account === "corporate",
    });

    yield put(createAssessmentDone(data));
  } catch (error) {
    toast.error("Something went wrong, assessment has not been created");
    yield put(createAssessmentFailed());
  }
}

function* getAssessmentDetailsHandler({ userId }: GetAssessmentDetailsT) {
  try {
    const AuthenticationState: AuthenticationStateT = yield select(
      ({ AuthenticationState }: AppState) => AuthenticationState
    );
    const { data } = yield axios.get(
      `/muinmos/assessment/${userId || AuthenticationState.userId}`
    );
    yield put(getAssessmentDetailsDone(data));
  } catch (error) {
    toast.error("Something went wrong, please try again.");
    yield put(getAssessmentDetailsFailed());
  }
}

function* sendAssessmentCompletedHandler({
  payload,
}: SendAssessmentCompletedT) {
  try {
    const { userId }: AuthenticationStateT = yield select(
      ({ AuthenticationState }: AppState) => AuthenticationState
    );
    yield axios.patch(`/user/${userId}/testState`, {
      testState: payload,
    });
    yield put(sendAssessmentCompletedDone());
  } catch (error) {
    toast.error("Something went wrong, please try again.");
    yield put(sendAssessmentCompletedFailed());
  }
}

function* acceptAssessmentWarningHandler({
  payload,
}: AcceptAssessmentWarningT) {
  try {
    yield axios.patch("/user/appropriatenessWarning", {
      appropriatenessWarningNoticed: true,
      appropriatenessWarningState: payload,
    });
    yield put(acceptAssessmentWarningDone());
  } catch (error) {
    toast.error("Something went wrong, please try again.");
    yield put(acceptAssessmentWarningFailed());
  }
}

export default function* watchAssessment() {
  yield all([
    takeLatest(CREATE_ASSESSMENT, createAssessmentHandler),
    takeLatest(GET_ASSESSMENT_DETAILS, getAssessmentDetailsHandler),
    takeLatest(ACCEPT_ASSESSMENT_WARNING, acceptAssessmentWarningHandler),
    takeLatest(SEND_ASSESSMENT_COMPLETED, sendAssessmentCompletedHandler),
  ]);
}
