import { Field, FormikErrors, FormikTouched } from "formik";
import FormCheckbox from "../../../../../components/Form/FormCheckbox/FormCheckbox";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInputError from "../../../../../components/Form/FormInputError/FormInputError";
import {
  PossibleResponseT,
  QuestionT,
} from "../../../../../store/types/Questions.type";

const CheckboxField = (props: ICheckboxField) => {
  const { question, errors, touched } = props;
  return (
    <FormGroup
      className={"mb-large-last-0 pb-large-last-0"}
      bold
      hLine
      label={question.questionText}
    >
      {question.possibleResponses.map(
        (responseItem: PossibleResponseT, key: number) => {
          return (
            <Field
              name={question.questionId.toString()}
              component={FormCheckbox}
              label={responseItem.responseText}
              value={responseItem.responseKey}
              key={key}
            />
          );
        }
      )}
      {touched[question.questionId.toString()] &&
        errors[question.questionId.toString()] && (
          <FormInputError
            errors={errors}
            inputName={question.questionId.toString()}
          />
        )}
    </FormGroup>
  );
};

export default CheckboxField;

interface ICheckboxField {
  question: QuestionT;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}
