import styles from "./FormInput.module.scss";
import cn from "classnames";
import FormInputError from "../FormInputError/FormInputError";

const FormInputPhone = (props: IFormInputPhone) => {
  const {
    field,
    disabled = false,
    required,
    form,
    className,
    dark,
    phoneCode,
  } = props;

  const { setFieldValue } = form;

  if (disabled) {
    return (
      <span className={cn(styles.element, styles["isDisabled"])}>
        {field.value ? field.value : "-"}
      </span>
    );
  } else {
    return (
      <>
        <div className="d-flex align-items-center">
          <span
            className={cn(
              styles.element,
              styles[`is-phone`],
              className,
              dark && styles["isDark"],
              styles[`is-phoneCode`]
            )}
          >
            {phoneCode?.startsWith("+") ? phoneCode : `+${phoneCode}`}
          </span>
          <input
            className={cn(
              styles.element,
              styles[`is-phone`],
              className,
              dark && styles["isDark"]
            )}
            type="text"
            {...field}
            onChange={(e) => {
              setFieldValue(
                field.name,
                e.currentTarget.value.replace(/[^\d]+/g, "")
              );
            }}
            required={required}
          />
        </div>
        {form.touched[field.name] && form.errors[field.name] && (
          <FormInputError errors={form.errors} inputName={field.name} />
        )}
      </>
    );
  }
};

export default FormInputPhone;

interface IFormInputPhone {
  field: any;
  form: any;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  dark?: boolean;
  phoneCode?: string;
}
