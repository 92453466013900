import {
  GetQuestionsT,
  QuestionT,
  GetQuestionsDoneT,
  SendAnswersT,
  AnswersT,
  SetPreviousQuestionsT,
  GET_QUESTIONS,
  GET_QUESTIONS_DONE,
  SEND_ANSWERS,
  SET_PREVIOUS_QUESTIONS,
  SEND_FILE,
  SendFileT,
  SEND_ANSWERS_DONE,
  SEND_ANSWERS_FAILED,
  SendAnswersDoneT,
  SendAnswersFailedT,
  SEND_FILE_DONE,
  SEND_FILE_FAILED,
  SendFileDoneT,
  SendFileFailedT,
  SET_PREVIOUS_QUESTIONS_DONE,
  SET_PREVIOUS_QUESTIONS_FAILED,
  SetPreviousQuestionsDoneT,
  SetPreviousQuestionsFailedT,
  GET_QUESTIONS_FAILED,
  GetQuestionsFailedT,
} from "../types/Questions.type";


export const getQuestions = ( userId: string | null ): GetQuestionsT => ( {
  type: GET_QUESTIONS,
  userId
} );

export const getQuestionsDone = ( payload: QuestionT[] ): GetQuestionsDoneT => ( {
  type: GET_QUESTIONS_DONE,
  payload,
} );

export const getQuestionsFailed = (): GetQuestionsFailedT => ( {
  type: GET_QUESTIONS_FAILED,
} );

export const sendAnswers = ( payload: AnswersT[] ): SendAnswersT => ( {
  type: SEND_ANSWERS,
  payload,
} );

export const sendAnswersDone = (): SendAnswersDoneT => ( {
  type: SEND_ANSWERS_DONE,
} );

export const sendAnswersFailed = (): SendAnswersFailedT => ( {
  type: SEND_ANSWERS_FAILED,
} );

export const sendFile = ( payload: FormData ): SendFileT => ( {
  type: SEND_FILE,
  payload,
} );

export const sendFileDone = (): SendFileDoneT => ( {
  type: SEND_FILE_DONE,
} );

export const sendFileFailed = (): SendFileFailedT => ( {
  type: SEND_FILE_FAILED,
} );

export const setPreviousQuestions = (): SetPreviousQuestionsT => ( {
  type: SET_PREVIOUS_QUESTIONS,
} );

export const setPreviousQuestionsDone = (): SetPreviousQuestionsDoneT => ( {
  type: SET_PREVIOUS_QUESTIONS_DONE,
} );

export const setPreviousQuestionsFailed = (): SetPreviousQuestionsFailedT => ( {
  type: SET_PREVIOUS_QUESTIONS_FAILED,
} );
