import { all, put } from "redux-saga/effects";
import axios from "axios";
import { GetIPAdressDone } from "../actions/GetIPAddress";

function* IPAddressHAndler() {
  try {
    const { data } = yield axios.get("https://api.ipify.org/?format=json");
    yield put(GetIPAdressDone(data.ip));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchIPAddress() {
  yield all([IPAddressHAndler()]);
}
