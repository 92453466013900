export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_DONE = "REGISTER_USER_DONE";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_DONE = "LOGIN_USER_DONE";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_DONE = "LOGOUT_USER_DONE";
export const CHECK_USER = "CHECK_USER";
export const CHECK_USER_DONE = "CHECK_USER_DONE";
export const CHECK_USER_FAILED = "CHECK_USER_FAILED";
export const RENEW_TOKEN = "RENEW_TOKEN";

export type LoginUserPayloadT = {
  login: string;
  password: string;
}

export type LoginUserDonePayloadT = {
  id: string
}

export type RegisterUserT = {
  type: typeof REGISTER_USER;
}

export type RegisterUserDoneT = {
  type: typeof REGISTER_USER_DONE;
}

export type RegisterUserFailedT = {
  type: typeof REGISTER_USER_FAILED
}

export type LoginUserT = {
  type: typeof LOGIN_USER;
  userAuthData: LoginUserPayloadT
}

export type LoginUserDoneT = {
  type: typeof LOGIN_USER_DONE;
  payload: LoginUserDonePayloadT
}

export type LogoutUserT = {
  type: typeof LOGOUT_USER
}

export type LogoutUserDoneT = {
  type: typeof LOGOUT_USER_DONE
}

export type LoginUserFailedT = {
  type: typeof LOGIN_USER_FAILED
}

export type CheckUserT = {
  type: typeof CHECK_USER
}

export type CheckUserDoneT = {
  type: typeof CHECK_USER_DONE
}

export type CheckUserFailedT = {
  type: typeof CHECK_USER_FAILED
}

export type RenewTokenT = {
  type: typeof RENEW_TOKEN
}

export type AuthenticationStateActionT =
  | LoginUserT | LoginUserDoneT | LoginUserFailedT | LogoutUserDoneT | RegisterUserT | RegisterUserFailedT

export type AuthenticationStateT = {
  userId: string | null;
  error: boolean
};