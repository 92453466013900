import React, { useEffect, useState } from "react";
import styles from "./StepperMobile.module.scss";
import cn from "classnames";
import ActiveStep from "./ActiveStep/ActiveStep";
import StepperMobileSteps from "./StepperMobileSteps/StepperMobileSteps";
import { IStep } from "../Stepper/Stepper";

const StepperMobile = ({ steps }: IStepperMobile) => {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState({
    ...steps[0],
    index: 0,
  });

  useEffect(() => {
    steps.forEach((step, key) => {
      if (step.active) {
        setActiveStep({
          ...step,
          index: key,
        });
      }
    });
  }, [steps]);

  return (
    <div
      className={cn(
        styles.element,
        "d-flex flex-column w-100",
        open && styles["isOpen"]
      )}
    >
      <div className={styles.wrapper}>
        <ActiveStep
          title={activeStep.title}
          length={steps.length}
          index={activeStep.index}
          handleClick={() => setOpen(!open)}
          active={open}
        />
        <StepperMobileSteps steps={steps} open={open} />
      </div>
    </div>
  );
};

export default StepperMobile;

interface IStepperMobile {
  steps: Array<IStep>;
}
