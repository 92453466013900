export const SET_WARNING = "SET_WARNING";

export type setWarningT = {
  type: "SET_WARNING";
  data: boolean
};

const initialState = false;

export default function WarningState (
  state = initialState,
  action: setWarningT
) {
  if ( action.type === SET_WARNING ) {
    return action.data;
  }
  return state;
}
