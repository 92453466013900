import {
  REGISTER_USER,
  REGISTER_USER_DONE,
  REGISTER_USER_FAILED,
  LOGIN_USER,
  LOGIN_USER_DONE,
  LOGIN_USER_FAILED,
  CHECK_USER,
  RegisterUserFailedT,
  RegisterUserT,
  RegisterUserDoneT,
  LoginUserT,
  LoginUserPayloadT,
  LoginUserDoneT,
  LoginUserDonePayloadT,
  LoginUserFailedT,
  CheckUserT,
  LogoutUserT,
  LogoutUserDoneT,
  LOGOUT_USER,
  LOGOUT_USER_DONE,
  CHECK_USER_DONE,
  CheckUserDoneT,
  CHECK_USER_FAILED,
  CheckUserFailedT,
} from "../types/Authentication.type";

export const registerUser = (): RegisterUserT => ( {
  type: REGISTER_USER,
} );

export const registerUserDone = (): RegisterUserDoneT => ( {
  type: REGISTER_USER_DONE,
} );

export const registerUserFailed = (
): RegisterUserFailedT => ( {
  type: REGISTER_USER_FAILED,
} );

export const loginUser = ( userAuthData: LoginUserPayloadT ): LoginUserT => ( {
  type: LOGIN_USER,
  userAuthData,
} );

export const loginUserDone = (
  payload: LoginUserDonePayloadT
): LoginUserDoneT => ( {
  type: LOGIN_USER_DONE,
  payload,
} );

export const loginUserFailed = (
): LoginUserFailedT => ( {
  type: LOGIN_USER_FAILED,
} );

export const checkUser = (): CheckUserT => ( {
  type: CHECK_USER,
} );

export const checkUserDone = (): CheckUserDoneT => ( {
  type: CHECK_USER_DONE,
} );

export const checkUserFailed = (): CheckUserFailedT => ( {
  type: CHECK_USER_FAILED,
} );

export const logoutUser = (): LogoutUserT => ( {
  type: LOGOUT_USER,
} );

export const logoutUserDone = (): LogoutUserDoneT => ( {
  type: LOGOUT_USER_DONE,
} );
