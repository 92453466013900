import { useEffect, useState } from "react";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import styles from "./InitialForm.module.scss";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Heading from "../../components/Heading/Heading";
import FormSelect from "../../components/Form/FormSelect/FormSelect";
import FormInput from "../../components/Form/FormInput/FormInput";
import FormGroup from "../../components/Form/FormGroup/FormGroup";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SetApply } from "../../store/actions/SetApply";
import { SetDivision } from "../../store/actions/SetDivision";
import { Countries } from "./Countries";
import { SetAccount } from "../../store/actions/SetAccount";
import { SetData } from "../../store/actions/SetData";
import LayoutAuth from "../../components/LayoutAuth/LayoutAuth";
import { AppState } from "../../store/reducers";
import getDivisionValue from "../../functions/getDivisionValue";
import { Divisions } from "./Divisions";
import FormSelectWithScrollOnSearch from "../../components/Form/FormSelect/FormSelectWithScrollOnSearch";
import { SetIBroker, SetIBrokerID } from "../../store/actions/SetIBroker";
import { useParams } from "react-router";

const InitialFormView = () => {
  const IBrokerState = useSelector((state: AppState) => state.IBrokerState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotSelected, setIsNotSelected] = useState(false);

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["lg"]}px)`,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const urlSearchParams = new URLSearchParams(window.location.search);

  const { id }: { id?: string } = useParams();

  const iBrokerId = id || Object.fromEntries(urlSearchParams.entries()).PTR;

  useEffect(() => {
    if (!!iBrokerId) {
      dispatch(SetIBrokerID(iBrokerId));
      dispatch(SetIBroker(iBrokerId));
    }
  }, [iBrokerId, dispatch]);

  return (
    <LayoutAuth>
      <div className={styles.content}>
        <Formik
          initialValues={{
            atc_brokers_division: IBrokerState?.atcDivision
              ? {
                  value: getDivisionValue(IBrokerState.atcDivision),
                  label: IBrokerState.atcDivision,
                }
              : "",
            country_of_residence: "" as
              | string
              | { value: string; label: string },
            account_registration: "" as
              | string
              | { value: string; label: string },
            currency_deposit: "",
            platform: "",
            amount: "",
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            if (
              (values.country_of_residence as Record<"label" | "value", string>)
                .value === "US" &&
              !isNotSelected
            ) {
              setIsModalOpen(true);
              return;
            }

            // all countries as KY
            const atcDivision =
              (values.country_of_residence as Record<"label" | "value", string>)
                .value === "GB"
                ? Divisions.KY
                : Divisions.KY;
            dispatch(SetApply(1));
            dispatch(SetDivision(atcDivision.value));
            //@ts-ignore
            dispatch(SetAccount(values.account_registration.value));
            dispatch(
              SetData({
                initial: { ...values, atc_brokers_division: atcDivision },
              })
            );

            history.push("/create-account");
          }}
          validationSchema={Yup.object().shape({
            country_of_residence: Yup.object().required(
              "County of Residence required"
            ),
            account_registration: Yup.object().required(
              "Account Registration required"
            ),
            currency_deposit: Yup.object().required(
              "Currency Deposit required"
            ),
            platform: Yup.object().required("Platform required"),
            amount:
              IBrokerState?.minimumDepositUSD &&
              IBrokerState?.minimumDepositEUR &&
              IBrokerState?.minimumDepositGBP
                ? Yup.number()
                    .when("currency_deposit", {
                      is: (value: any) => value && value.value === "usd",
                      then: Yup.number()
                        .required("Amount required")
                        .min(
                          IBrokerState?.minimumDepositUSD,
                          `Minimum deposit amount should be ${IBrokerState?.minimumDepositUSD}`
                        ),
                    })
                    .when("currency_deposit", {
                      is: (value: any) => value && value.value === "eur",
                      then: Yup.number()
                        .required("Amount required")
                        .min(
                          IBrokerState?.minimumDepositEUR,
                          `Minimum deposit amount should be ${IBrokerState?.minimumDepositEUR}`
                        ),
                    })
                    .when("currency_deposit", {
                      is: (value: any) => value && value.value === "gbp",
                      then: Yup.number()
                        .required("Amount required")
                        .min(
                          IBrokerState?.minimumDepositGBP,
                          `Minimum deposit amount should be ${IBrokerState?.minimumDepositGBP}`
                        ),
                    })
                : Yup.number()
                    .required("Amount required")
                    .min(1000, "Minimum deposit amount should be 1,000"),
          })}
        >
          {({ isValid, values }) => (
            <Form>
              <div className={styles.wrapper}>
                <Heading
                  className="text-center mb-large mb-lg-medium"
                  size="h1"
                  title="Create Account"
                />
                {isResponsiveView && (
                  <p className={cn(styles.description, "mb-large text-center")}>
                    Complete the application in a few simple steps.
                  </p>
                )}
                <FormGroup label="Country of Residence" dark>
                  <Field
                    name="country_of_residence"
                    component={FormSelectWithScrollOnSearch}
                    options={Countries}
                    placeholder="Choose country"
                    dark
                  />
                </FormGroup>
                <FormGroup label="Account Registration" dark>
                  <Field
                    name="account_registration"
                    component={FormSelect}
                    options={[
                      { value: "individual", label: "Individual Account" },
                      { value: "corporate", label: "Corporate Account" },
                    ]}
                    placeholder="Choose account"
                    dark
                  />
                </FormGroup>
                <FormGroup label="Currency Deposit" dark>
                  <Field
                    name="currency_deposit"
                    component={FormSelect}
                    options={
                      //@ts-ignore
                      values.atc_brokers_division.value === "uk"
                        ? [
                            { value: "usd", label: "USD" },
                            { value: "eur", label: "EUR" },
                            { value: "gbp", label: "GBP" },
                          ]
                        : [
                            { value: "usd", label: "USD" },
                            { value: "eur", label: "EUR" },
                            { value: "gbp", label: "GBP" },
                          ]
                    }
                    placeholder="Choose Currency"
                    dark
                  />
                </FormGroup>
                <FormGroup label="Platform" dark>
                  <Field
                    name="platform"
                    component={FormSelect}
                    options={[
                      { value: "mt4", label: "MT4" },
                      { value: "fix-api", label: "FIX API" },
                    ]}
                    placeholder="Choose Platform"
                    dark
                  />
                </FormGroup>
                <FormGroup label="Initial Deposit Amount" dark className="mb-0">
                  <Field
                    name="amount"
                    component={FormInput}
                    type="number"
                    dark
                    validateOnChange
                  />
                </FormGroup>
                <div className="d-flex flex-row justify-content-center">
                  <Button
                    title="Start Application"
                    type="submit"
                    disabled={!isValid}
                  />
                  {isModalOpen && (
                    <div className={styles.element}>
                      <div className={styles.dialog}>
                        <p className="text-center ">
                          As you have selected the United States as your country
                          of residence, you will need to qualify as an Eligible
                          Contract Participant as defined in the Commodity
                          Exchange Act 1a(18), which will need to be verified by
                          a certified public accountant or attorney.
                        </p>
                        <div className="d-flex align-items-center justify-content-center">
                          <Button
                            className={styles.modalBtn}
                            title="Cancel"
                            handleClick={() => {
                              setIsModalOpen(false);
                            }}
                          />
                          <Button
                            title="Continue"
                            type="submit"
                            handleClick={() => {
                              setIsNotSelected(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {values?.country_of_residence &&
                typeof values?.country_of_residence === "object" ? (
                  //   values?.country_of_residence?.value === "GB" ? (
                  //     <p className="text-center mt-medium color-grey-lighter">
                  //       You are creating an account with ATC Brokers Limited (UK).
                  //       (FCA Regulated License 591361)
                  //     </p>
                  //   ) : (
                  //     <p className="text-center mt-medium color-grey-lighter">
                  //       You are creating an account with ATC Brokers Limited (KY).
                  //       (CIMA Regulated License 1448274)
                  //     </p>
                  //   )
                  <p className="text-center mt-medium color-grey-lighter">
                    You are creating an account with ATC Brokers Limited (KY).
                    (CIMA Regulated License 1448274)
                  </p>
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LayoutAuth>
  );
};

export default InitialFormView;
