import React from "react";
import { ReactComponent as IconAlert } from "../../../assets/icons/alert.svg";
import styles from "./FormUploadError.module.scss";
import cn from "classnames";

const FormUploadError = (props: IFormUploadError) => {
  const { errors, inputName } = props;

  return (
    <span className={cn(styles.element, "d-inline-flex align-items-center")}>
      <IconAlert className={styles.icon} />
      {errors[inputName]}
    </span>
  );
};
export default FormUploadError;

interface IFormUploadError {
  errors: any;
  inputName: string;
}
