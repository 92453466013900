import getOrdinalAbbr from "../../../../../functions/getOrdinalAbbr";

const getDirectorsNumber = (value: string, suffix?: boolean) => {
  const result = value.match(/\d+/);
  if (result) {
    const num = result[0];
    const abbr = getOrdinalAbbr(num);
    return suffix ? num + abbr : num;
  } else return null;
};

export default getDirectorsNumber;
