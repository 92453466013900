import React, { useEffect } from "react";
import styles from "./LoaderOverlay.module.scss";

const LoaderOverlay = ({ children }: { children: any }) => {
  useEffect(() => {
    let isMounted = true;
    isMounted && (document.body.style.overflow = "hidden");
    return () => {
      document.body.style.overflow = "unset";
      isMounted = false;
    };
  }, []);

  return <div className={styles.element}>{children}</div>;
};

export default LoaderOverlay;
