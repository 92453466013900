import React from "react";
import styles from "./UploadAreaThumbnail.module.scss";
import cn from "classnames";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import { ReactComponent as IconImage } from "../../../assets/icons/image.svg";
import { IFile } from "../UploadArea/UploadArea";

const UploadAreaThumbnail = (props: IUploadAreaThumbnail) => {
  const { file, onFileDelete } = props;

  return (
    <div className={styles.element}>
      <button type="button" className={styles.close} onClick={onFileDelete}>
        <IconClose className={styles.icon} />
      </button>
      <a
        href={file.blob}
        target="_blank"
        rel="noreferrer"
        className={cn(
          styles.container,
          "d-flex align-items-center justify-content-center"
        )}
      >
        {file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/bmp" ? (
          <Placeholder type={file.type} file={file} />
        ) : (
          <img className={styles.image} src={file.blob} alt={file.name} />
        )}
      </a>
    </div>
  );
};

export default UploadAreaThumbnail;

interface IUploadAreaThumbnail {
  file: IFile;
  onFileDelete: () => void;
}

const Placeholder = (props: IPlaceholder) => {
  const { file, type } = props;

  const ext = file.name.split(".")[1];

  return (
    <span
      className={cn(
        styles.placeholder,
        "d-flex flex-column align-items-center"
      )}
    >
      <IconImage />
      <span>
        {type === "application/pdf" && "PDF"}
        {type === "image/tiff" && "TIFF"}
        {(type === "application/msword" ||
          type === "application/doc" ||
          ext === "doc") &&
          "DOC"}
        {(type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          type === "application/docx" ||
          ext === "docx") &&
          "DOCX"}
      </span>
    </span>
  );
};

interface IPlaceholder {
  file: IFile;
  type: string;
}
