const getDivisionValue = (label: string) => {
  switch (label) {
    case "United Kingdom":
      return "uk";
    case "Cayman Islands":
      return "ky";
  }
};

export default getDivisionValue;
