import cn from "classnames";
import styles from "../../../CreateAccount.module.scss";
import Paragraph from "../../../../../components/Paragraph/Paragraph";
import Button from "../../../../../components/Button/Button";
import { IISCSection } from "../IndividualSelfCertification";

const ISCSection0 = ({ goToPrevious, setSection }: IISCSection0) => {
  return (
    <>
      <b className={cn(styles.subtitle, "d-inline-flex mb-medium")}>
        Instructions for completion
      </b>
      <Paragraph className="mb-medium">
        We are obliged under the Tax information Authority Law, the Regulations,
        and Guidance Notes made pursuant to that Law, and treaties and
        intergovernmental agreements entered into by the Cayman Islands in
        relation to the automatic exchange of information for tax matters
        (collectively “AEOI”) to collect certain information about each account
        holder’s tax status. Please complete the sections below as directed and
        provide any additional information that is requested. Please note that
        we may be obliged to share that information with relevant tax
        authorities. Terms referenced in this Form shall have the same meaning
        as applicable under the relevant Cayman Islands Regulations, Guidance
        Notes or international agreements.
      </Paragraph>
      <Paragraph className="mb-medium">
        If any of the information below regarding your tax residence or AEOI
        classification changes in the future, please ensure you advise us of
        these changes promptly. If you have any questions about how to complete
        this form, please refer to accompanying guidelines for completion or
        contact your tax advisor.
      </Paragraph>
      <Paragraph className="mb-medium">
        Please note that where there are joint account holders each investor is
        required to complete a separate Self-Certification form.
      </Paragraph>
      <div className="d-flex flex-row justify-content-between">
        <Button title="Back" variant="border" handleClick={goToPrevious} />
        <Button title="Next" handleClick={setSection} />
      </div>
    </>
  );
};

export default ISCSection0;

interface IISCSection0 extends IISCSection {
  setSection: () => void;
}
