import {
  AssessmentStateActionT,
  AssessmentStateT,
  CREATE_ASSESSMENT,
  CREATE_ASSESSMENT_DONE,
  CREATE_ASSESSMENT_FAILED,
  GET_ASSESSMENT_DETAILS,
  GET_ASSESSMENT_DETAILS_DONE,
  GET_ASSESSMENT_DETAILS_FAILED,
} from "../types/Assessment.type";

const initialState: AssessmentStateT = {
  assessmentId: null,
  loading: false,
  details: null
};

export default function AssessmentState (
  state = initialState,
  action: AssessmentStateActionT
) {
  switch ( action.type ) {
    case CREATE_ASSESSMENT:
      return { ...state, loading: true };
    case CREATE_ASSESSMENT_DONE:
      return {
        ...state,
        assessmentId: action.payload,
        loading: false,
      };
    case CREATE_ASSESSMENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_ASSESSMENT_DETAILS:
      return { ...state, loading: true };
    case GET_ASSESSMENT_DETAILS_DONE:
      return {
        ...state,
        loading: false,
        details: action.payload,
      };
    case GET_ASSESSMENT_DETAILS_FAILED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
