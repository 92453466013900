import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { ReactComponent as IconCalendar } from "../../../assets/icons/calendar.svg";
import { ReactComponent as IconArrow } from "../../../assets/icons/arrow-down.svg";
import styles from "./FormDate.module.scss";
import cn from "classnames";
import moment, { Moment } from "moment";

const FormDate = (props: IFormDate) => {
  const { form, field } = props;
  const [val, changeVal] = useState(field.value && new Date(field.value));

  const thisHandleChange = (e: Date) => {
    changeVal(e);
    if (e === null) {
      form.setFieldValue(field.name, "");
    } else if (form) {
      form.setFieldValue(field.name, moment(e).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    changeVal(field.value && new Date(field.value));
  }, [field.value]);

  return (
    <DatePicker
      className={cn("w-100", styles.element)}
      onChange={(e: any) => thisHandleChange(e)}
      value={val}
      clearIcon={null}
      calendarIcon={<IconCalendar />}
      showLeadingZeros={true}
      format="yyyy-MM-dd"
      prev2Label={null}
      next2Label={null}
      locale="en-US"
      maxDate={new Date()}
      minDetail="century"
      formatShortWeekday={(locale, date) =>
        ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()]
      }
      prevLabel={<IconArrow className={cn(styles.icon, styles["prevIcon"])} />}
      nextLabel={<IconArrow className={cn(styles.icon, styles["nextIcon"])} />}
      dayPlaceholder="DD"
      monthPlaceholder="MM"
      yearPlaceholder="YYYY"
    />
  );
};
export default FormDate;

interface IFormDate {
  form?: any;
  field?: any;
  handleChange?: (e: Moment) => void;
}
