export const SET_ACCOUNT = "SET_ACCOUNT";

export type setAccountT = {
  type: "SET_ACCOUNT";
  data: "individual" | "corporate";
};

const initialState = "";

export default function AccountState(
  state = initialState,
  action: setAccountT
) {
  if (action.type === SET_ACCOUNT) {
    return action.data;
  }
  return state;
}
