import React from "react";
import cn from "classnames";
import styles from "./ApplicationEnd.module.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import ModalComplete from "../../components/Modal/ModalComplete/ModalComplete";
import { useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import AppropriatenessWarning from "../../components/AppropriatenessWarning/AppropriatenessWarning";
import LayoutMain from "../../components/LayoutMain/LayoutMain";
import ContainerMain from "../../components/ContainerMain/ContainerMain";

const ApplicationEndView = () => {
  const WarningState = useSelector((state: AppState) => state.WarningState);

  return (
    <LayoutMain>
      <ContainerMain scrollable dark>
        <div className={cn(styles.element, "d-flex align-items-center")}>
          <div className={cn(styles.header, "d-flex justify-content-center")}>
            <div className={styles.logo}>
              <Logo />
            </div>
          </div>
          {WarningState ? <AppropriatenessWarning /> : <ModalComplete />}
        </div>
      </ContainerMain>
    </LayoutMain>
  );
};

export default ApplicationEndView;
