import React from "react";
import styles from "./Paragraph.module.scss";
import cn from "classnames";

const Paragraph = (props: {
  children: any;
  className?: string;
  dark?: boolean;
  black?: boolean;
  bigger?: boolean;
  upperCase?: boolean;
}) => {
  const { children, className, dark, black, bigger, upperCase } = props;

  return (
    <p
      className={cn(
        styles.element,
        className && className,
        dark && styles["isDark"],
        black && styles["isBlack"],
        bigger && styles["isBigger"],
        upperCase && styles["isUpperCase"]
      )}
    >
      {children}
    </p>
  );
};
export default Paragraph;
