import {
  SET_IBROKER,
  SET_IBROKER_DONE,
  SET_IBROKER_ID,
} from "../reducers/IBrokerState";

export const SetIBrokerID = (id: string) => {
  return {
    type: SET_IBROKER_ID,
    id,
  };
};
export const SetIBroker = (id: string) => {
  return {
    type: SET_IBROKER,
    id,
  };
};

export const SetIBrokerDone = (payload: any) => {
  return {
    type: SET_IBROKER_DONE,
    payload,
  };
};
