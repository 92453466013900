import React from "react";
import { QuestionT } from "../../../../../store/types/Questions.type";
import styles from "./AssessmentQuestionsHeading.module.scss";
import getDirectorsNumber from "./getDirectorsNumber";

const AssessmentQuestionsHeading = ({ data }: { data: QuestionT }) => {
  if (data.directorShareholderName && data.directorShareholderNumber) {
    return (
      <div className={styles.element}>
        Director / Shareholder {""}
        {getDirectorsNumber(data.directorShareholderNumber)}: {""}
        <span className={styles.grey}>{data.directorShareholderName}</span>
      </div>
    );
  } else if (!data.directorShareholderName && data.directorShareholderNumber) {
    return (
      <div className={styles.element}>
        {getDirectorsNumber(data.directorShareholderNumber, true)} {""} Director
        / Shareholder
      </div>
    );
  } else return null;
};
export default AssessmentQuestionsHeading;
