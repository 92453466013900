export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_DONE = "GET_QUESTIONS_DONE";
export const GET_QUESTIONS_FAILED = "GET_QUESTIONS_FAILED";
export const SEND_ANSWERS = "SEND_ANSWERS";
export const SEND_ANSWERS_DONE = "SEND_ANSWERS_DONE";
export const SEND_ANSWERS_FAILED = "SEND_ANSWERS_FAILED";
export const SEND_FILE = "SEND_FILE";
export const SEND_FILE_DONE = "SEND_FILE_DONE";
export const SEND_FILE_FAILED = "SEND_FILE_FAILED";
export const SET_PREVIOUS_QUESTIONS = "SET_PREVIOUS_QUESTIONS";
export const SET_PREVIOUS_QUESTIONS_DONE = "SET_PREVIOUS_QUESTIONS_DONE";
export const SET_PREVIOUS_QUESTIONS_FAILED = "SET_PREVIOUS_QUESTIONS_FAILED";

export type PossibleResponseT = {
  responseKey: string,
  responseText: string
}

export type QuestionT = {
  type: "Value" | "Optional" | "Text" | "OptionalDate" | "Date" | "Phone" | "Email" | "Document" | "AdditionalDocument",
  maxSize: number,
  regEx: string,
  allowedFileTypes: {} | null,
  multipleUpload: {} | null,
  questionId: number,
  questionDefinitionId: number,
  questionText: string,
  possibleResponses: PossibleResponseT[] | [],
  multipleChoice: boolean,
  showCertifiedDocumentInformation: string | null,
  assessmentType: "Assessment" | "SuitabilityAndAppropriateness" | "KYCCheck" | "POIDocuments" | "PORDocuments" | "Documents",
  directorShareholderNumber: string | null,
  directorShareholderName: string | null,
  noOfDocumentsPerCountry: number | null
}

export type AnswersT = {
  questionId: number,
  responseKeys?: string[],
  text?: string
}

export type GetQuestionsT = {
  type: typeof GET_QUESTIONS;
  userId: string | null
}

export type GetQuestionsDoneT = {
  type: typeof GET_QUESTIONS_DONE;
  payload: QuestionT[]
}
export type GetQuestionsFailedT = {
  type: typeof GET_QUESTIONS_FAILED;
}

export type SendAnswersT = {
  type: typeof SEND_ANSWERS;
  payload: AnswersT[]
}

export type SendAnswersDoneT = {
  type: typeof SEND_ANSWERS_DONE;
}

export type SendAnswersFailedT = {
  type: typeof SEND_ANSWERS_FAILED;
}

export type SendFileT = {
  type: typeof SEND_FILE;
  payload: FormData
}

export type SendFileDoneT = {
  type: typeof SEND_FILE_DONE;
}

export type SendFileFailedT = {
  type: typeof SEND_FILE_FAILED;
}

export type SetPreviousQuestionsT = {
  type: typeof SET_PREVIOUS_QUESTIONS;
}

export type SetPreviousQuestionsDoneT = {
  type: typeof SET_PREVIOUS_QUESTIONS_DONE;
}

export type SetPreviousQuestionsFailedT = {
  type: typeof SET_PREVIOUS_QUESTIONS_FAILED;
}

export type QuestionsStateActionT =
  | GetQuestionsT | GetQuestionsDoneT | GetQuestionsFailedT | SendAnswersT | SendAnswersDoneT | SendAnswersFailedT | SendFileT | SendFileDoneT | SendFileFailedT | SetPreviousQuestionsT | SetPreviousQuestionsDoneT | SetPreviousQuestionsFailedT

export type QuestionsStateT = {
  loading: boolean;
  questions: QuestionT[];
  loaded: boolean,
  error: boolean
};