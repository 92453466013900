import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import ApplyState from "./ApplyState";
import DivisionState from "./DivisionState";
import AccountState from "./AccountState";
import DataState from "./DataState";
import AssessmentState from "./AssessmentState";
import QuestionsState from "./QuestionsState";
import AuthenticationState from "./Authentication";
import IBrokerState from "./IBrokerState";
import IPAddressState from "./IPAddressState";
import WarningState from "./WarningState";
import StepStage from "./StepStage";
import DeclineState from "./DeclineState";
import { LOGOUT_USER_DONE } from "../types/Authentication.type";

const createAppReducer = ( history: History ) =>
  combineReducers( {
    router: connectRouter( history ),
    ApplyState,
    Data: DataState,
    DivisionState,
    AccountState,
    AssessmentState,
    QuestionsState,
    AuthenticationState,
    IBrokerState,
    IPAddressState,
    WarningState,
    StepStage,
    DeclineState
  } );

type AppReducerT = ReturnType<typeof createAppReducer>;
export type AppState = ReturnType<AppReducerT>;

const initialState: AppState = {} as AppState;

let appReducer: AppReducerT;

function Container ( history: History ) {
  if ( !appReducer ) {
    appReducer = createAppReducer( history );
  }

  return function ( state: AppState = initialState, action: any ): AppState {
    if ( action.type === LOGOUT_USER_DONE ) {
      return appReducer( undefined, action );
    }

    return appReducer( state, action );
  };
}
export default Container;
