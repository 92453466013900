import { QuestionT } from "../../../../store/types/Questions.type";

const getAssessmentInitialValues = (questionsData: QuestionT[]) => {
  const initialValues = questionsData.reduce((obj: any, elem: QuestionT) => {
    obj[elem.questionId.toString()] = "";
    return obj;
  }, {});
  return initialValues;
};

export default getAssessmentInitialValues;
