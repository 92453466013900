import { Countries } from "../views/InitialForm/Countries";

const getCountryLabel = (value: string) => {
  const result = Countries.find((item: { value: string; label: string }) => {
    return item.value === value;
  });
  return result && result.label;
};

export default getCountryLabel;
