import { Dispatch, SetStateAction } from "react";
import Heading from "../../Heading/Heading";
import Modal from "../Modal";
import Paragraph from "../../Paragraph/Paragraph";
import Button from "../../Button/Button";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import styles from "../ModalConfirmPassword/ModalConfirmPassword.module.scss";

const ModalOmmissionOfTaxID = ({
  setModalOpen,
  setSendForm,
}: {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setSendForm: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Modal>
      <button className={styles.close} onClick={() => setModalOpen(false)}>
        <IconClose />
      </button>
      <Heading
        title="Omission of Tax ID"
        size="h1"
        className="text-center mb-medium"
      />
      <Paragraph className="mb-medium">
        It appears you have elected not to disclose your Tax ID.
      </Paragraph>
      <Paragraph className="mb-large">
        Please refer to the following information regarding this course of
        action:
      </Paragraph>
      <Paragraph className="mb-medium">
        <b>Recalcitrant Accounts</b>
      </Paragraph>
      <Paragraph className="mb-small">
        A recalcitrant account holder is any financial account which fails to
        comply with reasonable requests to provide:
      </Paragraph>
      <Paragraph className="mb-medium">
        a. information that is necessary to determine the account holder’s
        FATCA/CRS status; and
      </Paragraph>
      <Paragraph className="mb-large">
        b. information that needs to be reported about the account for FATCA/CRS
        purposes.
      </Paragraph>
      <hr />
      <Paragraph className="mb-large">
        In cases where a foreign financial institutions (“FFI”) is unable to
        determine the account status or in the case of any uncertainty, the FFI
        will report recalcitrant accounts just like any other Reportable Account
        (i.e. treating the account or, where applicable, controlling person, as
        a Reportable Person). For the avoidance of doubt, if an existing account
        holder refuses to provide a self-certification, the customer will be
        deemed a reportable person of the jurisdiction for which he holds any
        indicia such as mailing address and be reported, whether that
        information be accurate or not.
      </Paragraph>
      <Paragraph className="mb-large">
        Accordingly, the Regulations (Cayman) provide that a person may be
        criminally liable if he makes a self-certification that is false in a
        material particular for the CRS and a Cayman Financial Institution is
        given the self-certification for any purpose for which it was made or
        purports to have been made.
      </Paragraph>
      <Paragraph>
        Upon careful consideration of this information, please confirm that you
        are electing to proceed with a trading account with ATC BROKERS Limited
        - Cayman Islands without providing your TAX ID. If you have decided to
        provide the information, kindly complete and resend your FATCA Self
        Certification form.
      </Paragraph>
      <div className="d-flex flex-row justify-content-between">
        <Button
          title="Back"
          variant="border"
          handleClick={() => setModalOpen(false)}
        />
        <Button
          title="Submit without Tax ID"
          handleClick={() => {
            setModalOpen(false);
            setSendForm(true);
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalOmmissionOfTaxID;
