import React, { ChangeEvent, useEffect, useState } from "react";
import cn from "classnames";
import styles from "./FormCheckbox.module.scss";
import { ReactComponent as IconCheck } from "../../../assets/icons/select.svg";

const FormCheckbox = (props: IFormCheckbox) => {
  const { label, field, form, value } = props;

  const [active, setActive] = useState(
    form.values[field.name] ? form.values[field.name].includes(value) : false
  );

  useEffect(() => {
    if (form.values[field.name] && form.values[field.name].includes(value))
      setActive(true);
    else setActive(false);
  }, [field.name, form.values, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setActive(isChecked);
    if (field.onChange) {
      field.onChange(e);
    }
  };

  return (
    <label
      className={cn(
        styles.element,
        active && styles["isActive"],
        "d-flex mb-medium"
      )}
    >
      <input
        className={styles.input}
        type="checkbox"
        value={value}
        checked={active}
        name={field.name}
        onChange={(e) => handleChange(e)}
      />
      <span className={cn(styles.box, active && styles["isActive"])}>
        {active && <IconCheck />}
      </span>
      <span className={styles.label}>{label}</span>
    </label>
  );
};

export default FormCheckbox;

export interface IFormCheckbox {
  label: string;
  field: any;
  form: any;
  value: string;
}
