import * as Yup from "yup";
import { MIN_SELECT_OPTIONS } from "../../../../constans";
import { QuestionT } from "../../../../store/types/Questions.type";

const getAssessmentValidation = (questionsData: QuestionT[]) => {
  const validationSchema = questionsData.reduce((obj: any, elem: QuestionT) => {
    switch (elem.type) {
      case "Value":
        // checkbox
        if (elem.multipleChoice) {
          if (elem.noOfDocumentsPerCountry) {
            obj[elem.questionId.toString()] = Yup.array()
              .required("This field is required")
              .min(
                elem.noOfDocumentsPerCountry,
                `You must pick minimum ${elem.noOfDocumentsPerCountry} documents`
              );
          } else
            obj[elem.questionId.toString()] = Yup.array().required(
              "This field is required"
            );
          return obj;
        } else {
          if (elem.possibleResponses.length > MIN_SELECT_OPTIONS) {
            // select
            obj[elem.questionId.toString()] = Yup.object().required(
              "This field is required"
            );
            return obj;
          } else {
            // radio button
            obj[elem.questionId.toString()] = Yup.string().required(
              "This field is required"
            );
            return obj;
          }
        }
      case "Text":
        // text input
        obj[elem.questionId.toString()] = Yup.string()
          .required("This field is required")
          .max(elem.maxSize, "Text is too long");
        return obj;
      case "Optional":
        // text input not required
        obj[elem.questionId.toString()] = Yup.string()
          .max(elem.maxSize, "Text is too long")
          .nullable()
          .notRequired();
        return obj;
      case "Date":
        // text input not required
        obj[elem.questionId.toString()] = Yup.string()
          .required("This field is required")
          .max(elem.maxSize, `Must be maximum ${elem.maxSize} characters`)
          .matches(new RegExp(elem.regEx), "Valid date format is YYYY-MM-DD");
        return obj;
      case "OptionalDate":
        // date optional
        obj[elem.questionId.toString()] = Yup.string()
          .max(elem.maxSize, `Must be maximum ${elem.maxSize} characters`)
          .matches(new RegExp(elem.regEx), "Valid date format is YYYY-MM-DD")
          .nullable()
          .notRequired();
        return obj;
      case "Phone":
        // text input phone
        obj[elem.questionId.toString()] = Yup.string()
          .matches(
            new RegExp(elem.regEx),
            "Phone number is not valid. Add prefix started with +."
          )
          .max(elem.maxSize, "Phone is too long");
        return obj;
      case "Email":
        // text input phone
        obj[elem.questionId.toString()] = Yup.string()
          .required("This field is required")
          .matches(new RegExp(elem.regEx), "This is not a valid email")
          .max(elem.maxSize, "Email is too long");
        return obj;
      case "Document":
        // document upload
        obj[elem.questionId.toString()] = Yup.mixed()
          .required("This field is required")
          .test(
            "fileSize",
            `File exceeds maximum allowed upload size of 4000 KB.`,
            (value: File) => value && value.size <= elem.maxSize
          )
          .test(
            "fileType",
            "Wrong file extension",
            (value: File) =>
              value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/tiff",
                "image/bmp",
                "application/pdf",
              ].includes(value.type)
          );
        return obj;
      case "AdditionalDocument":
        // document upload
        obj[elem.questionId.toString()] = Yup.mixed()
          .required("This field is required")
          .test(
            "fileSize",
            `File exceeds maximum allowed upload size of 4000 KB.`,
            (value: File) => value && value.size <= elem.maxSize
          )
          .test(
            "fileType",
            "Wrong file extension",
            (value: File) =>
              value &&
              [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/tiff",
                "image/bmp",
                "application/pdf",
              ].includes(value.type)
          );
        return obj;
      // obj[elem.questionId.toString()] = Yup.array(
      //   Yup.mixed()
      //     .test(
      //       "fileSize",
      //       "Size of each document should be 4000KB maximum",
      //       (value: File) => value.size <= elem.maxSize
      //     )
      //     .test(
      //       "fileType",
      //       "Wrong file extension",
      //       (value: File) =>
      //         [
      //           "image/png",
      //           "image/jpg",
      //           "image/jpeg",
      //           "image/tiff",
      //           "image/bmp",
      //           "application/pdf",
      //           "application/doc",
      //           "application/docx",
      //           "application/msword",
      //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      //         ].includes(value.type) || value.type === ""
      //     )
      //     .test(
      //       "fileTypeWhenEmpty",
      //       "Wrong file extension",
      //       (value: File) =>
      //         value.type !== "" ||
      //         value.name.includes(".doc") ||
      //         value.name.includes(".docx")
      //     )
      // )
      //   .required("This field is required")
      //   .max(6, "You can upload a maximum of 6 documents");
      // return obj;
      default:
        obj[elem.questionId.toString()] = Yup.string().required(
          "This field is required"
        );
        return obj;
    }
  }, {});
  return validationSchema;
};

export default getAssessmentValidation;
