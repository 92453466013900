import { useState } from "react";
import styles from "./AppropriatenessWarning.module.scss";
import Container from "../Bootstrap/Container";
import Heading from "../Heading/Heading";
import Paragraph from "../Paragraph/Paragraph";
import Button from "../Button/Button";
import List from "../List/List";
import ListItem from "../List/ListItem/ListItem";
import ModalComplete from "../../components/Modal/ModalComplete/ModalComplete";
import { logoutUser } from "../../store/actions/Authentication";
import { useDispatch } from "react-redux";
import { acceptAssessmentWarning } from "../../store/actions/Assessment";
import { SetDecline } from "../../store/actions/SetDecline";

const AppropriatenessWarning = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  window.onbeforeunload = () => true;
  window.onunload = () => dispatch(logoutUser());

  return (
    <>
      <Container fluid>
        <div className={styles.content}>
          <Heading
            className="text-center mb-large"
            size="h1"
            title="Appropriateness Warning"
          />
          <Paragraph dark bigger className="mb-medium">
            Financial derivatives (such as rolling spot forex, futures including
            interest rates, yields and indices), Financial contracts for
            differences (CFDs)
          </Paragraph>
          <hr />
          <Paragraph dark bigger className="mb-medium">
            Thank you for completing our appropriateness assessment via the
            online application. On the basis of the answers that you have
            provided, we have determined that{" "}
            <b>above selected service(s) is not appropriate for you.</b>
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            The transactions that our clients enter into entail a high degree of
            risk. We do not thick that you have sufficient knowledge or
            experience to enter into this sort of transaction or fully
            understand the risks involved.
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            <b>
              As such we recommend that you do not proceed to open an account
              with us.
            </b>
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            If, despite the above, you do wish to proceed to open an account, we
            strongly recommend that you carry out some further research to
            familiarise yourself with this market. In order to give you time to
            carry out this research, we will not take any further steps for at
            least 24 hours.
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            If you would like to open an account with us, please click the “I
            accept” button below. By clicking the “I accept” button you will be:
          </Paragraph>
          <List>
            <ListItem dark bigger>
              Confirm that, in your view after performing additional research,
              you do have sufficient knowledge and experience to enter into
              transactions in this market; and
            </ListItem>
            <ListItem dark bigger>
              Acknowledging that we have warned you that, in our view, this
              service is not appropriate for you
            </ListItem>
          </List>
          <hr />
          <Heading
            className="text-center mb-large"
            size="h1"
            title="High Risk Notice"
          />
          <Paragraph dark bigger className="mb-medium">
            It is important that as a potential customer that you understand the
            key risks related to opening an account and trading with, ATC
            Brokers Ltd (“ATC”). In addition to the Risk Disclosure Notice,
            there are certain occasions where ATC feels obliged or may be
            required to inform clients of the high risk involved in Foreign
            Exchange (Forex) and Contract for Difference (CFD) trading.
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            ATC reviews various criteria on the account application. Based on
            your responses to questions on the application, we have identified
            that you fall into one of the categories below and encourage you to
            consider whether trading Forex and/or CFD’s is a suitable investment
            for you. After reading the disclosures below, if you decide that you
            still want to open a trading account, simply proceed with the online
            application
          </Paragraph>
          <List>
            <ListItem dark bigger>
              Age: 18 to 21 years of age
            </ListItem>
            <ListItem dark bigger>
              Age: 65 years of age or over
            </ListItem>
            <ListItem dark bigger>
              Currently Retired
            </ListItem>
            <ListItem dark bigger>
              Currently Unemployed
            </ListItem>
            <ListItem dark bigger>
              Filed Bankruptcy
            </ListItem>
            <ListItem dark bigger>
              Annual Income under 25,000USD
            </ListItem>
            <ListItem dark bigger>
              Net Worth under 25,000 USD
            </ListItem>
            <ListItem dark bigger>
              Liquid Assets under 25,000USD
            </ListItem>
            <ListItem dark bigger>
              Trading Experience: No experience in all 6 trading categories
            </ListItem>
          </List>
          <Paragraph dark bigger className="mb-medium">
            Based on the above said criteria, ATC believes that, in your case,
            leveraged Forex and CFD trading may be too risky. Please take the
            time to consider this matter carefully before deciding to complete
            the account application process.
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            If you have pursued only conservative forms of investment in the
            past, you may wish to study Forex and CFD trading further before
            continuing an investment of this nature. You must realise that you
            could sustain a total loss of all funds you deposit with your broker
            as initial margin as well as substantial amounts of capital, when
            trading in the Forex and CFD’s should the market go against your
            investment.
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            Additionally, you must fully understand the nature and risks of
            Forex and CFD investments if you will be unable to meet your
            obligations to others should you suffer investment losses.
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            Funding your account and beginning to trade constitutes your
            acceptance and understanding of this high risk notice from ATC.
          </Paragraph>
          <Paragraph dark bigger className="mb-medium">
            To continue with the account application process, please click the
            “I accept” button. Declining this notice will prevent the
            application to be submitted and the account from opening.
          </Paragraph>
          <div className="d-flex flex-row justify-content-between">
            <Button
              title="I Decline"
              variant="border"
              handleClick={() => {
                dispatch(acceptAssessmentWarning("DECLINE"));
                dispatch(SetDecline(true));
                setModalOpen(true);
              }}
            />
            <Button
              title="I Accept"
              handleClick={() => {
                dispatch(acceptAssessmentWarning("ACCEPT"));
                setModalOpen(true);
              }}
            />
          </div>
        </div>
      </Container>
      {modalOpen && <ModalComplete />}
    </>
  );
};

export default AppropriatenessWarning;
