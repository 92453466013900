import { Field } from "formik";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInput from "../../../../../components/Form/FormInput/FormInput";
import { QuestionT } from "../../../../../store/types/Questions.type";

const TextField = (props: ITextField) => {
  const { question, form } = props;

  if (form) {
    return (
      <div className="row">
        <div className="col-12 col-md-7">
          <FormGroup className="mb-md-large" label={question.questionText}>
            <Field
              component={FormInput}
              name={question.questionId.toString()}
              required={false}
              bgGray
            />
          </FormGroup>
        </div>
      </div>
    );
  } else {
    return (
      <FormGroup
        className={"mb-large-last-0 pb-large-last-0"}
        bold
        label={question.questionText}
      >
        <Field
          component={FormInput}
          name={question.questionId.toString()}
          required={false}
        />
      </FormGroup>
    );
  }
};

export default TextField;

interface ITextField {
  question: QuestionT;
  form: boolean;
}
