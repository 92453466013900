import React from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import CreateAccountView from "./views/CreateAccount/CreateAccount.view";
import { useSelector } from "react-redux";
import { history } from "./store";
import { AppState } from "./store/reducers";
import ApplicationStartView from "./views/ApplicationStart/ApplicationStart.view";
import InitialFormView from "./views/InitialForm/InitialForm.view";
import ApplicationView from "./views/Application/Application.view";
import SignInView from "./views/SignIn/SignIn.view";
import ApplicationEndView from "./views/ApplicationEnd/ApplicationEnd.view";

function App() {
  const ApplyState = useSelector((state: AppState) => state.ApplyState);


  switch (ApplyState) {
    case 1:
      return (
        <Router history={history}>
          <Switch>
            <Route exact path="/create-account">
              <CreateAccountView />
            </Route>
          </Switch>
        </Router>
      );
    case 2:
      return (
        <Router history={history}>
          <Switch>
            <Route exact path="/online-application/start">
              <ApplicationStartView />
            </Route>
          </Switch>
        </Router>
      );
    case 3:
      return (
        <Router history={history}>
          <Switch>
            <Route exact path="/online-application">
              <ApplicationView />
            </Route>
          </Switch>
        </Router>
      );
    case 4:
      return (
        <Router history={history}>
          <Switch>
            <Route exact path="/online-application/end">
              <ApplicationEndView />
            </Route>
          </Switch>
        </Router>
      );
    default:
      return (
        <Router history={history}>
        <Redirect to="/" />
          <Switch>
            <Route exact path="/">
              <InitialFormView />
            </Route>
            <Route path="/sign-in">
              <SignInView />
            </Route>
            <Route path="/:id">
              <InitialFormView />
            </Route>
          </Switch>
        </Router>
      );
  }
}

export default App;
