import { Field, FormikErrors, FormikTouched } from "formik";
import styles from "../DocumentField/DocumentField.module.scss";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInputError from "../../../../../components/Form/FormInputError/FormInputError";
import Paragraph from "../../../../../components/Paragraph/Paragraph";
import { QuestionT } from "../../../../../store/types/Questions.type";
// import UploadAreaMultiple from "../../../../../components/DocumentUploader/UploadAreaMultiple/UploadAreaMultiple";
import BoxGrey from "../../../../../components/BoxGrey/BoxGrey";
import WarningLabel from "../../../../../components/WarningLabel/WarningLabel";
import UploadArea from "../../../../../components/DocumentUploader/UploadArea/UploadArea";

const MultipleDocumentsField = (props: IMultipleDocumentsField) => {
  const { question, errors, touched, index } = props;

  return (
    <FormGroup
      className={"mb-large-last-0 pb-large-last-0"}
      key={index}
      bold
      hLine
    >
      <div className={styles.uploaderWrapper}>
        {index === 0 && (
          <>
            <BoxGrey className="mb-large">
              <Paragraph className="mb-medium" black bigger>
                Additional documents are subject to manual verification.{" "}
                <span className="blue">
                  Please make sure to upload all documents relating to this
                  request under this upload tab, kindly note you can upload a
                  maximum of 6 documents.
                </span>{" "}
                These include files with extension such as (.TIFF, .PNG, .BMP,
                .PDF, .JPG, .DOC, .DOCX). Each document size should be 1KB
                minimum and 4000KB maximum.
              </Paragraph>
            </BoxGrey>
            {question.showCertifiedDocumentInformation && (
              <WarningLabel text={question.showCertifiedDocumentInformation} />
            )}
          </>
        )}
        <Field
          component={
            // question.multipleUpload === "true" ? UploadAreaMultiple : UploadArea
            UploadArea
          }
          name={question.questionId.toString()}
          title={question.questionText}
          {...props}
        />
        {touched[question.questionId.toString()] &&
          errors[question.questionId.toString()] &&
          errors[question.questionId.toString()] ===
            "This field is required" && (
            <FormInputError
              className={"mt-medium"}
              errors={errors}
              inputName={question.questionId.toString()}
            />
          )}
      </div>
    </FormGroup>
  );
};

export default MultipleDocumentsField;

interface IMultipleDocumentsField {
  question: QuestionT;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  index: number;
}
