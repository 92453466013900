import React from "react";
import styles from "./Loader.module.scss";
import cn from "classnames";

const Loader = (props: { white?: boolean }) => {
  const { white } = props;
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M36.4998 14.0385C34.9486 14.0385 33.6921 12.782 33.6921 11.2308V2.80769C33.6921 1.25644 34.9486 0 36.4998 0C38.0511 0 39.3075 1.25644 39.3075 2.80769V11.2308C39.3075 12.782 38.0511 14.0385 36.4998 14.0385Z"
      />
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M62.3097 10.6905C63.4061 11.7869 63.4061 13.5641 62.3097 14.6605L56.3532 20.6171C55.2568 21.7135 53.4795 21.7135 52.3831 20.6171C51.2867 19.5207 51.2867 17.7434 52.3831 16.647L58.3396 10.6905C59.4346 9.59406 61.2133 9.59406 62.3097 10.6905Z"
      />
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M73.0001 36.5001C73.0001 38.0513 71.7437 39.3078 70.1924 39.3078H61.7694C60.2181 39.3078 58.9617 38.0513 58.9617 36.5001C58.9617 34.9488 60.2181 33.6924 61.7694 33.6924H70.1924C71.7437 33.6924 73.0001 34.9488 73.0001 36.5001Z"
      />
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M62.3097 62.3099C61.2133 63.4063 59.436 63.4063 58.3396 62.3099L52.3831 56.3534C51.2867 55.257 51.2867 53.4797 52.3831 52.3833C53.4795 51.2869 55.2568 51.2869 56.3532 52.3833L62.3097 58.3399C63.4061 59.4349 63.4061 61.2135 62.3097 62.3099Z"
      />
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M36.4998 72.9999C34.9486 72.9999 33.6921 71.7434 33.6921 70.1922V61.7691C33.6921 60.2179 34.9486 58.9614 36.4998 58.9614C38.0511 58.9614 39.3075 60.2179 39.3075 61.7691V70.1922C39.3075 71.7434 38.0511 72.9999 36.4998 72.9999Z"
      />
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M20.6168 52.3833C21.7132 53.4797 21.7132 55.257 20.6168 56.3534L14.6603 62.3099C13.5639 63.4063 11.7866 63.4063 10.6902 62.3099C9.59382 61.2135 9.59382 59.4363 10.6902 58.3399L16.6467 52.3833C17.7431 51.2869 19.5204 51.2869 20.6168 52.3833Z"
      />
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M14.0385 36.5001C14.0385 38.0513 12.782 39.3078 11.2308 39.3078H2.80769C1.25644 39.3078 0 38.0513 0 36.5001C0 34.9488 1.25644 33.6924 2.80769 33.6924H11.2308C12.782 33.6924 14.0385 34.9488 14.0385 36.5001Z"
      />
      <path
        className={cn(styles.item, white && styles["isWhite"])}
        d="M20.6168 20.6171C19.5204 21.7135 17.7431 21.7135 16.6467 20.6171L10.6902 14.6605C9.59382 13.5641 9.59382 11.7869 10.6902 10.6905C11.7866 9.59406 13.5639 9.59406 14.6603 10.6905L20.6168 16.647C21.7132 17.7434 21.7132 19.5207 20.6168 20.6171Z"
      />
    </svg>
  );
};

export default Loader;
