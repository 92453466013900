export const SET_DIVISION = "SET_DIVISION";

export type setDivisionT = {
  type: "SET_DIVISION";
  data: "uk" | "ky";
};

const initialState = "";

export default function DivisionState(
  state = initialState,
  action: setDivisionT
) {
  if (action.type === SET_DIVISION) {
    return action.data;
  }
  return state;
}
