import React, {ReactElement} from "react";
import {IListItem} from "./ListItem/ListItem";

const List = ({children}: IList) => {

    return(
        <ul>
            {children}
        </ul>
    )
}

export default List;

interface IList {
    children: ReactElement<IListItem> | ReactElement<IListItem>[];
}
