import { toast } from "react-toastify";
import { all, put, select, take, takeLatest } from "redux-saga/effects";
import {
  getQuestions,
  getQuestionsDone,
  getQuestionsFailed,
  sendAnswersDone,
  sendAnswersFailed,
  sendFileDone,
  sendFileFailed,
  setPreviousQuestionsDone,
  setPreviousQuestionsFailed,
} from "../actions/Questions";
import axios from "../axios";
import { AppState } from "../reducers";
import {
  GetQuestionsT,
  GET_QUESTIONS,
  SendAnswersT,
  SendFileT,
  SEND_ANSWERS,
  SEND_FILE,
  SET_PREVIOUS_QUESTIONS,
} from "../types/Questions.type";
import { AuthenticationStateT } from "../types/Authentication.type";
import { getAssessmentDetails } from "../actions/Assessment";
import { GET_ASSESSMENT_DETAILS_DONE } from "../types/Assessment.type";

function* getQuestionsHandler({ userId }: GetQuestionsT) {
  try {
    const AuthenticationState: AuthenticationStateT = yield select(
      ({ AuthenticationState }: AppState) => AuthenticationState
    );

    const { data } = yield axios.get(
      `/muinmos/currentQuestion/${userId || AuthenticationState.userId}`
    );
    if (!data.length) {
      yield put(getAssessmentDetails(userId || AuthenticationState.userId));
      yield take(GET_ASSESSMENT_DETAILS_DONE);
    }
    yield put(getQuestionsDone(data));
  } catch (error) {
    toast.error("Something went wrong, questions cannot be displayed");
    yield put(getQuestionsFailed());
  }
}

function* sendAnswersHandler({ payload }: SendAnswersT) {
  try {
    const { userId }: AuthenticationStateT = yield select(
      ({ AuthenticationState }: AppState) => AuthenticationState
    );

    const { data } = yield axios.post(`/muinmos/answer/${userId}`, {
      answers: payload,
    });

    yield put(sendAnswersDone());
    if (data === "Responding") {
      yield put(getQuestions(userId));
    } else if (data === "Completed") {
      yield put(getAssessmentDetails(userId));
    }
  } catch (error) {
    yield put(sendAnswersFailed());
    toast.error("Something went wrong, please try again.");
  }
}

function* sendFileHandler({ payload }: SendFileT) {
  try {
    const { userId }: AuthenticationStateT = yield select(
      ({ AuthenticationState }: AppState) => AuthenticationState
    );

    const { data } = yield axios.put(`/muinmos/answer/${userId}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(sendFileDone());
    if (data === "Responding") {
      yield put(getQuestions(userId));
    } else if (data === "Completed") {
      yield put(getAssessmentDetails(userId));
    }
  } catch (error) {
    yield put(sendFileFailed());
    toast.error("Something went wrong, please try again.");
  }
}

function* setPreviousQuestionsHandler() {
  try {
    const { userId }: AuthenticationStateT = yield select(
      ({ AuthenticationState }: AppState) => AuthenticationState
    );
    yield axios.delete(`/muinmos/answer/${userId}`);
    yield put(setPreviousQuestionsDone());
    yield put(getQuestions(userId));
  } catch (error) {
    yield put(setPreviousQuestionsFailed());
    toast.error("Something went wrong, please try again.");
  }
}

export default function* watchQuestions() {
  yield all([
    takeLatest(GET_QUESTIONS, getQuestionsHandler),
    takeLatest(SEND_ANSWERS, sendAnswersHandler),
    takeLatest(SEND_FILE, sendFileHandler),
    takeLatest(SET_PREVIOUS_QUESTIONS, setPreviousQuestionsHandler),
  ]);
}
