import {
  getIPAdressDoneT,
  getIPAdressT,
  GET_IP_ADDRESS,
  GET_IP_ADDRESS_DONE,
} from "../reducers/IPAddressState";

export const GetIPAdress = (): getIPAdressT => ({
  type: GET_IP_ADDRESS,
});

export const GetIPAdressDone = (ip: string): getIPAdressDoneT => ({
  type: GET_IP_ADDRESS_DONE,
  ip,
});
