export const CREATE_ASSESSMENT = "CREATE_ASSESSMENT";
export const CREATE_ASSESSMENT_DONE = "CREATE_ASSESSMENT_DONE";
export const CREATE_ASSESSMENT_FAILED = "CREATE_ASSESSMENT_FAILED";
export const GET_ASSESSMENT_DETAILS = "GET_ASSESSMENT_DETAILS";
export const GET_ASSESSMENT_DETAILS_DONE = "GET_ASSESSMENT_DETAILS_DONE";
export const GET_ASSESSMENT_DETAILS_FAILED = "GET_ASSESSMENT_DETAILS_FAILED";
export const SEND_ASSESSMENT_COMPLETED = "SEND_ASSESSMENT_COMPLETED";
export const SEND_ASSESSMENT_COMPLETED_DONE = "SEND_ASSESSMENT_COMPLETED_DONE";
export const SEND_ASSESSMENT_COMPLETED_FAILED = "SEND_ASSESSMENT_COMPLETED_FAILED";
export const ACCEPT_ASSESSMENT_WARNING = "ACCEPT_ASSESSMENT_WARNING";
export const ACCEPT_ASSESSMENT_WARNING_DONE = "ACCEPT_ASSESSMENT_WARNING_DONE";
export const ACCEPT_ASSESSMENT_WARNING_FAILED = "ACCEPT_ASSESSMENT_WARNING_FAILED";

export type CreateAssessmentT = {
  type: typeof CREATE_ASSESSMENT;
}

export type CreateAssessmentDoneT = {
  type: typeof CREATE_ASSESSMENT_DONE;
  payload: string
}

export type CreateAssessmentFailedT = {
  type: typeof CREATE_ASSESSMENT_FAILED;
}

export type GetAssessmentDetailsT = {
  type: typeof GET_ASSESSMENT_DETAILS,
  userId: string | null
}

export type GetAssessmentDetailsDoneT = {
  type: typeof GET_ASSESSMENT_DETAILS_DONE,
  payload: any
}

export type GetAssessmentDetailsFailedT = {
  type: typeof GET_ASSESSMENT_DETAILS_FAILED,
}

export type SendAssessmentCompletedT = {
  type: typeof SEND_ASSESSMENT_COMPLETED,
  payload: string
}

export type SendAssessmentCompletedDoneT = {
  type: typeof SEND_ASSESSMENT_COMPLETED_DONE,
}

export type SendAssessmentCompletedFailedT = {
  type: typeof SEND_ASSESSMENT_COMPLETED_FAILED,
}

export type AcceptAssessmentWarningT = {
  type: typeof ACCEPT_ASSESSMENT_WARNING,
  payload: string
}

export type AcceptAssessmentWarningDoneT = {
  type: typeof ACCEPT_ASSESSMENT_WARNING_DONE
}

export type AcceptAssessmentWarningFailedT = {
  type: typeof ACCEPT_ASSESSMENT_WARNING_FAILED,
}

export type AssessmentStateActionT =
  | CreateAssessmentT | CreateAssessmentDoneT | CreateAssessmentFailedT | GetAssessmentDetailsT | GetAssessmentDetailsDoneT | GetAssessmentDetailsFailedT | AcceptAssessmentWarningT | AcceptAssessmentWarningDoneT | AcceptAssessmentWarningFailedT

export type AssessmentStateT = {
  assessmentId: string | null;
  loading: boolean;
  details: any
};