import React, { useEffect, useRef, useState } from "react";
import Panel from "../../Panel/Panel";
import styles from "./UploadArea.module.scss";
import cn from "classnames";
import { FileDrop } from "react-file-drop";
import { ReactComponent as IconImage } from "../../../assets/icons/image.svg";
import UploadAreaThumbnail from "../UploadAreaThumbnail/UploadAreaThumbnail";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import FormUploadError from "../../Form/FormUploadError/FormUploadError";

const UploadArea = (props: IUploadArea) => {
  const { field, form, title } = props;

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["md"]}px)`,
  });

  const [file, setFile] = useState<IFile | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!form.values[field.name]) {
      // @ts-ignore
      fileInputRef.current.value = "";
    }
  }, [field.name, form.values]);

  const onFileInputChange = (f: FileList | null) => {
    if (f && f.length > 0) {
      const file = f[0];
      setFile({
        blob: window.URL.createObjectURL(file),
        name: file.name,
        type: file.type,
      });
      form.setFieldValue(field.name, file);
    }
  };

  const onFileDelete = () => {
    setFile(null);
    form.setFieldValue(field.name, null);
  };

  return (
    <>
      <div className={cn(styles.title, "mb-large", styles["isGray"])}>
        <span>{title}</span>
      </div>
      <Panel className={cn(styles.element, file && "pb-0")}>
        <input
          onChange={(e) => onFileInputChange(e.currentTarget.files)}
          ref={fileInputRef}
          type="file"
          className={styles.input}
          accept=".png, .jpeg, .jpg, .tiff, .bmp, .pdf"
        />
        <FileDrop
          className={cn(styles.area, file && "mb-medium")}
          onTargetClick={() =>
            fileInputRef.current && fileInputRef.current.click()
          }
          onDrop={(files) => onFileInputChange(files)}
        >
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <IconImage className={styles.icon} />
            {isResponsiveView ? (
              <span className={styles.label}>
                Drop your image here, or{" "}
                <span className={styles.blue}>browse</span>
              </span>
            ) : (
              <span className={styles.label}>Upload your image here</span>
            )}
          </div>
        </FileDrop>
        {form.errors[field.name] &&
          form.errors[field.name] !== "This field is required" && (
            <FormUploadError errors={form.errors} inputName={field.name} />
          )}
        {file && form.values[field.name] && !form.errors[field.name] && (
          <div className="d-flex flex-wrap">
            <UploadAreaThumbnail
              file={file}
              onFileDelete={() => onFileDelete()}
            />
          </div>
        )}
      </Panel>
    </>
  );
};
export default UploadArea;

export interface IUploadArea {
  field: any;
  form: any;
  name: string;
  title: string;
}

export interface IFile {
  blob: string;
  name: string;
  type: string;
}
