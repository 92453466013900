import React, { useEffect } from "react";
import { IModal } from "./Modal.interface";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";
import Container from "../Bootstrap/Container";

const Modal = (props: IModal) => {
  const { children } = props;

  useEffect(() => {
    let isMounted = true;
    isMounted && (document.body.style.overflow = "hidden");
    return () => {
      document.body.style.overflow = "unset";
      isMounted = false;
    };
  }, []);

  const ModalTemplate = (
    <div className={styles.element}>
      <Container limited>
        <div className={styles.dialog}>{children}</div>
      </Container>
    </div>
  );

  return createPortal(ModalTemplate, document.body);
};

export default Modal;
