import {all, put, takeLatest} from "redux-saga/effects";
import axios from "../axios";
import {setIBrokerT, SET_IBROKER} from "../reducers/IBrokerState";
import {SetIBrokerDone} from "../actions/SetIBroker"
import {toast} from "react-toastify";
import {GetIPAdress} from "../actions/GetIPAddress";

function* setIBrokerHandler({id}: setIBrokerT) {
  try {
    const {data} = yield axios.get(`/ibData/${id}`);
    yield put(SetIBrokerDone(data));
    yield put(GetIPAdress());
  } catch (error) {
    toast.error("Referring Agent data are not available. Please contact your Agent");
  }
}

export default function* watchIBroker() {
  yield all([
    takeLatest(SET_IBROKER, setIBrokerHandler)
  ]);
}
