import { Field, FormikErrors, FormikTouched } from "formik";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInputError from "../../../../../components/Form/FormInputError/FormInputError";
import FormRadio from "../../../../../components/Form/FormRadio/FormRadio";
import FormRadioGroup from "../../../../../components/Form/FormRadioGroup/FormRadioGroup";
import FormRadioList from "../../../../../components/Form/FormRadioList/FormRadioList";
import {
  PossibleResponseT,
  QuestionT,
} from "../../../../../store/types/Questions.type";

const RadioField = (props: IRadioField) => {
  const { question, errors, touched, setFieldValue, values, form } = props;

  if (form && question.possibleResponses.length === 2) {
    return (
      <div className="row">
        <div className="col-12 col-md-7">
          <FormRadioGroup label={question.questionText}>
            {question.possibleResponses.map(
              (responseItem: PossibleResponseT, key: number) => {
                return (
                  <Field
                    name={question.questionId.toString()}
                    component={FormRadio}
                    value={responseItem.responseKey}
                    label={responseItem.responseText}
                    key={key}
                  />
                );
              }
            )}
            {touched[question.questionId.toString()] &&
              errors[question.questionId.toString()] && (
                <FormInputError
                  className={"mt-0"}
                  errors={errors}
                  inputName={question.questionId.toString()}
                />
              )}
          </FormRadioGroup>
        </div>
      </div>
    );
  } else {
    return (
      <FormGroup
        label={question.questionText}
        className={"mb-large-last-0 pb-large-last-0"}
        bold
        hLine
      >
        {question.possibleResponses.map(
          (responseItem: PossibleResponseT, key: number) => {
            return (
              <FormRadioList
                bigger
                label={responseItem.responseText}
                setFieldValue={setFieldValue}
                name={question.questionId.toString()}
                value={responseItem.responseKey}
                values={values}
                key={key}
              />
            );
          }
        )}
        {touched[question.questionId.toString()] &&
          errors[question.questionId.toString()] && (
            <FormInputError
              className={"mt-medium"}
              errors={errors}
              inputName={question.questionId.toString()}
            />
          )}
      </FormGroup>
    );
  }
};

export default RadioField;

interface IRadioField {
  question: QuestionT;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  setFieldValue: {
    (field: string, value: string): void;
  };
  values: any;
  form: boolean;
}
