import { Dispatch, SetStateAction } from "react";
import { Field } from "formik";
import FormInputError from "../../components/Form/FormInputError/FormInputError";
import FormRadio from "../../components/Form/FormRadio/FormRadio";
import FormRadioGroup from "../../components/Form/FormRadioGroup/FormRadioGroup";
import { GridElem } from "../../components/Grid/Grid";

export const getQuestionBasedOnAccountType = (
  accountType: string,
  setFieldValue: {
    (field: string, value: string): void;
  },
  touched: any,
  errors: any,
  setModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  switch (accountType) {
    case "Subscription":
      return (
        <>
          <GridElem className="d-flex align-items-center" size={2}>
            <b>Will this account follow a signal system?</b>
          </GridElem>
          <GridElem className="d-flex flex-column" size={2}>
            <FormRadioGroup className="justify-content-lg-end">
              <Field
                name="signalSystem"
                component={FormRadio}
                value="yes"
                label="Yes"
                handleChange={() => {
                  setModalOpen(true);
                  setFieldValue("signalSystem", "yes");
                }}
              />
              <Field
                name="signalSystem"
                component={FormRadio}
                value="no"
                label="No"
              />
            </FormRadioGroup>
            {touched["signalSystem"] && errors["signalSystem"] && (
              <FormInputError
                className="ml-lg-auto"
                errors={errors}
                inputName="signalSystem"
              />
            )}
          </GridElem>
        </>
      );
    case "Self":
      return null;
    default:
      return (
        <>
          <GridElem className="d-flex align-items-center" size={2}>
            <b>Will this account be traded by someone other than yourself?</b>
          </GridElem>
          <GridElem className="d-flex flex-column" size={2}>
            <FormRadioGroup className="justify-content-lg-end">
              <Field
                name="otherTrader"
                component={FormRadio}
                value="yes"
                label="Yes"
                handleChange={() => {
                  setModalOpen(true);
                  setFieldValue("otherTrader", "yes");
                }}
              />
              <Field
                name="otherTrader"
                component={FormRadio}
                value="no"
                label="No"
              />
            </FormRadioGroup>
            {touched["otherTrader"] && errors["otherTrader"] && (
              <FormInputError
                className="ml-lg-auto"
                errors={errors}
                inputName="otherTrader"
              />
            )}
          </GridElem>
        </>
      );
  }
};
