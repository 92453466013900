import React, { useEffect, useState } from "react";
import styles from "./FormRadio.module.scss";
import cn from "classnames";

const FormRadio = (props: IFormRadio) => {
  const { label, field, form, value, handleChange, disabled } = props;

  const [active, setActive] = useState(form.values[field.name] === value);

  const thisHandleChange = (e: any) => {
    if (handleChange) {
      handleChange(e);
    }
    form.setFieldValue(field.name, e.target.value);
  };

  useEffect(() => {
    setActive(form.values[field.name] === value);
  }, [form.values, field.name, value]);

  return (
    <label
      className={cn(
        styles.element,
        active && styles["isActive"],
        disabled && styles["isDisabled"]
      )}
    >
      <input
        className={styles.input}
        type="radio"
        checked={active}
        value={value}
        name={field.name}
        onChange={(e) => thisHandleChange(e)}
        disabled={disabled}
      />
      <span className={styles.round} />
      <span>{label}</span>
    </label>
  );
};
export default FormRadio;

interface IFormRadio {
  label: string;
  field: any;
  form: any;
  value: string;
  handleChange?: (e: any) => void;
  disabled?: boolean;
}
