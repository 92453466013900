import { Field, FormikErrors, FormikTouched } from "formik";
import FormDate from "../../../../../components/Form/FormDate/FormDate";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInputError from "../../../../../components/Form/FormInputError/FormInputError";
import { QuestionT } from "../../../../../store/types/Questions.type";

const DateField = (props: IDateField) => {
  const { question, errors, touched } = props;
  return (
    <div className="row">
      <div className="col-12 col-md-7">
        <FormGroup className="mb-md-large" label={question.questionText}>
          <Field name={question.questionId.toString()} component={FormDate} />
          {touched[question.questionId.toString()] &&
            errors[question.questionId.toString()] && (
              <FormInputError
                errors={errors}
                inputName={question.questionId.toString()}
              />
            )}
        </FormGroup>
      </div>
    </div>
  );
};

export default DateField;

interface IDateField {
  question: QuestionT;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}
