import styles from "./FormInput.module.scss";
import cn from "classnames";
import FormInputError from "../FormInputError/FormInputError";
import { useState } from "react";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-closed-icon.svg";
import { ReactComponent as EyeClosedIcon } from "../../../assets/icons/eye-icon.svg";

const FormInput = (props: IFormInput) => {
  const {
    type = "text",
    placeholder,
    field,
    step = "1",
    disabled,
    required,
    form,
    className,
    width,
    dark,
    bgGray,
    noDigits,
    onlyDigits,
    limit = 0,
    isRight,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  function toggleShowPassword() {
    setShowPassword((showPassword) => !showPassword);
  }

  if (type === "number") {
    return (
      <>
        <input
          className={cn(styles.element, className, dark && styles["isDark"])}
          type="number"
          min={step === "0.01" ? 0.0 : 0}
          max={step === "0.01" ? 9999999.99 : 9999999}
          pattern={
            step === "0.01"
              ? "(^[0-9]{0,2}$)|(^[0-9]{0,2}[0-9]{0,5}$)"
              : "[a-zA-Z]*"
          }
          step={step}
          {...field}
          placeholder={placeholder}
          required={required}
        />
        {form.touched[field.name] && form.errors[field.name] && (
          <FormInputError errors={form.errors} inputName={field.name} />
        )}
      </>
    );
  }

  if (type === "textarea") {
    return (
      <>
        <textarea
          className={cn(
            styles.element,
            styles[`is-textarea`],
            className,
            width === "limited" && styles["hasLimitedWidth"],
            dark && styles["isDark"]
          )}
          {...field}
          placeholder={placeholder}
          required={required}
        />
        {form.touched[field.name] && form.errors[field.name] && (
          <FormInputError
            // values={form.values}
            errors={form.errors}
            inputName={field.name}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        <input
          className={cn(
            styles.element,
            styles[`is-${type}`],
            className,
            dark && styles["isDark"],
            bgGray && styles["hasBgGray"],
            disabled && styles["isDisabled"]
          )}
          type={type === "password" && showPassword ? "text" : type}
          {...field}
          placeholder={placeholder}
          onChange={(e) => {
            let value = e.currentTarget.value;
            if (noDigits) {
              value = e.currentTarget.value.replace(/[0-9]/g, "");
            }
            if (onlyDigits) {
              if (limit > 0) {
                if (value.length <= limit) {
                  value = e.currentTarget.value.replace(/[^\d]+/g, "");
                } else {
                  return null;
                }
              }
              value = e.currentTarget.value.replace(/[^\d]+/g, "");
            }

            form.setFieldValue(field.name, value);
          }}
          required={required}
          disabled={disabled}
        />
        <span
          className={cn(styles.passwordToggleIcon, {
            [styles.visible]: type === "password",
          })}
          onClick={toggleShowPassword}
        >
          {showPassword ? <EyeIcon /> : <EyeClosedIcon />}
        </span>
      </div>
      {form.touched[field.name] && form.errors[field.name] && (
        <FormInputError
          // values={form.values}
          errors={form.errors}
          inputName={field.name}
          isRight={isRight}
        />
      )}
    </>
  );
};

export default FormInput;

interface IFormInput {
  type?: "text" | "email" | "tel" | "number" | "textarea" | "password";
  placeholder?: string;
  field: any;
  form: any;
  step?: "0.01" | "1";
  disabled?: boolean;
  required?: boolean;
  className?: string;
  width?: "limited";
  dark?: boolean;
  bgGray?: boolean;
  noDigits?: boolean;
  limit?: number;
  onlyDigits?: boolean;
  isRight?: boolean;
}
