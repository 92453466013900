import { all } from "redux-saga/effects";
import watchAuthentication from "./authentication.saga";
import watchQuestions from "./questions.saga";
import watchAssessment from "./assessment.saga";
import watchIBroker from "./iBroker.saga";
import watchIPAddress from "./IPAddress";

export default function* rootSaga () {
  yield all( [watchAuthentication(), watchQuestions(), watchAssessment(), watchIBroker(), watchIPAddress()] );
}
