import { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Heading from "../../../../components/Heading/Heading";
import Button from "../../../../components/Button/Button";
import Grid, { GridElem } from "../../../../components/Grid/Grid";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import FormInput from "../../../../components/Form/FormInput/FormInput";
import FormSelect from "../../../../components/Form/FormSelect/FormSelect";
import { Countries } from "../../../InitialForm/Countries";
import FormRadioGroup from "../../../../components/Form/FormRadioGroup/FormRadioGroup";
import FormRadio from "../../../../components/Form/FormRadio/FormRadio";
import FormInputError from "../../../../components/Form/FormInputError/FormInputError";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import ModalNotice from "../../../../components/Modal/ModalNotice/ModalNotice";
import Notice from "../../../../components/Notice/Notice";
import { useDispatch, useSelector } from "react-redux";
import { SetData } from "../../../../store/actions/SetData";
import FormInputPhone from "../../../../components/Form/FormInput/FormInputPhone";
import getPhoneCode from "../../../../functions/getPhoneCode";
import { AppState } from "../../../../store/reducers";
import { getQuestionBasedOnAccountType } from "../../getQuestionBasedOnAccountType";
import { specialCharactersError } from "../../../../constans";

const AuthorisedRepresentative = (props: IAuthorisedRepresentative) => {
  const { setActiveStep, formData } = props;
  const { initial } = useSelector((state: AppState) => state.Data);
  const IBrokerState = useSelector((state: AppState) => state.IBrokerState);
  const [introducingBroker, setIntroducingBroker] = useState(
    IBrokerState?.managerName ||
      formData?.authorised_representative?.referredBroker
  );
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const phoneCode = getPhoneCode(formData.initial.country_of_residence.value);

  return (
    <>
      <Formik
        initialValues={{
          entityName: formData?.authorised_representative?.entityName || "",
          registeredNumber:
            formData?.authorised_representative?.registeredNumber || "",
          leiNumber: formData?.authorised_representative?.leiNumber || "",
          buildingNumber:
            formData?.authorised_representative?.buildingNumber || "",
          street: formData?.authorised_representative?.street || "",
          city: formData?.authorised_representative?.city || "",
          country:
            (initial?.country_of_residence as (typeof Countries)[number]) || "",
          postCode: formData?.authorised_representative?.postCode || "",
          firstName: formData?.authorised_representative?.firstName || "",
          surname: formData?.authorised_representative?.surname || "",
          emailAddress: formData?.authorised_representative?.emailAddress || "",
          confirmEmailAddress:
            formData?.authorised_representative?.confirmEmailAddress || "",
          phoneNumber: formData?.authorised_representative?.phoneNumber || "",
          position: formData?.authorised_representative?.position || "",
          financialInstitution:
            formData?.authorised_representative?.financialInstitution || "",
          introducingBroker: IBrokerState?.managerName
            ? "yes"
            : formData?.authorised_representative?.introducingBroker || "",
          referredBroker: IBrokerState?.managerName
            ? IBrokerState.managerName
            : formData?.authorised_representative?.referredBroker || "",
          otherTrader: formData?.authorised_representative?.otherTrader || "",
          signalSystem: formData?.authorised_representative?.signalSystem || "",
        }}
        onSubmit={(values) => {
          dispatch(
            SetData({
              ...formData,
              authorised_representative: {
                ...values,
                registeredCountry: values?.country,
              },
            })
          );
          setActiveStep();
        }}
        validationSchema={Yup.object().shape({
          entityName: Yup.string()
            .required("Entity Name is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),

          registeredNumber: Yup.string()
            .required("Registered Number is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          buildingNumber: Yup.string()
            .required("Building Number is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          street: Yup.string()
            .required("Street is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          city: Yup.string()
            .required("City is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          country: Yup.object().required("Country is required"),
          postCode: Yup.string()
            .required("Post Code is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          firstName: Yup.string()
            .required("First Name is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          surname: Yup.string()
            .required("Surname is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          emailAddress: Yup.string().required("Email Address is required"),
          confirmEmailAddress: Yup.string()
            .required("Email Address confirmation is required")
            .oneOf(
              [Yup.ref("emailAddress"), null],
              "Email Addresses must match"
            ),
          phoneNumber: Yup.string().required("Phone number is required"),
          position: Yup.string()
            .required("Position is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          financialInstitution: Yup.string()
            .required("This field is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          introducingBroker: Yup.string()
            .required("This field is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          referredBroker: Yup.string().when("introducingBroker", {
            is: "yes",
            then: Yup.string().required(
              "Introducing Referring Agent is required"
            ),
          }),
          otherTrader:
            IBrokerState?.accountType !== "Subscription" &&
            IBrokerState?.accountType !== "Self"
              ? Yup.string().required("This field is required")
              : Yup.string().notRequired(),
          signalSystem:
            IBrokerState?.accountType === "Subscription"
              ? Yup.string().required("This field is required")
              : Yup.string().notRequired(),
        })}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <Form>
            <Heading
              title="Applicant Name"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <Grid className="mb-xlarge" columns={6} gap={2.4}>
              <GridElem size={3}>
                <FormGroup label="Entity Name">
                  <Field name="entityName" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Registered Number">
                  <Field name="registeredNumber" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Building Number">
                  <Field name="buildingNumber" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Street">
                  <Field name="street" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="City/Town">
                  <Field name="city" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Post Code">
                  <Field name="postCode" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Country">
                  <Field
                    disabled={!!initial?.country_of_residence}
                    name="country"
                    component={FormSelect}
                    options={Countries}
                    placeholder="Choose country"
                  />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="LEI Number (if available)">
                  <Field name="leiNumber" component={FormInput} />
                </FormGroup>
              </GridElem>
            </Grid>
            <Heading
              title="Authorised Representative"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <Grid columns={6} gap={2.4}>
              <GridElem size={3}>
                <FormGroup label="First Name">
                  <Field name="firstName" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Surname">
                  <Field name="surname" component={FormInput} />
                </FormGroup>
              </GridElem>
            </Grid>
            <hr />
            <Grid columns={6} gap={2.4}>
              <GridElem size={3}>
                <FormGroup label="Email Address">
                  <Field name="emailAddress" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Confirm Email Address">
                  <Field name="confirmEmailAddress" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Phone number">
                  <Field
                    name="phoneNumber"
                    component={FormInputPhone}
                    onlyDigits
                    phoneCode={phoneCode}
                  />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Position">
                  <Field name="position" component={FormInput} />
                </FormGroup>
              </GridElem>
            </Grid>
            <hr />
            <Grid className="mb-medium" gap={2.4} rowGrap={1.6}>
              <GridElem className="d-flex align-items-center" size={2}>
                <b>Is the Application a “financial institution”?</b>
              </GridElem>
              <GridElem className="d-flex flex-column" size={2}>
                <FormRadioGroup className="justify-content-lg-end">
                  <Field
                    name="financialInstitution"
                    component={FormRadio}
                    value="yes"
                    label="Yes"
                  />
                  <Field
                    name="financialInstitution"
                    component={FormRadio}
                    value="no"
                    label="No"
                  />
                </FormRadioGroup>
                {touched["financialInstitution"] &&
                  errors["financialInstitution"] && (
                    <FormInputError
                      className="ml-lg-auto"
                      errors={errors}
                      inputName="financialInstitution"
                    />
                  )}
              </GridElem>
            </Grid>
            <Paragraph className="mb-medium">
              The form “financial institution” applies to, but is not limited
              to, a bank, credit or thrift institution, broker dealer,
              investment company, currency exchange, mortgage broker, insurance
              company, futures commission merchant, commodity pool operator,
              mutual fund, licensed sender of money or any other person who
              engages as a business in the transmission of funds or the
              provision if financial services.
            </Paragraph>
            <Grid gap={2.4} rowGrap={1.6}>
              <GridElem className="d-flex align-items-center" size={2}>
                <b>Were you referred by a Referring Agent?</b>
              </GridElem>
              <GridElem className="d-flex flex-column" size={2}>
                <FormRadioGroup className="justify-content-lg-end">
                  <Field
                    name="introducingBroker"
                    component={FormRadio}
                    value="yes"
                    label="Yes"
                    handleChange={() => {
                      setIntroducingBroker(true);
                    }}
                    disabled={!!IBrokerState?.managerName}
                  />
                  <Field
                    name="introducingBroker"
                    component={FormRadio}
                    value="no"
                    label="No"
                    handleChange={(e: any) => {
                      setIntroducingBroker(false);
                      setFieldValue("referredBroker", "");
                    }}
                    disabled={!!IBrokerState?.managerName}
                  />
                </FormRadioGroup>
                {touched["introducingBroker"] &&
                  errors["introducingBroker"] && (
                    <FormInputError
                      className="ml-lg-auto"
                      errors={errors}
                      inputName="introducingBroker"
                    />
                  )}
              </GridElem>
              {introducingBroker && (
                <>
                  <GridElem className="d-flex align-items-center" size={2}>
                    <b>Referring Agent Name</b>
                  </GridElem>
                  <GridElem size={2}>
                    <Field
                      name="referredBroker"
                      component={FormInput}
                      isRight
                      disabled={!!IBrokerState?.managerName}
                    />
                  </GridElem>
                </>
              )}
              {getQuestionBasedOnAccountType(
                IBrokerState?.accountType,
                setFieldValue,
                touched,
                errors,
                setModalOpen
              )}
            </Grid>
            <hr />
            <Notice>
              Please have Corporate Documents, including Proof of Identification
              and Proof of Residence for Directors/Shareholders, ready for
              upload to complete the application.
            </Notice>
            <div className="d-flex flex-row justify-content-center">
              <Button title="Next" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
      {modalOpen && <ModalNotice setModalOpen={setModalOpen} />}
    </>
  );
};

export default AuthorisedRepresentative;

interface IAuthorisedRepresentative {
  setActiveStep: () => void;
  formData?: any;
}
