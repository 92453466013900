const getOrdinalAbbr = (num: string) => {
  const lastNum = num.slice(-1);
  switch (lastNum) {
    case "1":
      return "st";
    case "2":
      return "nd";
    case "3":
      return "rd";
    default:
      return "th";
  }
};

export default getOrdinalAbbr;
