import axios from "../axios";

type SaveTokensT = {
  token: string;
  refreshToken: string;
};

export function saveTokens({ token, refreshToken }: SaveTokensT) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("refreshToken", refreshToken);
}

export function renewToken() {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("refreshToken")}`;
  return axios.post("/user/refresh");
}

export function removeUser() {
  axios.defaults.headers.common["Authorization"] = "";
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("refreshToken");
}
