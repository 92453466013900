import React from "react";
import styles from "./StepsNavigation.module.scss";
import Container from "../Bootstrap/Container";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import cn from "classnames";
import Stepper, { IStep } from "../Stepper/Stepper";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import StepperMobile from "../StepperMobile/StepperMobile";

const StepsNavigation = (props: IStepsNavigation) => {
  const { steps } = props;

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["lg"]}px)`,
  });

  return (
    <>
      <div className={styles.element}>
        <Container className={styles.container}>
          <div
            className={cn(
              styles.inner,
              "d-flex align-items-center flex-column flex-lg-row"
            )}
          >
            <div className={styles.logo}>
              <Logo />
            </div>
            {steps.length > 0 && (
              <div className={cn(styles.steps, "d-flex align-items-center")}>
                {isResponsiveView && <Stepper steps={steps} />}
              </div>
            )}
          </div>
        </Container>
      </div>
      {!isResponsiveView && steps.length > 0 && <StepperMobile steps={steps} />}
    </>
  );
};
export default StepsNavigation;

export interface IStepsNavigation {
  steps: Array<IStep>;
  type?: "createAccount";
}
