import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import store from ".";
import { logoutUser } from "./actions/Authentication";
import { renewToken, saveTokens } from "./helpers/tokenManagement";

const instance = axios.create();
instance.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
let isRefreshing = false;
let refreshSubscribers: Array<any> = [];

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    if (!error.response) {
      console.error(error);
      toast.error("Something went wrong, please try again.");
      return Promise.reject(error.message);
    }
    // const errorMessage = error.response?.data?.error || error.message;

    const originalRequest = config;

    if (status === 403) {
      store.dispatch(logoutUser());
    }

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        renewToken()
          .then(({ data: { token, refreshToken } }) => {
            isRefreshing = false;
            onRefreshed(token);
            saveTokens({ token, refreshToken });
          })
          .catch((error) =>
            toast.error("Something went wrong, please try again.")
          );
      }

      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((token: string) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    } else {
      console.error(error);
      return Promise.reject(error.response);
    }
  }
);

function subscribeTokenRefresh(cb: any) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token: string) {
  refreshSubscribers.map((cb) => cb(token));
}

export default instance;
