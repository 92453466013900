
export const SET_DATA = "SET_DATA";

export type setDataT = {
    type: "SET_DATA";
    data: {};
}

const initialState:any = {};

export default function DataState(state = initialState, action: setDataT) {
    if (action.type === SET_DATA) {
        return action.data;
    }
    return state;
}
