import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Heading from "../../../../components/Heading/Heading";
import BoxGrey from "../../../../components/BoxGrey/BoxGrey";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import MainList, {
  MainListItem,
} from "../../../../components/MainList/MainList";
import Grid, { GridElem } from "../../../../components/Grid/Grid";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import FormInput from "../../../../components/Form/FormInput/FormInput";
import Button from "../../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { SetData } from "../../../../store/actions/SetData";
import { specialCharactersError } from "../../../../constans";

const ApprovedTraders = (props: IApprovedTraders) => {
  const { setActiveStep, goToPrevious, formData } = props;

  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          name1: formData?.approved_traders
            ? formData.approved_traders[0]?.name
            : "",
          title1: formData?.approved_traders
            ? formData.approved_traders[0]?.title
            : "",
          name2: formData?.approved_traders
            ? formData?.approved_traders[1]?.name
            : "",
          title2: formData?.approved_traders
            ? formData?.approved_traders[1]?.title
            : "",
        }}
        onSubmit={(values) => {
          const data = [{ name: values.name1, title: values.title1 }];

          values.name2 &&
            values.title2 &&
            data.push({ name: values.name2, title: values.title2 });

          dispatch(SetData({ ...formData, approved_traders: data }));
          setActiveStep();
        }}
        validationSchema={Yup.object().shape({
          name1: Yup.string()
            .required("Name is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          title1: Yup.string()
            .required("Title is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
          name2: Yup.string().matches(
            /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
            specialCharactersError
          ),
          title2: Yup.string()
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            )
            .when("name2", {
              is: (value: string) => value && value.length > 0,
              then: Yup.string()
                .required("Title is required")
                .matches(
                  /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
                  specialCharactersError
                ),
            }),
        })}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <Form>
            <Heading
              title="Approved Traders"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <BoxGrey className="mb-xlarge">
              <Paragraph black className="mb-medium">
                The Applicant agrees that the individuals designated by the
                Applicant as “Approved Traders” from time to time (including the
                individuals listed below unless and until such designation is
                withdrawn) are authorised to trade (on margin or otherwise) with
                ATC Brokers Ltd (“ATC”) on the Applicant’s behalf and:
              </Paragraph>
              <MainList>
                <MainListItem>
                  to deposit with and withdraw from ATC in connection with
                  transactions and/or the sale or purchase of property;
                </MainListItem>
                <MainListItem>
                  to receive requests and demands for additional margin, notices
                  of intention to sell or purchase and other notices and demands
                  of whatever character from ATC;
                </MainListItem>
                <MainListItem>
                  to confirm the correctness of notices, confirmations,
                  requests, demands and confirmations of every kind;
                </MainListItem>
                <MainListItem>
                  to place oral orders with any authorised representative of ATC
                  for the execution of margined transactions on behalf of the
                  Applicant on any marketplace on which ATC is permitted to
                  effect transactions.
                </MainListItem>
                <MainListItem>
                  to pay to ATC all fees, commissions and mark ups or downs
                  incurred in connection with any such transactions and all
                  amounts as may be requested by ATC from time to time as margin
                  or equity;
                </MainListItem>
                <MainListItem>
                  to settle, compromise, adjust and give releases on behalf of
                  the Applicant with respect to any and all claims, disputes and
                  controversies; and
                </MainListItem>
                <MainListItem>
                  to take any other action relating to any of the foregoing
                  matter.
                </MainListItem>
              </MainList>
            </BoxGrey>
            <Grid columns={6} gap={2.4}>
              <GridElem size={3}>
                <FormGroup label="Name">
                  <Field name="name1" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Title">
                  <Field name="title1" component={FormInput} />
                </FormGroup>
              </GridElem>
            </Grid>
            <Grid columns={6} gap={2.4}>
              <GridElem size={3}>
                <FormGroup label="Name">
                  <Field name="name2" component={FormInput} />
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup label="Title">
                  <Field name="title2" component={FormInput} />
                </FormGroup>
              </GridElem>
            </Grid>
            <div className="d-flex flex-row justify-content-between">
              <Button
                title="Back"
                variant="border"
                handleClick={goToPrevious}
              />
              <Button title="Next" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default ApprovedTraders;

interface IApprovedTraders {
  setActiveStep: () => void;
  goToPrevious: () => void;
  formData?: any;
}
