import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import cn from "classnames";
import styles from "../../../CreateAccount.module.scss";
import Paragraph from "../../../../../components/Paragraph/Paragraph";
import FormAgreement from "../../../../../components/Form/FormAgreement/FormAgreement";
import { IISCSection } from "../IndividualSelfCertification";
import Button from "../../../../../components/Button/Button";
import * as Yup from "yup";
import ModalOmmissionOfTaxID from "../../../../../components/Modal/ModalOmmissionOfTaxID/ModalOmmissionOfTaxID";
import ModalProcessing from "../../../../../components/Modal/ModalProcessing/ModalProcessing";
import { SetApply } from "../../../../../store/actions/SetApply";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../../../store/actions/Authentication";
import { AppState } from "../../../../../store/reducers";
import { SetData } from "../../../../../store/actions/SetData";
import { createAssessment } from "../../../../../store/actions/Assessment";

const ISCSection4 = (props: IISCSection4) => {
  const { step, formData, goToPrevious } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenProcess, setModalOpenProcess] = useState(false);

  const { userId, error } = useSelector(
    (state: AppState) => state.AuthenticationState
  );

  const { assessmentId } = useSelector(
    (state: AppState) => state.AssessmentState
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(registerUser());
    setModalOpenProcess(true);
  };

  useEffect(() => {
    !!userId && dispatch(createAssessment());
  }, [userId, dispatch]);

  useEffect(() => {
    error && setModalOpen(false);
  }, [error]);

  useEffect(() => {
    if (!!assessmentId) {
      dispatch(SetApply(2));
      history.push("/online-application/start");
    }
  }, [assessmentId, dispatch, history]);

  return (
    <>
      <Formik
        initialValues={{
          agreement: formData?.isc_section4?.agreement || false,
        }}
        onSubmit={(values) => {
          dispatch(SetData({ ...formData, isc_section4: values }));
          if (formData.isc_section3?.notDiscloseTaxId) {
            setModalOpen(true);
          } else {
            handleSubmit();
          }
        }}
        validationSchema={Yup.object().shape({
          agreement: Yup.boolean().oneOf([true], "This field is required"),
        })}
      >
        {() => (
          <Form>
            <b className={cn(styles.subtitle, "d-inline-flex mb-medium")}>
              Section {step}: Declaration and Undertakings
            </b>
            <Paragraph>
              I declare that the information provided in this form is, to the
              best of my knowledge and belief, accurate and complete. I
              undertake to advise the recipient promptly and provide an updated
              Self-Certification form within 30 days where any change in
              circumstances occurs which causes any of the information contained
              in this form to be inaccurate or incomplete. Where legally obliged
              to do so, I hereby consent to the recipient sharing this
              information with the relevant tax information authorities.
            </Paragraph>
            <hr />
            <Field
              name="agreement"
              component={FormAgreement}
              label={`I, ${formData?.personal_information?.firstName} ${formData?.personal_information?.surname}, accept the above information is correct and agree to all of the previously stated terms.`}
            />
            <div className="d-flex flex-row justify-content-between">
              <Button
                title="Back"
                variant="border"
                handleClick={goToPrevious}
              />
              <Button
                title="Submit Application and FATCA Self-Certification"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
      {modalOpen && (
        <ModalOmmissionOfTaxID
          setSendForm={handleSubmit}
          setModalOpen={setModalOpen}
        />
      )}
      {modalOpenProcess && <ModalProcessing />}
    </>
  );
};
export default ISCSection4;

interface IISCSection4 extends IISCSection {
  step: 3 | 4;
}
