import React, { useEffect, useState } from "react";
import styles from "./SignIn.module.scss";
import { Formik, Form, Field } from "formik";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import * as Yup from "yup";
import FormAuthInput from "../../components/Form/FormAuthInput/FormAuthInput";
import { ReactComponent as IconUser } from "../../assets/icons/user.svg";
import { ReactComponent as IconLock } from "../../assets/icons/lock.svg";
import LayoutAuth from "../../components/LayoutAuth/LayoutAuth";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../store/actions/Authentication";
import { useHistory } from "react-router";
import { SetApply } from "../../store/actions/SetApply";
import { AppState } from "../../store/reducers";
import { SetWarning } from "../../store/actions/SetWarning";
import { SetDecline } from "../../store/actions/SetDecline";
import { checkAppropriateness } from "../../functions/checkAppropriateness";
import { sendAssessmentCompleted } from "../../store/actions/Assessment";

const SignInView = () => {
  const { userId, error } = useSelector(
    (state: AppState) => state.AuthenticationState
  );
  const StepStage = useSelector((state: AppState) => state.StepStage);
  const { questions } = useSelector((state: AppState) => state.QuestionsState);
  const { details } = useSelector((state: AppState) => state.AssessmentState);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const setAssessmentStep = (step: string) => {
      switch (step) {
        case "inProgress":
          if (questions.length) {
            dispatch(SetApply(3));
            history.push("/online-application");
          } else {
            const assessmentResult = checkAppropriateness(details.broker);
            if (assessmentResult) {
              dispatch(sendAssessmentCompleted("completedWithWarning"));
            } else {
              dispatch(sendAssessmentCompleted("completed"));
            }
            dispatch(SetWarning(assessmentResult));
            dispatch(SetApply(4));
            history.push("/online-application/end");
          }
          break;
        case "completed":
          dispatch(SetApply(4));
          history.push("/online-application/end");
          break;
        case "completedWithWarning":
          dispatch(SetWarning(true));
          dispatch(SetApply(4));
          history.push("/online-application/end");
          break;
        case "declined":
          dispatch(SetDecline(true));
          dispatch(SetApply(4));
          history.push("/online-application/end");
          break;
      }
    };
    !!userId && StepStage && setAssessmentStep(StepStage);
  }, [StepStage, dispatch, history, userId, questions, details]);

  useEffect(() => {
    error && setLoader(false);
  }, [error]);

  return (
    <LayoutAuth>
      <div className={styles.content}>
        <Formik
          initialValues={{
            user: "",
            password: "",
          }}
          onSubmit={(values) => {
            dispatch(
              loginUser({ login: values.user, password: values.password })
            );
            setLoader(true);
          }}
          validationSchema={Yup.object().shape({
            user: Yup.string().required("Account name required"),
            password: Yup.string().required("Password required"),
          })}
        >
          {({ isValid }) => (
            <Form>
              <Heading
                className="text-center mb-medium"
                size="h1"
                title="Login"
              />
              <Field
                name="user"
                component={FormAuthInput}
                icon={<IconUser />}
                placeholder="Enter your account"
              />
              <Field
                name="password"
                component={FormAuthInput}
                icon={<IconLock />}
                placeholder="Enter your password"
                type="password"
              />
              <div className="d-flex justify-content-center flex-column flex-lg-row">
                <Button title="Log in" type="submit" loading={loader} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </LayoutAuth>
  );
};

export default SignInView;
