import React, { ReactComponentElement } from "react";
import styles from "./FormAuthInput.module.scss";
import cn from "classnames";
// import { NavLink } from "react-router-dom";
import FormInputError from "../FormInputError/FormInputError";

const FormAuthInput = (props: IFormAuthInput) => {
  const {
    type = "text",
    placeholder,
    field,
    form,
    required,
    icon,
    className,
  } = props;

  return (
    <div className={className}>
      <div className={cn(styles.element, "d-flex align-items-center")}>
        {icon}
        <input
          type={type}
          placeholder={placeholder}
          {...field}
          required={required}
          className={styles.input}
        />
      </div>
      {form.errors[field.name] && (
        <FormInputError errors={form.errors} inputName={field.name} />
      )}
      {/* {type === "password" &&
			<div className="d-flex justify-content-end"><NavLink className="link" to="/auth/password-recovery">Forgot
				password?</NavLink></div>} */}
    </div>
  );
};
export default FormAuthInput;

interface IFormAuthInput {
  type?: "text" | "email" | "password";
  placeholder?: string;
  field: any;
  form: any;
  required?: boolean;
  icon?: ReactComponentElement<any>;
  className?: string;
}
