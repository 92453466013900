import { useEffect, useState } from "react";
import Panel from "../../../../components/Panel/Panel";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import {
  sendAnswers,
  sendFile,
  setPreviousQuestions,
} from "../../../../store/actions/Questions";
import Button from "../../../../components/Button/Button";
import { QuestionT } from "../../../../store/types/Questions.type";
import getAssessmentInitialValues from "./getAssessmentInitialValues";
import getAssessmentValidation from "./getAssessmentValidation";
import { getAssessmentQuestion } from "./getAssessmentQuestion";
import AssessmentQuestionsHeading from "./AssessmentQuestionsHeading/AssessmentQuestionsHeading";
import ModalConfirmation from "../../../../components/Modal/ModalConfirmation/ModalConfirmation";
import handleAnswers from "./handleAnswers";
import { checkAssessmentStep } from "../../Application.view";
import Paragraph from "../../../../components/Paragraph/Paragraph";

const AssessmentQuestions = () => {
  const { questions } = useSelector((state: AppState) => state.QuestionsState);
  const [questionsData, setQuestionsData] = useState<QuestionT[]>(questions);
  const [modalOpen, setModalOpen] = useState(false);
  const [textFieldsNum, setTextFIeldsNum] = useState<QuestionT[]>([]);
  const [activeStep, setActiveStep] = useState<number>(-1);
  const [showSaveAndClose, setShowSaveAndClose] = useState<boolean>(false);

  const dispatch = useDispatch();
  const QuestionsState = useSelector((state: AppState) => state.QuestionsState);
  const AccountState = useSelector((state: AppState) => state.AccountState);

  useEffect(() => {
    // to avoid reinitialization and uncontrolled inputs errors
    setQuestionsData([]);
    setTimeout(() => {
      setQuestionsData(questions);
    }, 0);
  }, [questions, dispatch]);

  const handlePreviousQuestion = () => {
    setModalOpen(false);
    dispatch(setPreviousQuestions());
  };

  useEffect(() => {
    const checkIfText = (questions: QuestionT[]) => {
      const fields = [
        "Text",
        "Optional",
        "Date",
        "OptionalDate",
        "Phone",
        "Email",
      ];
      const res = questions.filter((item: QuestionT) =>
        fields.includes(item.type)
      );
      return res;
    };
    setTextFIeldsNum(checkIfText(questionsData));
  }, [questionsData]);

  useEffect(() => {
    if (QuestionsState.questions.length) {
      const step = checkAssessmentStep(QuestionsState.questions);
      if (step >= 0) setActiveStep(step);
    }
  }, [QuestionsState.questions]);

  useEffect(() => {
    if (AccountState === "individual") {
      activeStep === 2 || activeStep === 3
        ? setShowSaveAndClose(true)
        : setShowSaveAndClose(false);
    } else {
      activeStep === 2 || activeStep === 3 || activeStep === 4
        ? setShowSaveAndClose(true)
        : setShowSaveAndClose(false);
    }
  }, [AccountState, activeStep]);

  if (questionsData.length)
    return (
      <>
        <AssessmentQuestionsHeading data={questionsData[0]} />
        <Formik
          initialValues={getAssessmentInitialValues(questionsData)}
          onSubmit={(values) => {
            const allAnswers = handleAnswers(values, questionsData).filter(
              (item) => item !== null && item !== undefined
            );

            const fieldsAnswers: any[] = [];

            allAnswers.forEach((answer) => {
              if (answer instanceof FormData) {
                // send files
                dispatch(sendFile(answer));
              } else {
                fieldsAnswers.push(answer);
              }
            });
            // send fields answers
            fieldsAnswers.length && dispatch(sendAnswers(fieldsAnswers));
          }}
          validationSchema={Yup.object().shape(
            getAssessmentValidation(questionsData)
          )}
        >
          {({ values, setFieldValue, touched, errors }) => (
            <Form>
              <Panel paddingBig>
                {questionsData.map((question: QuestionT, key: number) => {
                  return getAssessmentQuestion(
                    question,
                    values,
                    setFieldValue,
                    key,
                    touched,
                    errors,
                    textFieldsNum
                  );
                })}
              </Panel>

              {showSaveAndClose && (
                <div className={"d-flex flex-row"}>
                  <Paragraph black className="mt-large">
                    Don't have the document ready? No problem! Click on “Save
                    and Close” and visit us soon using the assessment link that
                    is emailed to you. Be mindful of emails going to your spam
                    folder.
                  </Paragraph>
                </div>
              )}

              <div className={"d-flex flex-row justify-content-between"}>
                <Button
                  title="Back"
                  variant="border"
                  handleClick={() => setModalOpen(true)}
                />
                {showSaveAndClose && (
                  <Button
                    title="Save and Close"
                    handleClick={() => {
                      window.location.href =
                        "https://atcbrokers.com/en/thank-you/";
                    }}
                  />
                )}
                <Button title="Next" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
        {modalOpen && (
          <ModalConfirmation
            handleClick={handlePreviousQuestion}
            setModalOpen={setModalOpen}
          />
        )}
      </>
    );
  else return null;
};

export default AssessmentQuestions;
