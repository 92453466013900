import axios from "../axios";

export const checkEmailAvailability = (value: string | undefined) => {
  if (!value) {
    return false;
  }
  return axios
    .post("/user/emailAvailability", {
      email: value,
    })
    .then(({ data }) => {
      return data.emailAvailable;
    })
    .catch(console.error);
};
