import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Heading from "../../../../components/Heading/Heading";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import FormAgreement from "../../../../components/Form/FormAgreement/FormAgreement";
import Button from "../../../../components/Button/Button";
import ModalProcessing from "../../../../components/Modal/ModalProcessing/ModalProcessing";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetApply } from "../../../../store/actions/SetApply";
import { getName } from "../../../../functions/getName";
import { SetData } from "../../../../store/actions/SetData";
import { registerUser } from "../../../../store/actions/Authentication";
import { AppState } from "../../../../store/reducers";
import { createAssessment } from "../../../../store/actions/Assessment";

const Declaration = ({
  formData,
  goToPrevious,
  setActiveStep,
}: IDeclaration) => {
  const { userId, error } = useSelector(
    (state: AppState) => state.AuthenticationState
  );
  const { assessmentId } = useSelector(
    (state: AppState) => state.AssessmentState
  );
  const DivisionState = useSelector((state: AppState) => state.DivisionState);
  const AccountState = useSelector((state: AppState) => state.AccountState);
  const [modalOpen, setModalOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    !!userId && dispatch(createAssessment());
  }, [userId, dispatch]);

  useEffect(() => {
    error && setModalOpen(false);
  }, [error]);

  useEffect(() => {
    if (!!assessmentId) {
      dispatch(SetApply(2));
      history.push("/online-application/start");
    }
  }, [assessmentId, dispatch, history]);

  return (
    <>
      <Formik
        initialValues={{
          agreement: formData?.declaration?.agreement || false,
        }}
        onSubmit={(values) => {
          dispatch(SetData({ ...formData, declaration: values }));
          if (AccountState === "corporate" || DivisionState === "uk") {
            dispatch(registerUser());
            setModalOpen(true);
          }
          setActiveStep && setActiveStep();
        }}
        validationSchema={Yup.object().shape({
          agreement: Yup.boolean().oneOf([true], "This field is required"),
        })}
      >
        {({ values, submitForm }) => (
          <Form>
            <Heading
              title="Declaration"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <Paragraph className="mb-medium">
              By continuing the application and accepting this declaration, I
              acknowledge that I am electronically signing your Customer
              Agreement and related documents which is a legally contractual
              agreement. I confirm I understand the full nature and risks of
              trading in the Foreign Exchange, Contract for Difference and other
              derivatives.
            </Paragraph>
            <Paragraph className="mb-medium">
              I confirm that I have read and understood the current{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                  DivisionState === "uk"
                    ? "https://atcbrokers.com/en/uk-terms-of-business.pdf"
                    : "https://atcbrokers.com/en/ky-terms-of-business.pdf"
                }
              >
                Terms of Business
              </a>
              ,{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                  DivisionState === "uk"
                    ? "https://atcbrokers.com/en/uk-order-execution-policy.pdf"
                    : "https://atcbrokers.com/en/ky-order-execution-policy.pdf"
                }
              >
                Order Execution Policy
              </a>
              ,{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                  DivisionState === "uk"
                    ? "https://atcbrokers.com/en/uk-conflict-of-interest.pdf"
                    : "https://atcbrokers.com/en/ky-conflict-of-interest-policy.pdf"
                }
              >
                Conflicts of Interests Policy
              </a>{" "}
              and{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                  DivisionState === "uk"
                    ? "https://atcbrokers.com/en/uk-risk-disclosure-notice.pdf"
                    : "https://atcbrokers.com/en/ky-risk-disclosure-notice.pdf"
                }
              >
                Risk Disclosure Notice
              </a>{" "}
              set out by ATC Brokers Limited and by submitting this application,
              I agree be bound by the terms of these documents. If referred by a
              Referring Agent, I also agree to be bound by the term of the{" "}
              <a
                className="link"
                target="_blank"
                rel="noreferrer"
                href={
                  DivisionState === "uk"
                  ? "https://atcbrokers.com/en/uk-referring-agent-disclosure.pdf"
                  : "https://atcbrokers.com/en/ky-referring-agent-disclosure.pdf"
                }
              >
                Referring Agent Disclosure
              </a>
              .
            </Paragraph>
            <Paragraph className="mb-medium">
              I have access to the Internet and consent to ATC Brokers Limited
              providing me with information about changes to the Customer
              Agreement, Terms of Business, Order Execution Policy, Conflict of
              Interest Policy, and Risk Disclosure Notice by maintaining the
              most up to date versions available on the company website{" "}
              {DivisionState === "uk" ? (
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://atcbrokers.co.uk/"
                >
                  atcbrokers.co.uk
                </a>
              ) : (
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://atcbrokers.com/"
                >
                  atcbrokers.com
                </a>
              )}
              .
            </Paragraph>
            <Paragraph>
              I confirm that I am 18 years or over and that the information
              provided by me in this account opening process is true and correct
              and that I will notify ATC Brokers Limited in writing of any
              material changes. I further confirm that I do not breach any
              regulations of my country of residence in trading with ATC Brokers
              Limited.
            </Paragraph>
            <hr />
            <Field
              name="agreement"
              component={FormAgreement}
              label={`I, ${getName(
                formData
              )}, acknowledge having received, read, and understood the foregoing declaration.`}
            />
            <div className="d-flex flex-row justify-content-between">
              <Button
                title="Back"
                variant="border"
                handleClick={goToPrevious}
              />
              <Button title="Next" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
      {modalOpen && <ModalProcessing />}
    </>
  );
};
export default Declaration;

interface IDeclaration {
  goToPrevious: () => void;
  formData?: any;
  setActiveStep?: () => void;
}
