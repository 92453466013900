import React from "react";
import styles from "./ListItem.module.scss";
import cn from "classnames"

const ListItem = ({children, bigger, dark}:IListItem) => {


    return(
        <li className={cn(styles.element, bigger && styles['isBigger'], dark && styles['isDark'])}>{children}</li>
    )
}
export default ListItem;

export interface IListItem {
    children?: any;
    bigger?: boolean;
    dark?: boolean;
}
