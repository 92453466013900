import { IISCSection } from "../IndividualSelfCertification";
import cn from "classnames";
import styles from "../../../CreateAccount.module.scss";
import Panel from "../../../../../components/Panel/Panel";
import Grid, { GridElem } from "../../../../../components/Grid/Grid";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import { Formik, Form, Field } from "formik";
import FormInput from "../../../../../components/Form/FormInput/FormInput";
import FormAgreement from "../../../../../components/Form/FormAgreement/FormAgreement";
import Button from "../../../../../components/Button/Button";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { SetData } from "../../../../../store/actions/SetData";
import FormSelect from "../../../../../components/Form/FormSelect/FormSelect";
import { Countries } from "../../../../InitialForm/Countries";
import { specialCharactersError } from "../../../../../constans";

const ISCSection1 = ({ goToPrevious, setSection, formData }: IISCSection1) => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        mailingAddressDiff: formData?.isc_section1?.mailingAddressDiff || false,
        residentialAddress: formData?.isc_section1?.residentialAddress || "",
        countryOfBirth: formData?.isc_section1?.countryOfBirth || "",
        city: formData?.isc_section1?.city || "",
        country: formData?.isc_section1?.country || "",
        postCode: formData?.isc_section1?.postCode || "",
      }}
      onSubmit={(values) => {
        dispatch(SetData({ ...formData, isc_section1: values }));
        setSection();
      }}
      validationSchema={Yup.object().shape({
        countryOfBirth: Yup.string()
          .required("Country of Birth is required")
          .matches(
            /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
            specialCharactersError
          ),
        residentialAddress: Yup.string().when("mailingAddressDiff", {
          is: true,
          then: Yup.string()
            .required("Residential Address is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
        }),
        city: Yup.string().when("mailingAddressDiff", {
          is: true,
          then: Yup.string()
            .required("City/Town is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
        }),
        country: Yup.object().when("mailingAddressDiff", {
          is: true,
          then: Yup.object().required("Country is required"),
        }),
        postCode: Yup.string().when("mailingAddressDiff", {
          is: true,
          then: Yup.string()
            .required("Post Code is required")
            .matches(
              /^[a-zA-Z0-9@!#$%^&*()_+{}[\]:;<>,.?~\\/\-\s\n]+$/,
              specialCharactersError
            ),
        }),
      })}
      validateOnMount
      validateOnChange
    >
      {({ values, touched }) => (
        <Form>
          <b className={cn(styles.subtitle, "d-inline-flex mb-medium")}>
            Section 1: Account Holder Identification
          </b>
          <Panel className="mb-medium" padding>
            <Grid columns={6}>
              <GridElem size={2}>
                <FormGroup label="First Name">
                  <p>{formData?.personal_information?.firstName || "-"}</p>
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Middle Name">
                  <p>{formData?.personal_information?.middleName || "-"}</p>
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Last Name">
                  <p>{formData?.personal_information?.surname || "-"}</p>
                </FormGroup>
              </GridElem>
            </Grid>
            <hr className="mt-small" />
            <Grid columns={6}>
              <GridElem size={2}>
                <FormGroup label="Birth Day">
                  <p>{formData?.personal_information?.birthDay.label || "-"}</p>
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Birth Month">
                  <p>
                    {formData?.personal_information?.birthMonth.label || "-"}
                  </p>
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Birth Year">
                  <p>
                    {formData?.personal_information?.birthYear.label || "-"}
                  </p>
                </FormGroup>
              </GridElem>
              <GridElem size={3}>
                <FormGroup
                  className="mt-small mb-0"
                  label="Place and Country of Birth"
                >
                  <Field name="countryOfBirth" component={FormInput} bgGray />
                </FormGroup>
              </GridElem>
            </Grid>
          </Panel>
          <Panel className="mb-medium" padding>
            <b className={cn(styles.subtitle, "d-inline-flex mb-large")}>
              Permanent Residence Address
            </b>
            <Grid>
              <GridElem size={2}>
                <FormGroup label="Street">
                  <p>
                    {formData?.personal_information?.residentialAddress || "-"}
                  </p>
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="City/Town">
                  <p>{formData?.personal_information?.city || "-"}</p>
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Country">
                  <p>{formData?.personal_information?.country.label || "-"}</p>
                </FormGroup>
              </GridElem>
              <GridElem size={2}>
                <FormGroup label="Post Code">
                  <p>{formData?.personal_information?.postCode || "-"}</p>
                </FormGroup>
              </GridElem>
            </Grid>
          </Panel>
          <Field
            name="mailingAddressDiff"
            component={FormAgreement}
            label="Mailing Address (if different from above)"
          />
          {values["mailingAddressDiff"] === true && (
            <Panel padding>
              <b className={cn(styles.subtitle, "d-inline-flex mb-medium")}>
                Mailing Address
              </b>
              <Grid gap={2.4}>
                <GridElem size={2}>
                  <FormGroup label="Residential Address">
                    <Field name="residentialAddress" component={FormInput} />
                  </FormGroup>
                </GridElem>
                <GridElem size={2}>
                  <FormGroup label="City/Town">
                    <Field name="city" component={FormInput} />
                  </FormGroup>
                </GridElem>
                <GridElem size={2}>
                  <FormGroup label="Country">
                    <Field
                      name="country"
                      component={FormSelect}
                      options={Countries}
                      placeholder="Choose country"
                    />
                  </FormGroup>
                </GridElem>
                <GridElem size={2}>
                  <FormGroup label="Post Code">
                    <Field name="postCode" component={FormInput} />
                  </FormGroup>
                </GridElem>
              </Grid>
            </Panel>
          )}
          <div className="d-flex flex-row justify-content-between">
            <Button title="Back" variant="border" handleClick={goToPrevious} />
            <Button title="Next" type="submit" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ISCSection1;

interface IISCSection1 extends IISCSection {
  setSection: () => void;
  formData: any;
}
