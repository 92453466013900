import { Field, FormikErrors, FormikTouched } from "formik";
import styles from "./DocumentField.module.scss";
import FormGroup from "../../../../../components/Form/FormGroup/FormGroup";
import FormInputError from "../../../../../components/Form/FormInputError/FormInputError";
import Paragraph from "../../../../../components/Paragraph/Paragraph";
import List from "../../../../../components/List/List";
import ListItem from "../../../../../components/List/ListItem/ListItem";
import WarningLabel from "../../../../../components/WarningLabel/WarningLabel";
import UploadArea from "../../../../../components/DocumentUploader/UploadArea/UploadArea";
import { QuestionT } from "../../../../../store/types/Questions.type";
import UploadAreaMultiple from "../../../../../components/DocumentUploader/UploadAreaMultiple/UploadAreaMultiple";

const DocumentField = (props: IDocumentField) => {
  const { question, errors, touched, index } = props;

  return (
    <FormGroup className={"mb-large-last-0 pb-large-last-0"} bold hLine>
      <div className={styles.uploaderWrapper}>
        {index === 0 && (
          <>
            <Paragraph className="mb-medium" black bigger>
              We have limited the number of documents that can be uploaded as
              proof of identity and residency due to limitations from data
              source.
            </Paragraph>
            <List>
              <ListItem bigger>
                Only one file at a time is permitted to be uploaded.
              </ListItem>
              <ListItem bigger>
                These include files with extension such as (.TIFF, .PNG, .BMP,
                .JPEG, .PDF).
              </ListItem>
              <ListItem bigger>
                Document file size should be 50KB minimum and 4000KB maximum.
              </ListItem>
            </List>
            <hr />
            <WarningLabel text="Please make sure to attach the exact document as selected by you for Proof of Identity and Proof of Residency to avoid delay in your application." />
            {question.showCertifiedDocumentInformation && (
              <WarningLabel text={question.showCertifiedDocumentInformation} />
            )}
          </>
        )}
        <Field
          component={
            question.multipleUpload === "true" ? UploadAreaMultiple : UploadArea
          }
          name={question.questionId.toString()}
          title={question.questionText}
          {...props}
        />
        {touched[question.questionId.toString()] &&
          errors[question.questionId.toString()] &&
          errors[question.questionId.toString()] ===
            "This field is required" && (
            <FormInputError
              className={"mt-medium"}
              errors={errors}
              inputName={question.questionId.toString()}
            />
          )}
      </div>
    </FormGroup>
  );
};

export default DocumentField;

interface IDocumentField {
  question: QuestionT;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  index: number;
}
