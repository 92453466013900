import React from "react";
import cn from "classnames";
import styles from "./Button.module.scss";
import { ReactComponent as IconLoading } from "../../assets/icons/spinner.svg";

const Button = (props: IButton) => {
  const {
    Tag = "button",
    variant,
    title,
    type = "button",
    className,
    handleClick,
    disabled,
    loading,
    size,
  } = props;

  return (
    <Tag
      type={type}
      className={cn(
        "d-inline-flex align-items-center justify-content-center",
        styles.element,
        variant === "border" && styles["isBorder"],
        disabled && styles["isDisabled"],
        loading && styles["isLoading"],
        className,
        size === "small" && styles["isSmall"]
      )}
      onClick={handleClick}
    >
      {title}
      {loading && (
        <span className={styles.mask}>
          <IconLoading className={styles.loading} />
        </span>
      )}
    </Tag>
  );
};
export default Button;

interface IButton {
  Tag?: "button" | "a";
  variant?: "border" | "secondary";
  title: string;
  type?: "submit" | "reset" | "button";
  className?: string;
  handleClick?: () => void;
  disabled?: boolean;
  size?: "small";
  loading?: boolean;
}
