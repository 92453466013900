import React from "react";
import { ReactComponent as IconAlert } from "../../../assets/icons/alert.svg";
import styles from "./FormInputError.module.scss";
import cn from "classnames";

const FormInputError = (props: IFormInputError) => {
  const { className, errors, inputName, isRight } = props;
  return (
    <span
      className={cn(
        styles.element,
        "d-flex align-items-center",
        isRight && styles["isRight"],
        className
      )}
    >
      <IconAlert className={styles.icon} />
      {errors[inputName]}
    </span>
  );
};
export default FormInputError;

interface IFormInputError {
  className?: string;
  errors: any;
  inputName: string;
  isRight?: boolean;
}
