import { useState } from "react";
import { Form, Formik, Field } from "formik";
import Heading from "../../../../components/Heading/Heading";
import MainList, {
  MainListItem,
} from "../../../../components/MainList/MainList";
import Paragraph from "../../../../components/Paragraph/Paragraph";
import FormInput from "../../../../components/Form/FormInput/FormInput";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import Grid, { GridElem } from "../../../../components/Grid/Grid";
import Button from "../../../../components/Button/Button";
import ModalConfirmPassword from "../../../../components/Modal/ModalConfirmPassword/ModalConfirmPassword";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store/reducers";
import ModalNonSolicitationAcknowledgement from "../../../../components/Modal/ModalNonSolicitationAcknowledgement/ModalNonSolicitationAcknowledgement";
import { SetData } from "../../../../store/actions/SetData";

const SecurityQuestion = ({
  setActiveStep,
  goToPrevious,
  formData,
}: ISecurityQuestion) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [nskModalOpen, setNskModalOpen] = useState(false);

  const DivisionState = useSelector((state: AppState) => state.DivisionState);
  const AccountState = useSelector((state: AppState) => state.AccountState);

  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          pin: formData?.security_question?.pin || "",
          password: formData?.security_question?.password || "",
          confirmPassword: formData?.security_question?.confirmPassword || "",
        }}
        onSubmit={(values) => {
          setActiveStep();
          dispatch(SetData({ ...formData, security_question: values }));
        }}
        validationSchema={Yup.object().shape({
          pin: Yup.number()
            .test(
              "len",
              "PIN must be exactly 4 characters",
              (e) => String(e).length === 4
            )
            .required("PIN is required"),
          password: Yup.string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters.")
            .max(12, "Password must be at most 12 characters.")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])/,
              "Password must contain at least one upper letter."
            )
            .matches(
              /^(?=.*[0-9])/,
              "Password must contain at lease one number."
            ),
          confirmPassword: Yup.string()
            .required("This field is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        })}
        validateOnMount
        validateOnChange
      >
        {({
          isValid,
          values,
          submitForm,
          validateForm,
          errors,
          setFieldTouched,
          setFieldError,
        }) => (
          <Form>
            <Heading
              title="Security Question"
              className="text-center mb-xlarge d-none d-lg-block"
              size="h1"
            />
            <MainList>
              <MainListItem title="Please specify a security code">
                <Paragraph className="mb-large">
                  This PIN will be used for placing orders by phone with our
                  Trade Desk, to reset your password, and to establish your
                  identity for any account related queries.
                </Paragraph>
                <Grid>
                  <GridElem size={2}>
                    <FormGroup label="4 numerical digits">
                      <Field
                        name="pin"
                        component={FormInput}
                        onlyDigits
                        limit={4}
                      />
                    </FormGroup>
                  </GridElem>
                </Grid>
              </MainListItem>
            </MainList>
            <hr />
            <MainList>
              <MainListItem title="Please specify an account password">
                <Paragraph className="mb-large">
                  This password will be used to log in to your Trading Platform
                  and your account section of our website, where you fund your
                  account and update personal details.
                </Paragraph>
                <Grid>
                  <GridElem size={2}>
                    <FormGroup label="Password">
                      <Field
                        name="password"
                        component={FormInput}
                        type="password"
                      />
                    </FormGroup>
                  </GridElem>
                </Grid>
                <Grid>
                  <GridElem size={2}>
                    <FormGroup label="Confirm password">
                      <Field
                        name="confirmPassword"
                        component={FormInput}
                        type="password"
                      />
                    </FormGroup>
                  </GridElem>
                </Grid>
              </MainListItem>
            </MainList>
            <div className="d-flex flex-row justify-content-between">
              <Button
                title="Back"
                variant="border"
                handleClick={goToPrevious}
              />
              <Button
                title="Next"
                handleClick={() => {
                  validateForm().then(() => {
                    for (const [key] of Object.entries(errors)) {
                      setFieldTouched(key);
                    }
                  });
                  isValid && setModalOpen(true);
                }}
              />
            </div>
            {modalOpen && (
              <ModalConfirmPassword
                setModalOpen={setModalOpen}
                values={values}
                submitForm={() => {
                  if (DivisionState === "ky" && AccountState === "individual") {
                    setModalOpen(false);
                    setNskModalOpen(true);
                  } else {
                    submitForm();
                  }
                }}
              />
            )}
            {nskModalOpen && (
              <ModalNonSolicitationAcknowledgement submitForm={submitForm} />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SecurityQuestion;

interface ISecurityQuestion {
  setActiveStep: () => void;
  goToPrevious: () => void;
  formData?: any;
}
