import React, { Dispatch, SetStateAction } from "react";
import Modal from "../Modal";
import Heading from "../../Heading/Heading";
import Paragraph from "../../Paragraph/Paragraph";
import { ReactComponent as IconWarning } from "../../../assets/icons/warning-triangle.svg";
import Button from "../../Button/Button";

const ModalConfirmation = (props: IModalConfirmation) => {
  const { setModalOpen, handleClick } = props;

  return (
    <Modal>
      <div className="d-flex justify-content-center mb-large">
        <IconWarning />
      </div>
      <Heading
        className="text-center mb-medium"
        size="h1"
        title="Are you sure?"
      />
      <Paragraph className="text-center">
        This will clear previous answers. You may need to answer few questions
        again. Please confirm?
      </Paragraph>
      <div className="d-flex flex-column-reverse flex-lg-row justify-content-lg-center">
        <Button
          title="Cancel"
          variant="border"
          handleClick={() => setModalOpen(false)}
        />
        <Button title="OK" handleClick={handleClick} />
      </div>
    </Modal>
  );
};

export default ModalConfirmation;

interface IModalConfirmation {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  handleClick: () => void;
}
