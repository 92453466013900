import Heading from "../../Heading/Heading";
import Modal from "../Modal";
import Paragraph from "../../Paragraph/Paragraph";
import Button from "../../Button/Button";

const ModalNonSolicitationAcknowledgement = ({
  submitForm,
}: IModalNonSolicitationAcknowledgement) => {
  return (
    <Modal>
      <Heading
        title="Non-Solicitation Acknowledgement"
        size="h1"
        className="text-center mb-large"
      />
      <Paragraph className="mb-medium">
        You have applied for a foreign exchange trading account with ATC BROKERS
        LIMITED (“ATC”). You acknowledge that ATC is licensed by the Cayman
        Islands Monetary Authority (“CIMA”) in the Cayman Islands and that your
        account(s) with ATC will be governed by CIMA rules including all
        relevant CIMA retail client protection provisions.
      </Paragraph>
      <Paragraph className="mb-medium">
        Clients residing within the European Union: You acknowledge that the
        rules made for the protection of retail clients (including measures
        proposed or adopted by the European Securities and Markets Authority,
        such as leverage restrictions and negative balance protection) will not
        be applicable to you or to your account(s) with ATC.
      </Paragraph>
      <Paragraph className="mb-medium">
        Trading in foreign exchange, contracts for differences and other
        derivatives entails a high level of risk. As such, only genuine “risk”
        funds should be used in such trading. Clients which are not prepared to
        accept losses that may exceed the value of the capital that they have
        invested, should not trade in these markets.
      </Paragraph>
      <Paragraph>
        By choosing to proceed with the application, you acknowledge on your
        part that at no time has ATC solicited you with regard to opening a
        foreign exchange trading account.
      </Paragraph>
      <div className="d-flex flex-row justify-content-center">
        <Button title="Submit" handleClick={() => submitForm()} />
      </div>
    </Modal>
  );
};

export default ModalNonSolicitationAcknowledgement;

interface IModalNonSolicitationAcknowledgement {
  submitForm: () => void;
}
