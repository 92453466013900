import React, {ReactFragment} from "react";
import styles from "./Grid.module.scss";
import cn from "classnames";

const Grid = (props: IGrid) => {

    return (
        <div className={cn(styles.area, props.columns === 6 && styles['has6Columns'], props.className)} style={{gridColumnGap: `${props.gap}rem`, gridRowGap: `${props.rowGrap}rem`}}>{props.children}</div>
    )
}
export default Grid;

export const GridElem = (props: IGridElem) => {
    const {
        children, size, className
    } = props;
    return (
        <div className={cn(
            styles.element, size && styles[`hasSize-${size}`], className
        )}>
            {children}
        </div>
    )
}

interface IGrid {
    children: React.ReactElement<IGridElem> | React.ReactElement<IGridElem>[] | ReactFragment;
    gap?: number;
    rowGrap?: number;
    columns?: 6;
    className?: string;
}

interface IGridElem {
    children: any;
    size?: 2 | 3 | 4 | 5 | 6;
    className?: string;
}
