import React from "react";
import styles from "./WarningLabel.module.scss";
import {ReactComponent as IconWarning} from "../../assets/icons/warning.svg";
import cn from "classnames";

const WarningLabel = (props: IWarningLabel) => {

    const {
        text
    } = props;

    return(
        <div className={cn(styles.element, "mb-large")}>
            <IconWarning className={styles.icon}/>
            <p className={styles.text}>
                <span className={styles.warning}>Warning: </span>
                {text}
            </p>
        </div>
    )
}

interface IWarningLabel {
    text: string;
}

export default WarningLabel;
