import {
  GET_QUESTIONS,
  GET_QUESTIONS_DONE,
  SEND_ANSWERS,
  SEND_ANSWERS_DONE,
  SEND_ANSWERS_FAILED,
  SEND_FILE,
  SEND_FILE_DONE,
  SEND_FILE_FAILED,
  SET_PREVIOUS_QUESTIONS,
  SET_PREVIOUS_QUESTIONS_DONE,
  SET_PREVIOUS_QUESTIONS_FAILED,
  QuestionsStateT,
  QuestionsStateActionT,
  GET_QUESTIONS_FAILED,
} from "../types/Questions.type";

const initialState: QuestionsStateT = {
  loading: false,
  loaded: false,
  questions: [],
  error: false
};

export default function QuestionsState(
  state = initialState,
  action: QuestionsStateActionT
): QuestionsStateT {
  switch (action.type) {
    case GET_QUESTIONS:
      return {...state, loading: true, error: false};
    case GET_QUESTIONS_DONE:
      return {
        ...state,
        questions: action.payload,
        loading: false,
        loaded: true,
      };
    case GET_QUESTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case SEND_ANSWERS:
      return {...state, loaded: false, loading: true, error: false};
    case SEND_ANSWERS_DONE:
      return {
        ...state,
        loading: false,
      };
    case SEND_ANSWERS_FAILED:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true
      };
    case SEND_FILE:
      return {...state, loaded: false, loading: true, error: false};
    case SEND_FILE_DONE:
      return {
        ...state,
        loading: false,
      };
    case SEND_FILE_FAILED:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true
      };
    case SET_PREVIOUS_QUESTIONS:
      return {...state, loaded: false, loading: true, error: false};
    case SET_PREVIOUS_QUESTIONS_DONE:
      return {
        ...state,
        loading: false,
      };
    case SET_PREVIOUS_QUESTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
