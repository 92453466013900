export const GET_IP_ADDRESS = "GET_IP_ADDRESS";
export const GET_IP_ADDRESS_DONE = "GET_IP_ADDRESS_DONE";

export type getIPAdressT = {
  type: typeof GET_IP_ADDRESS;
};

export type getIPAdressDoneT = {
  type: typeof GET_IP_ADDRESS_DONE;
  ip: string;
};

export default function IPAddressState(
  state: string | null = null,
  action: getIPAdressDoneT
) {
  if (action.type === GET_IP_ADDRESS_DONE) {
    return action.ip;
  }
  return state;
}
