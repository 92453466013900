import { FormikErrors, FormikTouched } from "formik";
import { MIN_SELECT_OPTIONS } from "../../../../constans";
import { QuestionT } from "../../../../store/types/Questions.type";
import CheckboxField from "../AssessmentFields/CheckboxField/CheckboxField";
import DateField from "../AssessmentFields/DateField/DateField";
import DocumentField from "../AssessmentFields/DocumentField/DocumentField";
import MultipleDocumentsField from "../AssessmentFields/MultipleDocumentsField/MultipleDocumentsField";
import RadioField from "../AssessmentFields/RadioField/RadioField";
import SelectField from "../AssessmentFields/SelectField/SelectField";
import TextField from "../AssessmentFields/TextField/TextField";

export const getAssessmentQuestion = (
  question: QuestionT,
  values: any,
  setFieldValue: {
    (field: string, value: string): void;
  },
  key: number,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  textFieldsNum: QuestionT[]
) => {
  switch (question.type) {
    case "Text":
      return (
        <TextField
          question={question}
          key={key}
          form={textFieldsNum.length > 1}
        />
      );
    case "Optional":
      return (
        <TextField
          question={question}
          key={key}
          form={textFieldsNum.length > 1}
        />
      );
    case "Value":
      return question.multipleChoice ? (
        <CheckboxField
          question={question}
          key={key}
          errors={errors}
          touched={touched}
        />
      ) : question.possibleResponses.length > MIN_SELECT_OPTIONS ? (
        <SelectField question={question} key={key} />
      ) : (
        <RadioField
          question={question}
          key={key}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          values={values}
          form={textFieldsNum.length > 1}
        />
      );
    case "Date":
      return (
        <DateField
          question={question}
          key={key}
          errors={errors}
          touched={touched}
        />
      );
    case "OptionalDate":
      return (
        <DateField
          question={question}
          key={key}
          errors={errors}
          touched={touched}
        />
      );
    case "Phone":
      return (
        <TextField
          question={question}
          key={key}
          form={textFieldsNum.length > 1}
        />
      );
    case "Email":
      return (
        <TextField
          question={question}
          key={key}
          form={textFieldsNum.length > 1}
        />
      );
    case "Document":
      return (
        <DocumentField
          question={question}
          key={key}
          index={key}
          errors={errors}
          touched={touched}
        />
      );
    case "AdditionalDocument":
      return (
        <MultipleDocumentsField
          question={question}
          key={key}
          index={key}
          errors={errors}
          touched={touched}
        />
      );
  }
};
