import {
  LOGIN_USER,
  LOGIN_USER_DONE,
  LOGIN_USER_FAILED,
  AuthenticationStateActionT,
  AuthenticationStateT,
  LOGOUT_USER_DONE,
  REGISTER_USER,
  REGISTER_USER_FAILED
} from "../types/Authentication.type";

const initialState: AuthenticationStateT = {
  userId: null,
  error: false,
};

export default function AuthenticationState(
  state = initialState,
  action: AuthenticationStateActionT
): AuthenticationStateT {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        error: false
      };
    case LOGIN_USER_DONE:
      return {
        ...state,
        userId: action.payload.id,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        error: true
      };
    case REGISTER_USER:
      return {
        ...state,
        error: false
      };
    case REGISTER_USER_FAILED:
      return {
        ...state,
        error: true
      };
    case LOGOUT_USER_DONE:
      return {...state, userId: null};
    default:
      return state;
  }
}
