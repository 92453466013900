import React from "react";
import styles from "./BoxGrey.module.scss";
import cn from "classnames";

const BoxGrey = ({children, className}: {children: any; className: string}) => {

    return(
        <div className={cn(styles.element, className)}>
            {children}
        </div>
    )
}

export default BoxGrey;
