import React, { useEffect, useRef, useState } from "react";
import Panel from "../../Panel/Panel";
import styles from "../UploadArea/UploadArea.module.scss";
import cn from "classnames";
import { FileDrop } from "react-file-drop";
import { ReactComponent as IconImage } from "../../../assets/icons/image.svg";
import UploadAreaThumbnail from "../UploadAreaThumbnail/UploadAreaThumbnail";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../assets/styles/variables/breakpoints.module.scss";
import FormUploadError from "../../Form/FormUploadError/FormUploadError";

const UploadAreaMultiple = (props: IUploadArea) => {
  const { field, form, title } = props;

  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["md"]}px)`,
  });

  const [files, setFiles] = useState<any[]>([]);
  const [filesToDisplay, setFilesToDisplay] = useState<any[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!form.values[field.name]) {
      // @ts-ignore
      fileInputRef.current.value = "";
    }
  }, [field.name, form.values]);

  useEffect(() => {
    const val = files.map((file) => {
      return {
        blob: window.URL.createObjectURL(file),
        name: file.name,
        type: file.type,
      };
    });
    setFilesToDisplay(val);
  }, [files]);

  const onFileInputChange = (f: FileList | null) => {
    // @ts-ignore
    const fArr = [...f];
    form.setFieldValue(field.name, [...files, ...fArr]);

    setFiles([...files, ...fArr]);
  };

  const onFileDelete = (index: number) => {
    files.splice(index, 1);
    setFiles(files);
    if (files.length) {
      form.setFieldValue(field.name, files);
    } else form.setFieldValue(field.name, "");
    filesToDisplay.splice(index, 1);
    setFilesToDisplay(filesToDisplay);
  };

  return (
    <>
      <div className={cn(styles.title, "mb-large", styles["isGray"])}>
        <span>{title}</span>
      </div>
      <Panel className={cn(styles.element, filesToDisplay.length && "pb-0")}>
        <input
          onChange={(e) => onFileInputChange(e.currentTarget.files)}
          ref={fileInputRef}
          type="file"
          className={styles.input}
          accept=".png, .jpeg, .jpg, .tiff, .bmp, .pdf, .doc, .docx"
          multiple
        />
        <FileDrop
          className={cn(styles.area, filesToDisplay.length && "mb-medium")}
          onTargetClick={() =>
            fileInputRef.current && fileInputRef.current.click()
          }
          onDrop={(files) => onFileInputChange(files)}
        >
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <IconImage className={styles.icon} />
            {isResponsiveView ? (
              <span className={styles.label}>
                Drop your image here, or{" "}
                <span className={styles.blue}>browse</span>
              </span>
            ) : (
              <span className={styles.label}>Upload your image here</span>
            )}
          </div>
        </FileDrop>
        {form.errors[field.name] &&
          form.errors[field.name] !== "This field is required" && (
            <FormUploadError errors={form.errors} inputName={field.name} />
          )}
        <div className="d-flex flex-wrap">
          {filesToDisplay.length > 0 &&
            filesToDisplay.map((file, key: number) => {
              return (
                <UploadAreaThumbnail
                  key={key}
                  file={file}
                  onFileDelete={() => onFileDelete(key)}
                />
              );
            })}
        </div>
      </Panel>
    </>
  );
};
export default UploadAreaMultiple;

export interface IUploadArea {
  field?: any;
  form?: any;
  name: string;
  title: string;
}

export interface IFile {
  blob: string;
  name: string;
  type: string;
}
