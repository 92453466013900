import React from "react";
import Heading from "../Heading/Heading";
import styles from "./MainList.module.scss";
import cn from "classnames";

const MainList = ({
  children,
}: {
  children:
    | React.ReactElement<IMainListItem>
    | React.ReactElement<IMainListItem>[];
}) => {
  return <ul className={styles.element}>{children}</ul>;
};

export default MainList;

export const MainListItem = ({ title, children, className }: any) => {
  return (
    <li className={cn(styles.item, className, "d-flex flex-column")}>
      {title && <Heading title={title} size="h4" />}
      {children}
    </li>
  );
};
interface IMainListItem {
  title: string;
  children: any;
  className?: string;
}
