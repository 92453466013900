import React from "react";
import styles from "./LayoutAuth.module.scss";
import Container from "../Bootstrap/Container";
import cn from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ToastContainer } from "react-toastify";
import ContainerMain from "../ContainerMain/ContainerMain";

const LayoutAuth = ({ children }: any) => {
  const isResponsiveView = useMediaQuery({
    query: `(min-width: ${breakpoints["lg"]}px)`,
  });

  const { pathname } = useLocation();

  const showAuthLink = (path: string) => {
    switch (path) {
      case "/":
        return (
          <div className={styles.toRegister}>
            Already have an account?
            <a
              href="https://atcbrokers.com/login/"
              className={cn("link mt-0", styles.link)}
            >
              Login
            </a>
          </div>
        );
      case "/sign-in":
        return (
          <div className={styles.toRegister}>
            Don’t have an account?
            <NavLink to="/" className={cn("link mt-0", styles.link)}>
              Create account
            </NavLink>
          </div>
        );
    }
  };

  return (
    <div className={styles.element}>
      <ContainerMain scrollable dark>
        <div className={cn(styles.content, "d-flex align-items-center")}>
          <div
            className={cn(
              styles.header,
              "d-flex justify-content-center justify-content-lg-end"
            )}
          >
            <div
              className={cn(
                styles.innerHeader,
                "d-flex justify-content-center"
              )}
            >
              <div className={styles.logoWrapper}>
                <div className={styles.logo}>
                  <Logo />
                </div>
              </div>
              {isResponsiveView && showAuthLink(pathname)}
            </div>
          </div>
          <Container fluid>{children}</Container>
          {!isResponsiveView && showAuthLink(pathname)}
          <ToastContainer
            autoClose={4000}
            hideProgressBar
            closeButton={false}
          />
        </div>
      </ContainerMain>
    </div>
  );
};
export default LayoutAuth;
