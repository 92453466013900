export const checkAppropriateness = (data: any) => {
  return !!(
    data?.clientDomicileResponse?.some((item: any) => {
      return item.suitabilityAndAppropriatnessResult === "NotAppropriate";
    }) ||
    data?.institutionDomicileResponse?.some((item: any) => {
      return item.suitabilityAndAppropriatnessResult === "NotAppropriate";
    })
  );
};
