export const SET_DECLINE = "SET_DECLINE";

export type setDeclineT = {
  type: "SET_DECLINE";
  data: boolean
};

const initialState = false;

export default function DeclineState (
  state = initialState,
  action: setDeclineT
) {
  if ( action.type === SET_DECLINE ) {
    return action.data;
  }
  return state;
}
