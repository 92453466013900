import { FunctionComponent } from "react";
import styles from "./FormSelect.module.scss";
import cn from "classnames";
import { ReactComponent as IconArrow } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as IconCheck } from "../../../assets/icons/select.svg";
import Select, {
  OptionsType,
  OptionTypeBase,
  SingleValueProps,
} from "react-select";
import FormInputError from "../FormInputError/FormInputError";

const FormSelect = (props: IFormSelect) => {
  const {
    name,
    field,
    form,
    options,
    customSingleValue,
    dark,
    placeholder,
    setActiveIndex,
    defaultValue,
    value,
    isControls,
    handleChange,
    separator,
    isSearchable = true,
    disabled,
  } = props;

  const customDropdownIndicator = () => {
    return <IconArrow className={styles.icon} />;
  };

  const customOption = (props: {
    isSelected?: boolean;
    data?: any;
    innerProps?: any;
    innerRef?: any;
  }) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={cn(styles.option, props.isSelected && styles["isSelected"])}
      >
        {props.data.label}
        {props.isSelected && <IconCheck className={styles.check} />}
      </div>
    );
  };

  const defaultSingleValue = (options: { data: { label: string } }) => {
    return (
      <div className={styles.singleValue}>
        <span className={styles.singleValueLabel}>{options.data.label}</span>
      </div>
    );
  };

  const thisHandleChange = (e: OptionTypeBase) => {
    if (form) {
      form.setFieldValue(field.name, e);
    }
    if (setActiveIndex) {
      setActiveIndex(e.value);
    }
    if (handleChange) {
      handleChange(e);
    }
  };

  return (
    <div className={cn(styles.wrapper, "mb-medium")}>
      <Select
        name={field ? field.name : name && name}
        options={options}
        isSearchable={isSearchable}
        components={{
          SingleValue: customSingleValue
            ? customSingleValue
            : defaultSingleValue,
          DropdownIndicator: customDropdownIndicator,
          IndicatorSeparator: () => null,
          Option: customOption,
        }}
        defaultValue={defaultValue ? defaultValue : field?.value && field.value}
        className={cn(
          styles.element,
          dark && styles["isDark"],
          isControls && styles["isControls"],
          separator && styles["hasSeparator"],
          disabled && styles["isDisabled"]
        )}
        onChange={(e) => thisHandleChange(e)}
        value={value ? value : field?.value && field.value}
        placeholder={placeholder ? placeholder : "Choose.."}
        styles={customSelectStyles}
        isDisabled={disabled}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "rgba(139, 139, 139, 0.7)",
          },
        })}
      />
      {form && form.touched[field.name] && form.errors[field.name] && (
        <FormInputError errors={form.errors} inputName={field.name} />
      )}
    </div>
  );
};
export default FormSelect;

export interface IFormSelect {
  options: OptionsType<OptionTypeBase>;
  customSingleValue?: FunctionComponent<SingleValueProps<any, any>>;
  dark?: boolean;
  field?: any;
  form?: any;
  name?: string;
  placeholder?: string;
  setActiveIndex?: (e: number | string) => void;
  defaultValue?: OptionTypeBase;
  isControls?: boolean;
  value?: OptionTypeBase;
  handleChange?: (e: OptionTypeBase) => void;
  separator?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
}

export const customSelectStyles = {
  valueContainer: (base: any, state: any) => ({
    ...base,
    padding: "0",
    lineHeight: "1",
  }),
  menuList: (base: any) => ({
    ...base,
    "::-webkit-scrollbar": {
      width: "8px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#86C4F5",
      borderRadius: "4px",
    },
  }),
};
