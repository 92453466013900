import React from "react";
import styles from "./Bootstrap.module.scss";
import cn from "classnames";

const Container = (props: any) => {
    const {children, className, limited} = props;

    return(
        <div className={cn(
            styles.container,
            className,
            limited && styles['isLimited']
        )}>{children}</div>
    )
}

export default Container;
