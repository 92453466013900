import React from "react";
import styles from "./Stepper.module.scss";
import cn from "classnames";
import { IStepsNavigation } from "../StepsNavigation/StepsNavigation";
import { ReactComponent as IconCheck } from "../../assets/icons/select.svg";

const Stepper = ({ steps }: { steps: IStepsNavigation["steps"] }) => {
  return (
    <div className={cn(styles.element, styles[`has${steps.length}Steps`])}>
      {steps.map((step, key) => {
        const nextFilled = steps[key + 1] && steps[key + 1].filled;
        const nextActive = steps[key + 1] && steps[key + 1].active;
        return (
          <Step
            {...step}
            key={key}
            first={key === 0}
            last={key === steps.length - 1}
            nextFilled={nextFilled}
            nextActive={nextActive}
          />
        );
      })}
    </div>
  );
};

export default Stepper;

export const Step = (props: IStep) => {
  const { title, first, last, active, filled, nextFilled, nextActive } = props;

  return (
    <div
      className={cn(
        styles.step,
        filled && styles["isFilled"],
        active && styles["isActive"]
      )}
    >
      <Circle filled={filled} />
      <Title title={title} />
      {!first && <Line filled={filled || active} dir="left" />}
      {!last && (
        <Line
          filled={(filled && nextActive) || (filled && nextFilled)}
          dir="right"
        />
      )}
    </div>
  );
};

const Circle = ({ filled }: { filled: IStep["filled"] }) => (
  <div
    className={cn(
      styles.circle,
      "d-inline-flex align-items-center justify-content-center"
    )}
  >
    {filled && <IconCheck className={styles.icon} />}
  </div>
);

const Title = ({ title }: { title: IStep["title"] }) => (
  <div className={styles.title}>{title}</div>
);

const Line = ({
  dir,
  filled,
  active,
}: {
  dir: "left" | "right";
  filled?: boolean;
  active?: boolean;
}) => (
  <span
    className={cn(
      styles.line,
      styles[`is-${dir}`],
      "d-inline-flex",
      filled && styles["isFilled"]
    )}
  />
);

export interface IStep {
  title: string;
  first?: boolean;
  last?: boolean;
  active: boolean;
  filled: boolean;
  nextFilled?: boolean;
  nextActive?: boolean;
}
