import React from "react";
import styles from "./Assessment.module.scss";
import Container from "../../../components/Bootstrap/Container";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/reducers";
import AssessmentQuestions from "./AssessmentQuestions/AssessmentQuestions";
import ContainerMain from "../../../components/ContainerMain/ContainerMain";

const Assessment = () => {
  const { questions } = useSelector((state: AppState) => state.QuestionsState);

  return (
    <ContainerMain scrollable dependency={questions}>
      <Container limited className={styles.container}>
        {questions.length > 0 && <AssessmentQuestions />}
      </Container>
    </ContainerMain>
  );
};

export default Assessment;
