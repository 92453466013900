import React from "react";
import styles from "./Panel.module.scss";
import cn from "classnames"

const Panel = (props: IPanel) => {

    const {
        children,
        className,
        fitInScreen,
        padding,
        paddingBig
    } = props;

    return(
        <div className={cn(styles.element, className, fitInScreen && styles['fitInScreen'], padding && styles['hasPadding'], paddingBig && styles['hasBigPadding'])}>
            {children}
        </div>
    )
}
export default Panel;

interface IPanel {
    children: any;
    className?: string;
    fitInScreen?: boolean;
    padding?: boolean;
    paddingBig?: boolean;
}
