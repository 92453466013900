import Modal from "../Modal";
import Heading from "../../Heading/Heading";
import Paragraph from "../../Paragraph/Paragraph";
import { ReactComponent as IconComplete } from "../../../assets/icons/complete.svg";
import Button from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../store/actions/Authentication";
import { AppState } from "../../../store/reducers";

const ModalComplete = () => {
  const DeclineState = useSelector((state: AppState) => state.DeclineState);

  const dispatch = useDispatch();

  return (
    <Modal>
      <div className="d-flex justify-content-center mb-large">
        <IconComplete />
      </div>
      <Heading
        className="text-center mb-medium"
        size="h1"
        title={
          DeclineState
            ? "The application has been completed"
            : "Thank you for completing the application!"
        }
      />
      {!DeclineState && (
        <Paragraph className="text-center">
          We will review your responses and contact you shortly to continue the
          on-boarding process. Should you have any queries in the meantime,
          please do not hesitate to contact our offices by phone, email, or live
          chat.
        </Paragraph>
      )}
      <div className="d-flex flex-row justify-content-center">
        <Button title="Finish" handleClick={() => dispatch(logoutUser())} />
      </div>
    </Modal>
  );
};

export default ModalComplete;
