import React from "react";
import styles from "./LayoutMain.module.scss";
import { ToastContainer } from "react-toastify";

const LayoutMain = ({ children }: { children: any }) => {
  return (
    <div className={styles.element}>
      <>{children}</>
      <ToastContainer autoClose={4000} hideProgressBar closeButton={false} />
    </div>
  );
};
export default LayoutMain;
