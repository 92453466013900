export const SET_APPLY = "SET_APPLY";

export type setApplyT = {
  type: "SET_APPLY";
  data: 0 | 1 | 2 | 3 | 4;
};

const initialState = 0;

export default function ApplyState ( state = initialState, action: setApplyT ) {
  if ( action.type === SET_APPLY ) {
    return action.data;
  }
  return state;
}
