import { Dispatch, SetStateAction } from "react";
import Modal from "../Modal";
import Button from "../../Button/Button";
import Heading from "../../Heading/Heading";
import Paragraph from "../../Paragraph/Paragraph";
import { ReactComponent as IconClose } from "../../../assets/icons/close.svg";
import styles from "./ModalConfirmPassword.module.scss";
import MainList, { MainListItem } from "../../MainList/MainList";

const ModalConfirmPassword = (props: IModalConfirmPassword) => {
  const { setModalOpen, values, submitForm } = props;

  return (
    <Modal>
      <button className={styles.close} onClick={() => setModalOpen(false)}>
        <IconClose />
      </button>
      <Heading
        title="Password Notice"
        size="h1"
        className="text-center mb-medium"
      />
      <Paragraph className="mb-large text-center text-lg-start">
        Please take the time now to write down the password and PIN you have
        just set. For security purposes, please do not distribute this
        information to anyone.
      </Paragraph>
      <MainList>
        <MainListItem title={`PIN: ${values.pin}`} className="mb-0">
          <Paragraph className="mb-large">
            This PIN will be used for placing orders by phone with our Trading
            Desk, to reset your password, and to establish your identity for any
            account related queries.
          </Paragraph>
        </MainListItem>
        <MainListItem title={`Password: ${values.password}`}>
          <Paragraph>
            This password will be used to log in to your Trading Platform and
            your Account, where you fund your account and update personal
            details.
          </Paragraph>
        </MainListItem>
      </MainList>
      <div className="d-flex flex-row justify-content-center">
        <Button title="Submit" handleClick={() => submitForm()} />
      </div>
    </Modal>
  );
};
export default ModalConfirmPassword;

interface IModalConfirmPassword {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  values: any;
  submitForm: () => void;
}
