import React from "react";
import cn from "classnames";
import styles from "./FormGroup.module.scss";

const FormGroup = (props: IFormGroup) => {
  const { label, children, className, dark, bold, hLine } = props;

  const checkIfHtml = (text: string) => {
    return text.includes("<") && text.includes(">");
  };

  return (
    <div
      className={cn(
        styles.element,
        "mb-medium",
        className,
        hLine && styles["hasHLine"]
      )}
    >
      {label ? (
        checkIfHtml(label) ? (
          <label
            dangerouslySetInnerHTML={{ __html: label }}
            className={cn(
              styles.label,
              "mb-small d-inline-block",
              dark && styles["isDark"],
              bold && styles["isBold"]
            )}
          ></label>
        ) : (
          <label
            className={cn(
              styles.label,
              "mb-small d-inline-flex",
              dark && styles["isDark"],
              bold && styles["isBold"]
            )}
          >
            {label}
          </label>
        )
      ) : null}
      {children}
    </div>
  );
};
export default FormGroup;

interface IFormGroup {
  label?: string;
  className?: string;
  children: any;
  dark?: boolean;
  bold?: boolean;
  hLine?: boolean;
}
