import { AcceptAssessmentWarningDoneT, AcceptAssessmentWarningFailedT, AcceptAssessmentWarningT, ACCEPT_ASSESSMENT_WARNING, ACCEPT_ASSESSMENT_WARNING_DONE, ACCEPT_ASSESSMENT_WARNING_FAILED, CreateAssessmentDoneT, CreateAssessmentFailedT, CreateAssessmentT, CREATE_ASSESSMENT, CREATE_ASSESSMENT_DONE, CREATE_ASSESSMENT_FAILED, GetAssessmentDetailsDoneT, GetAssessmentDetailsFailedT, GetAssessmentDetailsT, GET_ASSESSMENT_DETAILS, GET_ASSESSMENT_DETAILS_DONE, GET_ASSESSMENT_DETAILS_FAILED, SendAssessmentCompletedDoneT, SendAssessmentCompletedFailedT, SendAssessmentCompletedT, SEND_ASSESSMENT_COMPLETED, SEND_ASSESSMENT_COMPLETED_DONE, SEND_ASSESSMENT_COMPLETED_FAILED } from "../types/Assessment.type";

export const createAssessment = (): CreateAssessmentT => ( {
  type: CREATE_ASSESSMENT,
} );

export const createAssessmentDone = ( payload: string ): CreateAssessmentDoneT => ( {
  type: CREATE_ASSESSMENT_DONE,
  payload
} );

export const createAssessmentFailed = (): CreateAssessmentFailedT => ( {
  type: CREATE_ASSESSMENT_FAILED,
} );

export const getAssessmentDetails = ( userId: string | null ): GetAssessmentDetailsT => ( {
  type: GET_ASSESSMENT_DETAILS,
  userId
} );

export const getAssessmentDetailsDone = (
  payload: any
): GetAssessmentDetailsDoneT => ( {
  type: GET_ASSESSMENT_DETAILS_DONE,
  payload,
} );

export const getAssessmentDetailsFailed = (
): GetAssessmentDetailsFailedT => ( {
  type: GET_ASSESSMENT_DETAILS_FAILED
} );

export const sendAssessmentCompleted = ( payload: string ): SendAssessmentCompletedT => ( {
  type: SEND_ASSESSMENT_COMPLETED,
  payload
} );

export const sendAssessmentCompletedDone = (
): SendAssessmentCompletedDoneT => ( {
  type: SEND_ASSESSMENT_COMPLETED_DONE,
} );

export const sendAssessmentCompletedFailed = (
): SendAssessmentCompletedFailedT => ( {
  type: SEND_ASSESSMENT_COMPLETED_FAILED
} );

export const acceptAssessmentWarning = ( payload: string ): AcceptAssessmentWarningT => ( {
  type: ACCEPT_ASSESSMENT_WARNING,
  payload
} );

export const acceptAssessmentWarningDone = (
): AcceptAssessmentWarningDoneT => ( {
  type: ACCEPT_ASSESSMENT_WARNING_DONE,
} );

export const acceptAssessmentWarningFailed = (
): AcceptAssessmentWarningFailedT => ( {
  type: ACCEPT_ASSESSMENT_WARNING_FAILED
} );


